
import queryString from "query-string";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useForm = (initvalstring, ignoreurl) => {

    const location = useLocation();
    let history = useHistory();

    let real_initvals = {};
    if (ignoreurl === false) {
        real_initvals = queryString.parse(location.search, { arrayFormat: "bracket" });
    } else if (initvalstring) {
        let initvals = queryString.parse(initvalstring, { arrayFormat: "bracket" });
        real_initvals = {...initvals};
    }

    const [values, setValues] = useState(real_initvals);

    useEffect(() => {

        if (ignoreurl === false) {
            let qs = queryString.parse(location.search, { arrayFormat: "bracket" });
            setValues({...qs}); // {...foo} is the js object spread syntax. It is needed here because react needs to see a new object in order to update the state
        } else if (initvalstring) {
            let initvals = queryString.parse(initvalstring, { arrayFormat: "bracket" });
            setValues({...initvals});
        }

    }, [ location.search, initvalstring, ignoreurl ]);

    const handleChange = (event) => {

        let vals = values;
        vals[event.target.name] = event.target.value;

        if (event.target.type === 'checkbox') {
            vals[event.target.name] = event.target.checked;
        }    

        // If two statistics categories are selected, make a cross-tabulation table the default.
        if (event.target.name === 'rows' && vals['rows'] !== '' && (!vals['initviz'] || vals['initviz'] === 'table')) {

            vals['initviz'] = 'table-cross';

        }

        setValues({...vals});   // {...foo} is the js object spread syntax. It is needed here because react needs to see a new object in order to update the state

    };

    const updateURL = (vals) => {

        let qs = queryString.parse(location.search, { arrayFormat: "bracket" });
        let newvals = {...qs, ...vals};

        for(var k in newvals) { if(!newvals[k]) delete newvals[k]; } // No filter() for objects in js yet

        const stringified = queryString.stringify(newvals, { arrayFormat: "bracket" });
        history.push({ pathname: location.pathname, search: stringified }); // Push the completed form into history so the back button retains the form contents
        history.push({ pathname: location.pathname, search: stringified, hash: 'results' });

        const results = document.getElementById('results'); // Unfortunately we need belt and braces here
                                                            // Although we set the hash above, this will not move the viewport
        if (results) {                                      // if the hash was already '#results'.
            results.scrollIntoView({ behavior: 'smooth' }); // So we do it again here.
        }                                                   

    };

    const handleSubmit = (event) => {

        event.preventDefault();

        let vals = values;
        vals['from'] = 0;

        updateURL(vals);
        
    };

    const handleReset = (event) => {

        // This function is strictly to be called only by the form reset button
        
        event.preventDefault();

        let vals = values;
        for(var k in vals) { vals[k] = ''; }

        setValues({...vals}); // {...foo} is the js object spread syntax. It is needed here because react needs to see a new object in order to update the state

    };

    return [ values, handleChange, handleSubmit, handleReset ];
    
};

export default useForm;
