
const FieldDate = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <div className="col-12">
                <div className="input-group input-date-top">
                    <span className="input-group-text bg-white text-muted input-date-label">From</span>
                    <select className="form-select" name="month_gte" id="field_month_gte" value={ values['month_gte'] || '' } onChange={ handleChange }>
                        <option value="">&lt; Any { pretty('month') } &gt;</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    <select className="form-select" name="year_gte" id="field_year_gte" value={ values['year_gte'] || '' } onChange={ handleChange }>
                        <option value="">&lt; Any { pretty('year') } &gt;</option>
                        <option value="1674">{ pretty('1674') }</option>
                        <option value="1675">{ pretty('1675') }</option>
                        <option value="1676">{ pretty('1676') }</option>
                        <option value="1677">{ pretty('1677') }</option>
                        <option value="1678">{ pretty('1678') }</option>
                        <option value="1679">{ pretty('1679') }</option>
                        <option value="1680">{ pretty('1680') }</option>
                        <option value="1681">{ pretty('1681') }</option>
                        <option value="1682">{ pretty('1682') }</option>
                        <option value="1683">{ pretty('1683') }</option>
                        <option value="1684">{ pretty('1684') }</option>
                        <option value="1685">{ pretty('1685') }</option>
                        <option value="1686">{ pretty('1686') }</option>
                        <option value="1687">{ pretty('1687') }</option>
                        <option value="1688">{ pretty('1688') }</option>
                        <option value="1689">{ pretty('1689') }</option>
                        <option value="1690">{ pretty('1690') }</option>
                        <option value="1691">{ pretty('1691') }</option>
                        <option value="1692">{ pretty('1692') }</option>
                        <option value="1693">{ pretty('1693') }</option>
                        <option value="1694">{ pretty('1694') }</option>
                        <option value="1695">{ pretty('1695') }</option>
                        <option value="1696">{ pretty('1696') }</option>
                        <option value="1697">{ pretty('1697') }</option>
                        <option value="1698">{ pretty('1698') }</option>
                        <option value="1699">{ pretty('1699') }</option>
                        <option value="1700">{ pretty('1700') }</option>
                        <option value="1702">{ pretty('1702') }</option>
                        <option value="1703">{ pretty('1703') }</option>
                        <option value="1704">{ pretty('1704') }</option>
                        <option value="1706">{ pretty('1706') }</option>
                        <option value="1707">{ pretty('1707') }</option>
                        <option value="1708">{ pretty('1708') }</option>
                        <option value="1709">{ pretty('1709') }</option>
                        <option value="1710">{ pretty('1710') }</option>
                        <option value="1711">{ pretty('1711') }</option>
                        <option value="1712">{ pretty('1712') }</option>
                        <option value="1713">{ pretty('1713') }</option>
                        <option value="1714">{ pretty('1714') }</option>
                        <option value="1715">{ pretty('1715') }</option>
                        <option value="1716">{ pretty('1716') }</option>
                        <option value="1717">{ pretty('1717') }</option>
                        <option value="1718">{ pretty('1718') }</option>
                        <option value="1719">{ pretty('1719') }</option>
                        <option value="1720">{ pretty('1720') }</option>
                        <option value="1721">{ pretty('1721') }</option>
                        <option value="1722">{ pretty('1722') }</option>
                        <option value="1723">{ pretty('1723') }</option>
                        <option value="1724">{ pretty('1724') }</option>
                        <option value="1725">{ pretty('1725') }</option>
                        <option value="1726">{ pretty('1726') }</option>
                        <option value="1727">{ pretty('1727') }</option>
                        <option value="1728">{ pretty('1728') }</option>
                        <option value="1729">{ pretty('1729') }</option>
                        <option value="1730">{ pretty('1730') }</option>
                        <option value="1731">{ pretty('1731') }</option>
                        <option value="1732">{ pretty('1732') }</option>
                        <option value="1733">{ pretty('1733') }</option>
                        <option value="1734">{ pretty('1734') }</option>
                        <option value="1735">{ pretty('1735') }</option>
                        <option value="1736">{ pretty('1736') }</option>
                        <option value="1737">{ pretty('1737') }</option>
                        <option value="1738">{ pretty('1738') }</option>
                        <option value="1739">{ pretty('1739') }</option>
                        <option value="1740">{ pretty('1740') }</option>
                        <option value="1741">{ pretty('1741') }</option>
                        <option value="1742">{ pretty('1742') }</option>
                        <option value="1743">{ pretty('1743') }</option>
                        <option value="1744">{ pretty('1744') }</option>
                        <option value="1745">{ pretty('1745') }</option>
                        <option value="1746">{ pretty('1746') }</option>
                        <option value="1747">{ pretty('1747') }</option>
                        <option value="1748">{ pretty('1748') }</option>
                        <option value="1749">{ pretty('1749') }</option>
                        <option value="1750">{ pretty('1750') }</option>
                        <option value="1751">{ pretty('1751') }</option>
                        <option value="1752">{ pretty('1752') }</option>
                        <option value="1753">{ pretty('1753') }</option>
                        <option value="1754">{ pretty('1754') }</option>
                        <option value="1755">{ pretty('1755') }</option>
                        <option value="1756">{ pretty('1756') }</option>
                        <option value="1757">{ pretty('1757') }</option>
                        <option value="1758">{ pretty('1758') }</option>
                        <option value="1759">{ pretty('1759') }</option>
                        <option value="1760">{ pretty('1760') }</option>
                        <option value="1761">{ pretty('1761') }</option>
                        <option value="1762">{ pretty('1762') }</option>
                        <option value="1763">{ pretty('1763') }</option>
                        <option value="1764">{ pretty('1764') }</option>
                        <option value="1765">{ pretty('1765') }</option>
                        <option value="1766">{ pretty('1766') }</option>
                        <option value="1767">{ pretty('1767') }</option>
                        <option value="1768">{ pretty('1768') }</option>
                        <option value="1769">{ pretty('1769') }</option>
                        <option value="1770">{ pretty('1770') }</option>
                        <option value="1771">{ pretty('1771') }</option>
                        <option value="1772">{ pretty('1772') }</option>
                        <option value="1773">{ pretty('1773') }</option>
                        <option value="1774">{ pretty('1774') }</option>
                        <option value="1775">{ pretty('1775') }</option>
                        <option value="1776">{ pretty('1776') }</option>
                        <option value="1777">{ pretty('1777') }</option>
                        <option value="1778">{ pretty('1778') }</option>
                        <option value="1779">{ pretty('1779') }</option>
                        <option value="1780">{ pretty('1780') }</option>
                        <option value="1781">{ pretty('1781') }</option>
                        <option value="1782">{ pretty('1782') }</option>
                        <option value="1783">{ pretty('1783') }</option>
                        <option value="1784">{ pretty('1784') }</option>
                        <option value="1785">{ pretty('1785') }</option>
                        <option value="1786">{ pretty('1786') }</option>
                        <option value="1787">{ pretty('1787') }</option>
                        <option value="1788">{ pretty('1788') }</option>
                        <option value="1789">{ pretty('1789') }</option>
                        <option value="1790">{ pretty('1790') }</option>
                        <option value="1791">{ pretty('1791') }</option>
                        <option value="1792">{ pretty('1792') }</option>
                        <option value="1793">{ pretty('1793') }</option>
                        <option value="1794">{ pretty('1794') }</option>
                        <option value="1795">{ pretty('1795') }</option>
                        <option value="1796">{ pretty('1796') }</option>
                        <option value="1797">{ pretty('1797') }</option>
                        <option value="1798">{ pretty('1798') }</option>
                        <option value="1799">{ pretty('1799') }</option>
                        <option value="1800">{ pretty('1800') }</option>
                        <option value="1801">{ pretty('1801') }</option>
                        <option value="1802">{ pretty('1802') }</option>
                        <option value="1803">{ pretty('1803') }</option>
                        <option value="1804">{ pretty('1804') }</option>
                        <option value="1805">{ pretty('1805') }</option>
                        <option value="1806">{ pretty('1806') }</option>
                        <option value="1807">{ pretty('1807') }</option>
                        <option value="1808">{ pretty('1808') }</option>
                        <option value="1809">{ pretty('1809') }</option>
                        <option value="1810">{ pretty('1810') }</option>
                        <option value="1811">{ pretty('1811') }</option>
                        <option value="1812">{ pretty('1812') }</option>
                        <option value="1813">{ pretty('1813') }</option>
                        <option value="1814">{ pretty('1814') }</option>
                        <option value="1815">{ pretty('1815') }</option>
                        <option value="1816">{ pretty('1816') }</option>
                        <option value="1817">{ pretty('1817') }</option>
                        <option value="1818">{ pretty('1818') }</option>
                        <option value="1819">{ pretty('1819') }</option>
                        <option value="1820">{ pretty('1820') }</option>
                        <option value="1821">{ pretty('1821') }</option>
                        <option value="1822">{ pretty('1822') }</option>
                        <option value="1823">{ pretty('1823') }</option>
                        <option value="1824">{ pretty('1824') }</option>
                        <option value="1825">{ pretty('1825') }</option>
                        <option value="1826">{ pretty('1826') }</option>
                        <option value="1827">{ pretty('1827') }</option>
                        <option value="1828">{ pretty('1828') }</option>
                        <option value="1829">{ pretty('1829') }</option>
                        <option value="1830">{ pretty('1830') }</option>
                        <option value="1831">{ pretty('1831') }</option>
                        <option value="1832">{ pretty('1832') }</option>
                        <option value="1833">{ pretty('1833') }</option>
                        <option value="1834">{ pretty('1834') }</option>
                        <option value="1835">{ pretty('1835') }</option>
                        <option value="1836">{ pretty('1836') }</option>
                        <option value="1837">{ pretty('1837') }</option>
                        <option value="1838">{ pretty('1838') }</option>
                        <option value="1839">{ pretty('1839') }</option>
                        <option value="1840">{ pretty('1840') }</option>
                        <option value="1841">{ pretty('1841') }</option>
                        <option value="1842">{ pretty('1842') }</option>
                        <option value="1843">{ pretty('1843') }</option>
                        <option value="1844">{ pretty('1844') }</option>
                        <option value="1845">{ pretty('1845') }</option>
                        <option value="1846">{ pretty('1846') }</option>
                        <option value="1847">{ pretty('1847') }</option>
                        <option value="1848">{ pretty('1848') }</option>
                        <option value="1849">{ pretty('1849') }</option>
                        <option value="1850">{ pretty('1850') }</option>
                        <option value="1851">{ pretty('1851') }</option>
                        <option value="1852">{ pretty('1852') }</option>
                        <option value="1853">{ pretty('1853') }</option>
                        <option value="1854">{ pretty('1854') }</option>
                        <option value="1855">{ pretty('1855') }</option>
                        <option value="1856">{ pretty('1856') }</option>
                        <option value="1857">{ pretty('1857') }</option>
                        <option value="1858">{ pretty('1858') }</option>
                        <option value="1859">{ pretty('1859') }</option>
                        <option value="1860">{ pretty('1860') }</option>
                        <option value="1861">{ pretty('1861') }</option>
                        <option value="1862">{ pretty('1862') }</option>
                        <option value="1863">{ pretty('1863') }</option>
                        <option value="1864">{ pretty('1864') }</option>
                        <option value="1865">{ pretty('1865') }</option>
                        <option value="1866">{ pretty('1866') }</option>
                        <option value="1867">{ pretty('1867') }</option>
                        <option value="1868">{ pretty('1868') }</option>
                        <option value="1869">{ pretty('1869') }</option>
                        <option value="1870">{ pretty('1870') }</option>
                        <option value="1871">{ pretty('1871') }</option>
                        <option value="1872">{ pretty('1872') }</option>
                        <option value="1873">{ pretty('1873') }</option>
                        <option value="1874">{ pretty('1874') }</option>
                        <option value="1875">{ pretty('1875') }</option>
                        <option value="1876">{ pretty('1876') }</option>
                        <option value="1877">{ pretty('1877') }</option>
                        <option value="1878">{ pretty('1878') }</option>
                        <option value="1879">{ pretty('1879') }</option>
                        <option value="1880">{ pretty('1880') }</option>
                        <option value="1881">{ pretty('1881') }</option>
                        <option value="1882">{ pretty('1882') }</option>
                        <option value="1883">{ pretty('1883') }</option>
                        <option value="1884">{ pretty('1884') }</option>
                        <option value="1885">{ pretty('1885') }</option>
                        <option value="1886">{ pretty('1886') }</option>
                        <option value="1887">{ pretty('1887') }</option>
                        <option value="1888">{ pretty('1888') }</option>
                        <option value="1889">{ pretty('1889') }</option>
                        <option value="1890">{ pretty('1890') }</option>
                        <option value="1891">{ pretty('1891') }</option>
                        <option value="1892">{ pretty('1892') }</option>
                        <option value="1893">{ pretty('1893') }</option>
                        <option value="1894">{ pretty('1894') }</option>
                        <option value="1895">{ pretty('1895') }</option>
                        <option value="1896">{ pretty('1896') }</option>
                        <option value="1897">{ pretty('1897') }</option>
                        <option value="1898">{ pretty('1898') }</option>
                        <option value="1899">{ pretty('1899') }</option>
                        <option value="1900">{ pretty('1900') }</option>
                        <option value="1901">{ pretty('1901') }</option>
                        <option value="1902">{ pretty('1902') }</option>
                        <option value="1903">{ pretty('1903') }</option>
                        <option value="1904">{ pretty('1904') }</option>
                        <option value="1905">{ pretty('1905') }</option>
                        <option value="1906">{ pretty('1906') }</option>
                        <option value="1907">{ pretty('1907') }</option>
                        <option value="1908">{ pretty('1908') }</option>
                        <option value="1909">{ pretty('1909') }</option>
                        <option value="1910">{ pretty('1910') }</option>
                        <option value="1911">{ pretty('1911') }</option>
                        <option value="1912">{ pretty('1912') }</option>
                        <option value="1913">{ pretty('1913') }</option>
                    </select>
                    <button type="button" className="btn btn-outline-input-group btn-light bg-white text-muted input-date-help" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-date" onClick={ handleChangeHelpslug }>
                        <span className="material-symbols-sharp">help_outline</span>
                    </button>
                </div>
            </div>
            {/*
            <div className="input-date-dash">
                <i className="bi bi-dash-lg"></i>
            </div>
            */}
            <div className="col-12">
                <div className="input-group input-date-bottom">
                    <span className="input-group-text bg-white text-muted input-date-label">To</span>
                    <select className="form-select" name="month_lte" id="field_month_lte" value={ values['month_lte'] || '' } onChange={ handleChange }>
                        <option value="">&lt; Any { pretty('month') } &gt;</option>
                        <option value="12">December</option>
                        <option value="11">November</option>
                        <option value="10">October</option>
                        <option value="9">September</option>
                        <option value="8">August</option>
                        <option value="7">July</option>
                        <option value="6">June</option>
                        <option value="5">May</option>
                        <option value="4">April</option>
                        <option value="3">March</option>
                        <option value="2">February</option>
                        <option value="1">January</option>
                    </select>
                    <select className="form-select" name="year_lte" id="field_year_lte" value={ values['year_lte'] || '' } onChange={ handleChange }>
                        <option value="">&lt; Any { pretty('year') } &gt;</option>
                        <option value="1913">{ pretty('1913') }</option>
                        <option value="1912">{ pretty('1912') }</option>
                        <option value="1911">{ pretty('1911') }</option>
                        <option value="1910">{ pretty('1910') }</option>
                        <option value="1909">{ pretty('1909') }</option>
                        <option value="1908">{ pretty('1908') }</option>
                        <option value="1907">{ pretty('1907') }</option>
                        <option value="1906">{ pretty('1906') }</option>
                        <option value="1905">{ pretty('1905') }</option>
                        <option value="1904">{ pretty('1904') }</option>
                        <option value="1903">{ pretty('1903') }</option>
                        <option value="1902">{ pretty('1902') }</option>
                        <option value="1901">{ pretty('1901') }</option>
                        <option value="1900">{ pretty('1900') }</option>
                        <option value="1899">{ pretty('1899') }</option>
                        <option value="1898">{ pretty('1898') }</option>
                        <option value="1897">{ pretty('1897') }</option>
                        <option value="1896">{ pretty('1896') }</option>
                        <option value="1895">{ pretty('1895') }</option>
                        <option value="1894">{ pretty('1894') }</option>
                        <option value="1893">{ pretty('1893') }</option>
                        <option value="1892">{ pretty('1892') }</option>
                        <option value="1891">{ pretty('1891') }</option>
                        <option value="1890">{ pretty('1890') }</option>
                        <option value="1889">{ pretty('1889') }</option>
                        <option value="1888">{ pretty('1888') }</option>
                        <option value="1887">{ pretty('1887') }</option>
                        <option value="1886">{ pretty('1886') }</option>
                        <option value="1885">{ pretty('1885') }</option>
                        <option value="1884">{ pretty('1884') }</option>
                        <option value="1883">{ pretty('1883') }</option>
                        <option value="1882">{ pretty('1882') }</option>
                        <option value="1881">{ pretty('1881') }</option>
                        <option value="1880">{ pretty('1880') }</option>
                        <option value="1879">{ pretty('1879') }</option>
                        <option value="1878">{ pretty('1878') }</option>
                        <option value="1877">{ pretty('1877') }</option>
                        <option value="1876">{ pretty('1876') }</option>
                        <option value="1875">{ pretty('1875') }</option>
                        <option value="1874">{ pretty('1874') }</option>
                        <option value="1873">{ pretty('1873') }</option>
                        <option value="1872">{ pretty('1872') }</option>
                        <option value="1871">{ pretty('1871') }</option>
                        <option value="1870">{ pretty('1870') }</option>
                        <option value="1869">{ pretty('1869') }</option>
                        <option value="1868">{ pretty('1868') }</option>
                        <option value="1867">{ pretty('1867') }</option>
                        <option value="1866">{ pretty('1866') }</option>
                        <option value="1865">{ pretty('1865') }</option>
                        <option value="1864">{ pretty('1864') }</option>
                        <option value="1863">{ pretty('1863') }</option>
                        <option value="1862">{ pretty('1862') }</option>
                        <option value="1861">{ pretty('1861') }</option>
                        <option value="1860">{ pretty('1860') }</option>
                        <option value="1859">{ pretty('1859') }</option>
                        <option value="1858">{ pretty('1858') }</option>
                        <option value="1857">{ pretty('1857') }</option>
                        <option value="1856">{ pretty('1856') }</option>
                        <option value="1855">{ pretty('1855') }</option>
                        <option value="1854">{ pretty('1854') }</option>
                        <option value="1853">{ pretty('1853') }</option>
                        <option value="1852">{ pretty('1852') }</option>
                        <option value="1851">{ pretty('1851') }</option>
                        <option value="1850">{ pretty('1850') }</option>
                        <option value="1849">{ pretty('1849') }</option>
                        <option value="1848">{ pretty('1848') }</option>
                        <option value="1847">{ pretty('1847') }</option>
                        <option value="1846">{ pretty('1846') }</option>
                        <option value="1845">{ pretty('1845') }</option>
                        <option value="1844">{ pretty('1844') }</option>
                        <option value="1843">{ pretty('1843') }</option>
                        <option value="1842">{ pretty('1842') }</option>
                        <option value="1841">{ pretty('1841') }</option>
                        <option value="1840">{ pretty('1840') }</option>
                        <option value="1839">{ pretty('1839') }</option>
                        <option value="1838">{ pretty('1838') }</option>
                        <option value="1837">{ pretty('1837') }</option>
                        <option value="1836">{ pretty('1836') }</option>
                        <option value="1835">{ pretty('1835') }</option>
                        <option value="1834">{ pretty('1834') }</option>
                        <option value="1833">{ pretty('1833') }</option>
                        <option value="1832">{ pretty('1832') }</option>
                        <option value="1831">{ pretty('1831') }</option>
                        <option value="1830">{ pretty('1830') }</option>
                        <option value="1829">{ pretty('1829') }</option>
                        <option value="1828">{ pretty('1828') }</option>
                        <option value="1827">{ pretty('1827') }</option>
                        <option value="1826">{ pretty('1826') }</option>
                        <option value="1825">{ pretty('1825') }</option>
                        <option value="1824">{ pretty('1824') }</option>
                        <option value="1823">{ pretty('1823') }</option>
                        <option value="1822">{ pretty('1822') }</option>
                        <option value="1821">{ pretty('1821') }</option>
                        <option value="1820">{ pretty('1820') }</option>
                        <option value="1819">{ pretty('1819') }</option>
                        <option value="1818">{ pretty('1818') }</option>
                        <option value="1817">{ pretty('1817') }</option>
                        <option value="1816">{ pretty('1816') }</option>
                        <option value="1815">{ pretty('1815') }</option>
                        <option value="1814">{ pretty('1814') }</option>
                        <option value="1813">{ pretty('1813') }</option>
                        <option value="1812">{ pretty('1812') }</option>
                        <option value="1811">{ pretty('1811') }</option>
                        <option value="1810">{ pretty('1810') }</option>
                        <option value="1809">{ pretty('1809') }</option>
                        <option value="1808">{ pretty('1808') }</option>
                        <option value="1807">{ pretty('1807') }</option>
                        <option value="1806">{ pretty('1806') }</option>
                        <option value="1805">{ pretty('1805') }</option>
                        <option value="1804">{ pretty('1804') }</option>
                        <option value="1803">{ pretty('1803') }</option>
                        <option value="1802">{ pretty('1802') }</option>
                        <option value="1801">{ pretty('1801') }</option>
                        <option value="1800">{ pretty('1800') }</option>
                        <option value="1799">{ pretty('1799') }</option>
                        <option value="1798">{ pretty('1798') }</option>
                        <option value="1797">{ pretty('1797') }</option>
                        <option value="1796">{ pretty('1796') }</option>
                        <option value="1795">{ pretty('1795') }</option>
                        <option value="1794">{ pretty('1794') }</option>
                        <option value="1793">{ pretty('1793') }</option>
                        <option value="1792">{ pretty('1792') }</option>
                        <option value="1791">{ pretty('1791') }</option>
                        <option value="1790">{ pretty('1790') }</option>
                        <option value="1789">{ pretty('1789') }</option>
                        <option value="1788">{ pretty('1788') }</option>
                        <option value="1787">{ pretty('1787') }</option>
                        <option value="1786">{ pretty('1786') }</option>
                        <option value="1785">{ pretty('1785') }</option>
                        <option value="1784">{ pretty('1784') }</option>
                        <option value="1783">{ pretty('1783') }</option>
                        <option value="1782">{ pretty('1782') }</option>
                        <option value="1781">{ pretty('1781') }</option>
                        <option value="1780">{ pretty('1780') }</option>
                        <option value="1779">{ pretty('1779') }</option>
                        <option value="1778">{ pretty('1778') }</option>
                        <option value="1777">{ pretty('1777') }</option>
                        <option value="1776">{ pretty('1776') }</option>
                        <option value="1775">{ pretty('1775') }</option>
                        <option value="1774">{ pretty('1774') }</option>
                        <option value="1773">{ pretty('1773') }</option>
                        <option value="1772">{ pretty('1772') }</option>
                        <option value="1771">{ pretty('1771') }</option>
                        <option value="1770">{ pretty('1770') }</option>
                        <option value="1769">{ pretty('1769') }</option>
                        <option value="1768">{ pretty('1768') }</option>
                        <option value="1767">{ pretty('1767') }</option>
                        <option value="1766">{ pretty('1766') }</option>
                        <option value="1765">{ pretty('1765') }</option>
                        <option value="1764">{ pretty('1764') }</option>
                        <option value="1763">{ pretty('1763') }</option>
                        <option value="1762">{ pretty('1762') }</option>
                        <option value="1761">{ pretty('1761') }</option>
                        <option value="1760">{ pretty('1760') }</option>
                        <option value="1759">{ pretty('1759') }</option>
                        <option value="1758">{ pretty('1758') }</option>
                        <option value="1757">{ pretty('1757') }</option>
                        <option value="1756">{ pretty('1756') }</option>
                        <option value="1755">{ pretty('1755') }</option>
                        <option value="1754">{ pretty('1754') }</option>
                        <option value="1753">{ pretty('1753') }</option>
                        <option value="1752">{ pretty('1752') }</option>
                        <option value="1751">{ pretty('1751') }</option>
                        <option value="1750">{ pretty('1750') }</option>
                        <option value="1749">{ pretty('1749') }</option>
                        <option value="1748">{ pretty('1748') }</option>
                        <option value="1747">{ pretty('1747') }</option>
                        <option value="1746">{ pretty('1746') }</option>
                        <option value="1745">{ pretty('1745') }</option>
                        <option value="1744">{ pretty('1744') }</option>
                        <option value="1743">{ pretty('1743') }</option>
                        <option value="1742">{ pretty('1742') }</option>
                        <option value="1741">{ pretty('1741') }</option>
                        <option value="1740">{ pretty('1740') }</option>
                        <option value="1739">{ pretty('1739') }</option>
                        <option value="1738">{ pretty('1738') }</option>
                        <option value="1737">{ pretty('1737') }</option>
                        <option value="1736">{ pretty('1736') }</option>
                        <option value="1735">{ pretty('1735') }</option>
                        <option value="1734">{ pretty('1734') }</option>
                        <option value="1733">{ pretty('1733') }</option>
                        <option value="1732">{ pretty('1732') }</option>
                        <option value="1731">{ pretty('1731') }</option>
                        <option value="1730">{ pretty('1730') }</option>
                        <option value="1729">{ pretty('1729') }</option>
                        <option value="1728">{ pretty('1728') }</option>
                        <option value="1727">{ pretty('1727') }</option>
                        <option value="1726">{ pretty('1726') }</option>
                        <option value="1725">{ pretty('1725') }</option>
                        <option value="1724">{ pretty('1724') }</option>
                        <option value="1723">{ pretty('1723') }</option>
                        <option value="1722">{ pretty('1722') }</option>
                        <option value="1721">{ pretty('1721') }</option>
                        <option value="1720">{ pretty('1720') }</option>
                        <option value="1719">{ pretty('1719') }</option>
                        <option value="1718">{ pretty('1718') }</option>
                        <option value="1717">{ pretty('1717') }</option>
                        <option value="1716">{ pretty('1716') }</option>
                        <option value="1715">{ pretty('1715') }</option>
                        <option value="1714">{ pretty('1714') }</option>
                        <option value="1713">{ pretty('1713') }</option>
                        <option value="1712">{ pretty('1712') }</option>
                        <option value="1711">{ pretty('1711') }</option>
                        <option value="1710">{ pretty('1710') }</option>
                        <option value="1709">{ pretty('1709') }</option>
                        <option value="1708">{ pretty('1708') }</option>
                        <option value="1707">{ pretty('1707') }</option>
                        <option value="1706">{ pretty('1706') }</option>
                        <option value="1704">{ pretty('1704') }</option>
                        <option value="1703">{ pretty('1703') }</option>
                        <option value="1702">{ pretty('1702') }</option>
                        <option value="1700">{ pretty('1700') }</option>
                        <option value="1699">{ pretty('1699') }</option>
                        <option value="1698">{ pretty('1698') }</option>
                        <option value="1697">{ pretty('1697') }</option>
                        <option value="1696">{ pretty('1696') }</option>
                        <option value="1695">{ pretty('1695') }</option>
                        <option value="1694">{ pretty('1694') }</option>
                        <option value="1693">{ pretty('1693') }</option>
                        <option value="1692">{ pretty('1692') }</option>
                        <option value="1691">{ pretty('1691') }</option>
                        <option value="1690">{ pretty('1690') }</option>
                        <option value="1689">{ pretty('1689') }</option>
                        <option value="1688">{ pretty('1688') }</option>
                        <option value="1687">{ pretty('1687') }</option>
                        <option value="1686">{ pretty('1686') }</option>
                        <option value="1685">{ pretty('1685') }</option>
                        <option value="1684">{ pretty('1684') }</option>
                        <option value="1683">{ pretty('1683') }</option>
                        <option value="1682">{ pretty('1682') }</option>
                        <option value="1681">{ pretty('1681') }</option>
                        <option value="1680">{ pretty('1680') }</option>
                        <option value="1679">{ pretty('1679') }</option>
                        <option value="1678">{ pretty('1678') }</option>
                        <option value="1677">{ pretty('1677') }</option>
                        <option value="1676">{ pretty('1676') }</option>
                        <option value="1675">{ pretty('1675') }</option>
                        <option value="1674">{ pretty('1674') }</option>
                    </select>
                    <button type="button" className="btn btn-outline-input-group btn-light bg-white text-muted input-date-help" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-date" onClick={ handleChangeHelpslug }>
                        { /*<span className="material-symbols-sharp">help_outline</span>*/ }
                    </button>
                </div>
            </div>
        </>

    );

};

export default FieldDate;
