
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const usePaging = (pagesize) => {

    const location = useLocation();
    let history = useHistory();

    const [page, setPage] = useState(1);

    useEffect(() => {

        const qs = queryString.parse(location.search, { arrayFormat: "bracket" });
        setPage(Math.ceil((qs['from'] || 0) / pagesize) + 1)

    }, [ location.search, pagesize ]);
    
    const updatePage = (total, canpage) => {

        if (canpage < 1) { canpage = 1; }

        const maxpage = Math.ceil(total / pagesize);
        if (canpage > maxpage) { canpage = maxpage; } 

        const canfrom = (canpage * pagesize) - pagesize;

        const vals = { 'from': canfrom };
        const qs = queryString.parse(location.search, { arrayFormat: "bracket" });
        const newvals = {...qs, ...vals};

        const stringified = queryString.stringify(newvals, { arrayFormat: "bracket" });
        history.push({ pathname: location.pathname, search: stringified, hash: 'results' });

        const results = document.getElementById('results'); // Unfortunately we need belt and braces here
                                                            // Although we set the hash above, this will not move the viewport
        if (results) {                                      // if the hash was already '#results'.
            results.scrollIntoView({ behavior: 'smooth' }); // So we do it again here.
        }                                                   

    }

    const handleChangePageField = (event) => {

        const canpage = event.target.value;
        setPage(canpage);

    };

    const handleSubmitPageForm = (event, total) => {

        event.preventDefault();

        let canpage = parseInt(page) || 1;
        updatePage(total, canpage);

    }

    const handleNextPageButton = (event, total) => {

        event.preventDefault();

        let canpage = parseInt(page) || 1;
        canpage = canpage + 1;
        updatePage(total, canpage);

    }

    const handlePreviousPageButton = (event, total) => {

        event.preventDefault();

        let canpage = parseInt(page) || 1;
        canpage = canpage - 1;
        updatePage(total, canpage);

    }

    const handleLastPageButton = (event, total) => {

        event.preventDefault();

        updatePage(total, Math.ceil(total / pagesize));

    }

    const handleFirstPageButton = (event, total) => {

        event.preventDefault();

        updatePage(total, 1);

    }

    const handleEditQueryButton = (event) => {

        event.preventDefault();

        history.push({ pathname: location.pathname, search: location.search, hash: '' });

    }

    return [ page, handleChangePageField, handleSubmitPageForm, handleNextPageButton, handlePreviousPageButton, handleLastPageButton, handleFirstPageButton, handleEditQueryButton ];
    
};

export default usePaging;
