
const Field = (props) => {
    
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <select className="form-select" name="victim_institution" id="field_victim_institution" value={ values['victim_institution'] || '' } onChange={ handleChange }>
                <option value="">&lt; All types of victim &gt;</option>
                <option value="yes">Institution</option>
                <option value="no">Individual</option>
            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-victim_institution" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
