
const Field = (props) => {
    
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>
            <input type="search" className="form-control" name="offence_description" id="field_offence_description" placeholder={ 'Search offence descriptions (by keyword) ...' } value={ values['offence_description'] || '' } onChange={ handleChange } />
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-offence_description" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
