
import FieldKeyword from "./FieldKeyword";
import FieldSearchIn from "./FieldSearchIn";
import FieldDate from "./FieldDate";
import FieldCrime from "./FieldCrime";
import FieldPlea from "./FieldPlea";
import FieldVerdict from "./FieldVerdict";
import FieldPunishment from "./FieldPunishment";
import FieldSurname from "./FieldSurname";
import FieldGivenName from "./FieldGivenName";
import FieldAlias from "./FieldAlias";
import FieldRole from "./FieldRole";
import FieldGender from "./FieldGender";
import FieldOccupation from "./FieldOccupation";
import FieldInstitutionalVictim from "./FieldInstitutionalVictim";
import FieldAge from "./FieldAge";
import FieldDefendantGender from "./FieldDefendantGender";
import FieldVictimGender from "./FieldVictimGender";
import FieldDefendantOccupation from "./FieldDefendantOccupation";
import FieldVictimOccupation from "./FieldVictimOccupation";
import FieldDefendantAge from "./FieldDefendantAge";
import FieldVictimAge from "./FieldVictimAge";
import FieldStatsKeyword from "./FieldStatsKeyword";
import FieldStatsCat1 from "./FieldStatsCat1";
import FieldStatsCat2 from "./FieldStatsCat2";
import FieldStatsCountBy from "./FieldStatsCountBy";
import FieldCrimeDate from "./FieldCrimeDate";
import FieldCrimeKeyword from "./FieldCrimeKeyword";
import FieldCrimeLocation from "./FieldCrimeLocation";
import FieldVerdictKeyword from "./FieldVerdictKeyword";
import FieldPunishmentKeyword from "./FieldPunishmentKeyword";
import FieldDefendantHome from "./FieldDefendantHome";
import FieldVictimCategory from "./FieldVictimCategory";
import FieldVictimGroup from "./FieldVictimGroup";
//import FieldVictimHome from "./FieldVictimHome";
import FieldPlaceName from "./FieldPlaceName";
import FieldAssocrecKeyword from "./FieldAssocrecKeyword";
import FieldAssocrecLibarch from "./FieldAssocrecLibarch";
import FieldAssocrecDocumentType from "./FieldAssocrecDocumentType";
import FieldAssocrecCrimeKeyword from "./FieldAssocrecCrimeKeyword";
//import FieldAssocrecDocumentDate from "./FieldAssocrecDocumentDate";
import FieldAssocrecLinkedToTrial from "./FieldAssocrecLinkedToTrial";
import FieldAssocrecSurname from "./FieldAssocrecSurname";
import FieldAssocrecGivenName from "./FieldAssocrecGivenName";
//import FieldRefNumber from "./FieldRefNumber";
import FieldSortBy from "./FieldSortBy";
import FieldTrialLength from "./FieldTrialLength";

import FieldOAKeyword from "./FieldOAKeyword";
import FieldOADate from "./FieldOADate";
import FieldOAOccupation from "./FieldOAOccupation";
import FieldOAPlaceName from "./FieldOAPlaceName";

import FieldNameKeyword from "./FieldNameKeyword";


import RefineButton from "./RefineButton";

const SearchFields = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;
    const searchmode = props.searchmode;
    const showResults = props.showResults;

    return (

<>

{ searchmode === 'keyword' && (

    <>

        { showResults && (

            <>

                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/><RefineButton/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldSearchIn pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row"><FieldDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>

            </>

        ) }

        <h4 className="mt-4">Trial Details</h4>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrime pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPlea pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVerdict pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row"><div className="col-12"><div className="input-group"><FieldPunishment pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         
        <h4 className="mt-4">Name</h4>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldSurname pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldGivenName pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row"><div className="col-12"><div className="input-group"><FieldAlias pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>

        <button type="submit" className="d-none">submit</button>
    </>

) }

{ searchmode === 'name' && (

    <>

        { showResults && (

            <>

                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldSurname first={ true } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/><RefineButton/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldGivenName pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAlias pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row"><FieldDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>


            </>

        ) }

        <div className="row mt-4 mb-1"><div className="col-12"><div className="input-group"><FieldNameKeyword first={ true } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row"><div className="col-12"><div className="input-group"><FieldSearchIn pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
 

        <h4 className="mt-2">Personal Details</h4>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldRole pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldGender pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldOccupation pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        {/*<div className="row mb-1"><div className="col-12"><div className="input-group"><FieldInstitutionalVictim pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>*/}
        <div className="row"><div className="col-12"><div className="input-group"><FieldAge pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div> 

        <h4 className="mt-2">Trial Details</h4>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrime pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPlea pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVerdict pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row"><div className="col-12"><div className="input-group"><FieldPunishment pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>

        <button type="submit" className="d-none">submit</button>
    </>

) }

{ searchmode === 'crime' && (

    <>

        { showResults && (

            <>

                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrime pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/><RefineButton/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPlea pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVerdict pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPunishment pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row"><FieldDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>

            </>

        ) }

        <div className="row mt-4 mb-1"><div className="col-12"><div className="input-group"><FieldKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        {/*<div className="row"><div className="col-12"><div className="input-group"><FieldSearchIn pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>*/}
 
        <h4 className="mt-1">Name</h4>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldSurname first={ true } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldGivenName pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAlias pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>

        <h4 className="mt-4">Personal Details</h4>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldDefendantGender pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimGender pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldDefendantOccupation pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimOccupation pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldDefendantAge pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row"><div className="col-12"><div className="input-group"><FieldVictimAge pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div> 

        <button type="submit" className="d-none">submit</button>
    </>

) }

{ searchmode === 'statistical' && (

    <>

        { showResults && (

            <>

                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldStatsCat1 pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/><RefineButton/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldStatsCat2 pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldStatsCountBy pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                 
            </>

        ) }

        <div className="row mt-4 mb-1"><div className="col-12"><div className="input-group"><FieldStatsKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><FieldDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrimeDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
 
        <h4 className="mt-4">Trial Details</h4>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrime pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrimeKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrimeLocation pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPlea pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVerdict pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVerdictKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPunishment pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPunishmentKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>

        <h4 className="mt-4">Defendant Details</h4>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldDefendantGender pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldDefendantAge pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldDefendantOccupation pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldDefendantHome pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
 
        <h4 className="mt-4">Victim Details</h4>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimGender pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimAge pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimOccupation pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimCategory pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimGroup pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldInstitutionalVictim pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        {/*<div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimHome pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>*/}

        <button type="submit" className="d-none">submit</button>
    </>

) }

{ searchmode === 'ordinarys_accounts' && (

    <>

        { showResults && (

            <>

                <div className="row mt-4 mb-1"><div className="col-12"><div className="input-group"><FieldOAKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/><RefineButton/></div></div></div>      
                <div className="row mb-1"><FieldOADate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>

            </>

        ) }

        <div className="row mb-1 mt-4"><div className="col-12"><div className="input-group"><FieldSurname pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldGivenName pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldOAOccupation pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldOAPlaceName pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>

        <button type="submit" className="d-none">submit</button>
    </>

) }

{ searchmode === 'associated_records' && (

    <>

        { showResults && (

            <>

                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAssocrecSurname first={ true } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/><RefineButton/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAssocrecGivenName pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAssocrecLinkedToTrial pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row mt-4 mb-1"><FieldDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>

            </>

        ) }

        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAssocrecKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAssocrecDocumentType pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAssocrecLibarch pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAssocrecCrimeKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        {/*<div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAssocrecDocumentDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>*/}

        <button type="submit" className="d-none">submit</button>
    </>

) }

{ searchmode === 'advanced' && (

    <>

        { showResults && (

            <>

                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/><RefineButton/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrime pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPlea pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVerdict pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPunishment pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
                <div className="row mt-4 mb-1"><FieldDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>

            </>

        ) }

         <div className="row mt-4 mb-1"><div className="col-12"><div className="input-group"><FieldSearchIn pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         {/*<div className="row mb-1"><div className="col-12"><div className="input-group"><FieldRefNumber pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>*/}
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldSortBy pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldTrialLength pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         
         <h4 className="mt-4">Name</h4>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldSurname pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldGivenName pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAlias pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         
         <h4 className="mt-4">Defendant Details</h4>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldDefendantGender pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldDefendantAge pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldDefendantOccupation pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         
         <h4 className="mt-4">Victim Details</h4>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimGender pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimAge pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimOccupation pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimCategory pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimGroup pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldInstitutionalVictim pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         
         <h4 className="mt-4">Trial Details</h4>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrimeKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrimeDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVerdictKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPunishmentKeyword pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         
         <h4 className="mt-4">Location</h4>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrimeLocation pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldDefendantHome pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
         {/*<div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVictimHome pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>*/}
         <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPlaceName pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>

        <button type="submit" className="d-none">submit</button>
    </>

) }

</>

    );

};

export default SearchFields;
