
import { useEffect, useState } from "react";

import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = (props) => {

    const [state, setState] = useState({

        name: "",
        email: "",
        message: "",
        gRecaptchaResponse: "",

    });

    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(true);

    useEffect(() => {

        //const re =
        //    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
        //state.name !== "" &&
        //re.test(String(state.email).toLowerCase()) &&
        state.message !== "" &&
        state.gRecaptchaResponse !== ""
    ) {
        setBtnDisabled(false);
    }

    }, [state]);

const onRecaptchaChange = (value) => {

    setState({ ...state, gRecaptchaResponse: value });

};

const handleChange = (event) => {

    const value = event.target.value;
    setState({
        ...state,
        [event.target.name]: value,
    });

};

const handleSubmit = (event) => {

    event.preventDefault();
    const url = `${process.env.REACT_APP_API_URL}/contact`;

    const reqdata = {...state, 'idkey': props.idkey};

    axios
        .post(url, reqdata, { withCredentials: true })
        .then((res) => {

            // console.log(res);

            if (res['data']['success']) {

                setSubmitted(true);

            } else {

               setError(res['data']['message']); 

            }
            // const submitted = res.data.status === "success";
            // const errors = res.data.errors;
            // setErrors(errors);
        })
        .catch((error) => {

            // Error

            if (error.response) {

                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);

                //const errors = error.response.data.errors;
                //setSubmitted(false);
                //setErrors(errors);

                setError('Unfortunately we were not able to record your error report at this time. Please accept our apologies for this inconvenience. The failure has been recorded and we will fix the issue as soon as we can. Please feel free to try again at another time.');

            } else if (error.request) {

                
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                setError('Unfortunately we were not able to record your error report at this time. Please accept our apologies for this inconvenience. The failure has been recorded and we will fix the issue as soon as we can. Please feel free to try again at another time.');

            } else {

                // Something happened in setting up the request and triggered an Error
                // console.log("Error", error.message);
                
                setError('Unfortunately we were not able to record your error report at this time. Please accept our apologies for this inconvenience. The failure has been recorded and we will fix the issue as soon as we can. Please feel free to try again at another time.');

            }
            // console.log(error.config);
        });
};

return (

    <div>    

        { submitted ? (

            <>
                <h2 className="text-success">Your error report has been recorded.</h2>
                <table className="table">
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>Reference number:</th>
                            <td>{ props.idkey }</td>
                        </tr>
                        <tr>
                            <th>Description of error:</th>
                            <td>{ state.message }</td>
                        </tr>
                    </tbody>
                </table>
                <p className="ob-body-text mt-3">Thank you.</p>
            </>

        ) : (

            <>

                <h1>Report an error</h1>

                <p className="ob-body-text mb-4">We welcome any corrections to the transcriptions or markup.</p>

                <form onSubmit={handleSubmit}>

                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Reference number</label>
                        <input type="text" className="form-control" id="idkey" name="idkey" aria-describedby="idkeyHelp" value={ props.idkey } required readOnly />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Your name (optional)</label>
                        <input type="text" className="form-control" id="name" name="name" aria-describedby="nameHelp" value={ state.name } onChange={ handleChange } />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email address (optional)</label>
                        <input type="email" className="form-control" id="email" name="email" aria-describedby="emailHelp" value={ state.email } onChange={ handleChange } />
                        <div id="emailHelp" className="form-text">If you would like a response.</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">Please describe the error</label>
                        <textarea className="form-control" id="message" name="message" rows="6" value={ state.message } required onChange={ handleChange }></textarea>
                    </div>
                    <div className="mb-3">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={onRecaptchaChange} />
                        <input type="hidden" className="form-control" id="gRecaptchaResponse" name="gRecaptchaResponse" value={ state.gRecaptchaResponse } required />
                    </div>

                    { error !== '' && (

                        <div className="text-danger">{ error }</div>

                    ) }

                    <div className="text-end">
                        <button type="submit" disabled={ btnDisabled } className="btn btn-primary">Submit</button>
                    </div>

                </form>

            </>

        ) }

    </div>
);

};

export default ContactForm;
