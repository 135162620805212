
import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./Home";
import About from "./About";
import Record from "./Record";
import Search from "./Search";
import Contact from "./Contact";
import BrowseRedirector from "./BrowseRedirector";
import SearchRedirector from "./SearchRedirector";
import StatsRedirector from "./StatsRedirector";
import StaticRedirector from "./StaticRedirector";

const OBRouter = () => {

    return (

        <Switch>

             {/* You are currently using react-router-dom 5.3.0 */}
        
            {/*<Route path="/oldbailey/record/:idkey" component={ Record } />*/}

            <Route path="/record/:idkey" component={ Record } />
            <Route path="/contact/:idkey" component={ Contact } />
            <Route path="/about/:slug" component={ About } />

            <Route path="/search/:searchmode/:datedecade" component={ Search } />
            <Route path="/search/:searchmode" component={ Search } />
            <Route path="/search" component={ Search } />

            {/* Legacy routes */}
            {/* https://www.oldbaileyonline.org/browse.jsp?id=f17220510-1&div=f17220510-1&terms=sheffield#highlight */}
            <Route path="/browse.jsp" component={ BrowseRedirector } />
            {/* https://www.oldbaileyonline.org/search.jsp?form=searchHomePage&_divs_fulltext=&kwparse=and&_persNames_surname=&_persNames_given=&_persNames_alias=&_offences_offenceCategory_offenceSubcategory=breakingPeace_threateningBehaviour&_verdicts_verdictCategory_verdictSubcategory=&_punishments_punishmentCategory_punishmentSubcategory=&_divs_div0Type_div1Type=&fromMonth=&fromYear=&toMonth=&toYear=&ref=&submit.x=39&submit.y=11 */}
            <Route path="/search.jsp" component={ SearchRedirector } />
            {/* https://www.oldbaileyonline.org/stats.jsp?y=offenceCategory&x=&countBy=&render=&_offences_offenceCategory_offenceSubcategory=&_verdicts_verdictCategory_verdictSubcategory=&_punishments_punishmentCategory_punishmentSubcategory=&_defendantNames_defendantGender=&defendantAgeFrom=&defendantAgeTo=&_victimNames_victimGender=&victimAgeFrom=&victimAgeTo=&_divs_fulltext=&kwparse=and&fromMonth=&fromYear=&toMonth=&toYear= */}
            <Route path="/stats.jsp" component={ StatsRedirector } />
            {/* https://www.oldbaileyonline.org/static/Gender.jsp#genderjustice */}
            <Route path="/static/:page" component={ StaticRedirector } />

            {/* https://www.oldbaileyonline.org/forms/formMain.jsp */}
            <Route path="/forms/formMain.jsp"><Redirect to="/search" /></Route>
            {/* https://www.oldbaileyonline.org/forms/formPersName.jsp */}
            <Route path="/forms/formPersName.jsp"><Redirect to="/search/name" /></Route>
            {/* https://www.oldbaileyonline.org/forms/formOrdinarysAccounts.jsp */}
            <Route path="/forms/formOrdinarysAccounts.jsp"><Redirect to="/search/ordinarys_accounts" /></Route>
            {/* https://www.oldbaileyonline.org/forms/formStats.jsp */}
            <Route path="/forms/formStats.jsp"><Redirect to="/search/statstical" /></Route>
            {/* https://www.oldbaileyonline.org/forms/formCustom.jsp */}
            <Route path="/forms/formCustom.jsp"><Redirect to="/search/advanced" /></Route>
            {/* https://www.oldbaileyonline.org/forms/formAssociatedRecords.jsp */}
            <Route path="/forms/formAssociatedRecords.jsp"><Redirect to="/search/associated_records" /></Route>
            {/* https://www.oldbaileyonline.org/obapi/ */}
            <Route path="/obapi/"><Redirect to="/about/data" /></Route>

            <Route path="/" component={ Home } />

        </Switch>

    );

};

export default OBRouter;
