
const Field = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <select className="form-select" name="verdict" id="field_verdict" value={ values['verdict'] || '' } onChange={ handleChange }>

                <option value="">&lt; Any { pretty('verdict') } &gt;</option>

                <option value="guilty">{ pretty('guilty') } &gt; Any subcategory</option>
                <option value="chanceMedley">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('chanceMedley') }</option>
                <option value="insane">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('insane') }</option>
                <option value="lesserOffence">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('lesserOffence') }</option>
                <option value="manslaughter">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('manslaughter') }</option>
                <option value="pleadedGuilty">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('pleadedGuilty') }</option>
                <option value="pleadedPartGuilty">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('pleadedPartGuilty') }</option>
                <option value="theftunder100s">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('theftunder100s') }</option>
                <option value="theftunder10s">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('theftunder10s') }</option>
                <option value="theftunder1s">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('theftunder1s') }</option>
                <option value="theftunder40s">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('theftunder40s') }</option>
                <option value="theftunder5s">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('theftunder5s') }</option>
                <option value="withRecommendation">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('withRecommendation') }</option>
                <option value="guiltyNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('guilty') } &gt; { pretty('guiltyNoDetail') }</option>

                <option value="miscVerdict">{ pretty('miscVerdict') } &gt; Any subcategory</option>
                <option value="noAgreement">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscVerdict') } &gt; { pretty('noAgreement') }</option>
                <option value="postponed">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscVerdict') } &gt; { pretty('postponed') }</option>
                <option value="unfitToPlead">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscVerdict') } &gt; { pretty('unfitToPlead') }</option>
                <option value="miscVerdictNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscVerdict') } &gt; { pretty('miscVerdictNoDetail') }</option>

                <option value="notGuilty">{ pretty('notGuilty') } &gt; Any subcategory</option>
                <option value="accidentalDeath">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('notGuilty') } &gt; { pretty('accidentalDeath') }</option>
                <option value="directed">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('notGuilty') } &gt; { pretty('directed') }</option>
                <option value="fault">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('notGuilty') } &gt; { pretty('fault') }</option>
                <option value="noEvidence">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('notGuilty') } &gt; { pretty('noEvidence') }</option>
                <option value="nonComposMentis">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('notGuilty') } &gt; { pretty('nonComposMentis') }</option>
                <option value="noProsecutor">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('notGuilty') } &gt; { pretty('noProsecutor') }</option>
                <option value="selfDefence">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('notGuilty') } &gt; { pretty('selfDefence') }</option>
                <option value="notGuiltyNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('notGuilty') } &gt; { pretty('notGuiltyNoDetail') }</option>

                <option value="specialVerdict">{ pretty('specialVerdict') }</option>

            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-verdict" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
