
const SearchButton = () => {

     const handleCollapse = () => {

        //console.log('handleCollapse');

        const oc = document.querySelector("button#offcanvas-close");

        oc.click();

    };
   
    return (

        <>

            <button type="submit" className="btn btn-outline-input-group btn-light bg-primary text-white search-feature-inline-submit" onClick={ handleCollapse }>
                <span className="ms-2 me-2">Refine</span>
                <span className="material-symbols-sharp">search</span>
                {/*
                <span data-bs-dismiss="offcanvas">
                    <span className="ms-2 me-2">Refine</span>
                    <span className="material-symbols-sharp">search</span>
                </span>
                */}
            </button>

        </>

    );

};

export default SearchButton;
