
const FieldCrime = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;
    const first = props.first;

    return (

        <>

            <select className={ 'form-select' + (first ? ' search-feature-first-input' : '' ) } name="offence" id="field_offence" value={ values['offence'] || '' } onChange={ handleChange }>

                <option value="">&lt; Any Crime &gt;</option>

                <option value="breakingPeace">{ pretty('breakingPeace') } &gt; Any subcategory</option>
                <option value="assault">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('breakingPeace') } &gt; { pretty('assault') }</option>
                <option value="barratry">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('breakingPeace') } &gt; { pretty('barratry') }</option>
                <option value="libel">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('breakingPeace') } &gt; { pretty('libel') }</option>
                <option value="riot">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('breakingPeace') } &gt; { pretty('riot') }</option>
                <option value="threateningBehaviour">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('breakingPeace') } &gt; { pretty('threateningBehaviour') }</option>
                <option value="vagabond">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('breakingPeace') } &gt; { pretty('vagabond') }</option>
                <option value="wounding">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('breakingPeace') } &gt; { pretty('wounding') }</option>
                <option value="breakingPeaceNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('breakingPeace') } &gt; { pretty('breakingPeaceNoDetail') }</option>
                <option value="breakingPeaceOther">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('breakingPeace') } &gt; { pretty('breakingPeaceOther') }</option>

                <option value="damage">{ pretty('damage') } &gt; Any subcategory</option>
                <option value="arson">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('damage') } &gt; { pretty('arson') }</option>
                <option value="damageNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('damage') } &gt; { pretty('damageNoDetail') }</option>
                <option value="damageOther">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('damage') } &gt; { pretty('damageOther') }</option>

                <option value="deception">{ pretty('deception') } &gt; Any subcategory</option>
                <option value="bankrupcy">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('deception') } &gt; { pretty('bankrupcy') }</option>
                <option value="forgery">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('deception') } &gt; { pretty('forgery') }</option>
                <option value="fraud">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('deception') } &gt; { pretty('fraud') }</option>
                <option value="perjury">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('deception') } &gt; { pretty('perjury') }</option>
                <option value="deceptionNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('deception') } &gt; { pretty('deceptionNoDetail') }</option>
                <option value="deceptionOther">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('deception') } &gt; { pretty('deceptionOther') }</option>

                <option value="kill">{ pretty('kill') } &gt; Any subcategory</option>
                <option value="infanticide">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('kill') } &gt; { pretty('infanticide') }</option>
                <option value="manslaughter">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('kill') } &gt; { pretty('manslaughter') }</option>
                <option value="murder">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('kill') } &gt; { pretty('murder') }</option>
                <option value="pettyTreason">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('kill') } &gt; { pretty('pettyTreason') }</option>
                <option value="killNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('kill') } &gt; { pretty('killNoDetail') }</option>
                <option value="killOther">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('kill') } &gt; { pretty('killOther') }</option>

                <option value="miscellaneous">{ pretty('miscellaneous') } &gt; Any subcategory</option>
                <option value="concealingABirth">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscellaneous') } &gt; { pretty('concealingABirth') }</option>
                <option value="conspiracy">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscellaneous') } &gt; { pretty('conspiracy') }</option>
                <option value="habitualCriminal">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscellaneous') } &gt; { pretty('habitualCriminal') }</option>
                <option value="illegalAbortion">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscellaneous') } &gt; { pretty('illegalAbortion') }</option>
                <option value="kidnapping">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscellaneous') } &gt; { pretty('kidnapping') }</option>
                <option value="pervertingJustice">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscellaneous') } &gt; { pretty('pervertingJustice') }</option>
                <option value="piracy">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscellaneous') } &gt; { pretty('piracy') }</option>
                <option value="returnFromTransportation">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscellaneous') } &gt; { pretty('returnFromTransportation') }</option>
                <option value="miscellaneousNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscellaneous') } &gt; { pretty('miscellaneousNoDetail') }</option>
                <option value="miscellaneousOther">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscellaneous') } &gt; { pretty('miscellaneousOther') }</option>

                <option value="royalOffences">{ pretty('royalOffences') } &gt; Any subcategory</option>
                <option value="coiningOffences">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('royalOffences') } &gt; { pretty('coiningOffences') }</option>
                <option value="religiousOffences">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('royalOffences') } &gt; { pretty('religiousOffences') }</option>
                <option value="seditiousLibel">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('royalOffences') } &gt; { pretty('seditiousLibel') }</option>
                <option value="seditiousWords">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('royalOffences') } &gt; { pretty('seditiousWords') }</option>
                <option value="seducingAllegiance">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('royalOffences') } &gt; { pretty('seducingAllegiance') }</option>
                <option value="taxOffences">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('royalOffences') } &gt; { pretty('taxOffences') }</option>
                <option value="treason">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('royalOffences') } &gt; { pretty('treason') }</option>
                <option value="royalOffencesNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('royalOffences') } &gt; { pretty('royalOffencesNoDetail') }</option>
                <option value="royalOffencesOther">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('royalOffences') } &gt; { pretty('royalOffencesOther') }</option>

                <option value="sexual">{ pretty('sexual') } &gt; Any subcategory</option>
                <option value="assaultWithIntent">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('sexual') } &gt; { pretty('assaultWithIntent') }</option>
                <option value="assaultWithSodomiticalIntent">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('sexual') } &gt; { pretty('assaultWithSodomiticalIntent') }</option>
                <option value="bigamy">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('sexual') } &gt; { pretty('bigamy') }</option>
                <option value="indecentAssault">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('sexual') } &gt; { pretty('indecentAssault') }</option>
                <option value="keepingABrothel">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('sexual') } &gt; { pretty('keepingABrothel') }</option>
                <option value="rape">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('sexual') } &gt; { pretty('rape') }</option>
                <option value="sodomy">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('sexual') } &gt; { pretty('sodomy') }</option>
                <option value="sexualNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('sexual') } &gt; { pretty('sexualNoDetail') }</option>
                <option value="sexualOther">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('sexual') } &gt; { pretty('sexualOther') }</option>

                <option value="theft">{ pretty('theft') } &gt; Any subcategory</option>
                <option value="animalTheft">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('animalTheft') }</option>
                <option value="burglary">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('burglary') }</option>
                <option value="embezzlement">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('embezzlement') }</option>
                <option value="extortion">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('extortion') }</option>
                <option value="gameLawOffence">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('gameLawOffence') }</option>
                <option value="grandLarceny">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('grandLarceny') }</option>
                <option value="housebreaking">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('housebreaking') }</option>
                <option value="mail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('mail') }</option>
                <option value="pettyLarceny">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('pettyLarceny') }</option>
                <option value="pocketpicking">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('pocketpicking') }</option>
                <option value="receiving">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('receiving') }</option>
                <option value="shoplifting">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('shoplifting') }</option>
                <option value="simpleLarceny">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('simpleLarceny') }</option>
                <option value="stealingFromMaster">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('stealingFromMaster') }</option>
                <option value="theftFromPlace">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('theftFromPlace') }</option>
                <option value="theftNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('theftNoDetail') }</option>
                <option value="theftOther">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('theft') } &gt; { pretty('theftOther') }</option>

                <option value="violentTheft">{ pretty('violentTheft') } &gt; Any subcategory</option>
                <option value="highwayRobbery">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('violentTheft') } &gt; { pretty('highwayRobbery') }</option>
                <option value="robbery">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('violentTheft') } &gt; { pretty('robbery') }</option>
                <option value="violentTheftNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('violentTheft') } &gt; { pretty('violentTheftNoDetail') }</option>
                <option value="violentTheftOther">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('violentTheft') } &gt; { pretty('violentTheftOther') }</option>

            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-offence" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default FieldCrime;
