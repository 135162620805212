
const lookups = {

    "div_type": "the type of text",

    "kill": "killing",

    "keepingABrothel": "keeping a brothel",
    "concealingABirth": "concealing a birth",

    "transport": "transportation",

    "imprison": "imprisonment",

    "miscPunish": "miscellaneous punishment",
    "miscVerdict": "miscellaneous verdict",

    "noPunish": "no punishment",

    "gameLawOffence": "game law offences",

    "imprisonNoDetail": "imprisonment (no detail)",
    "transportNoDetail": "transportation",
    "deathNoDetail": "death (no detail)",
    "miscellaneousNoDetail": "miscellaneous (no detail)",
    "damageNoDetail": "damage (no detail)",
    "sexualNoDetail": "sexual (no detail)",
    "breakingPeaceNoDetail": "breaking peace (no detail)",
    "deceptionNoDetail": "deception (no detail)",
    "theftNoDetail": "theft (no detail)",
    "killNoDetail": "killing (no detail)",
    "royalOffencesNoDetail": "royal offences (no detail)",

    "guiltyNoDetail": "guilty (no detail)",
    "notGuiltyNoDetail": "not guilty (no detail)",
    "specialVerdictNoDetail": "special verdict",
    "miscVerdictNoDetail": "miscellaneous (no detail)",

    "miscPunishNoDetail": "miscellaneous (no detail)",
    "noPunishNoDetail": "no punishment (no detail)",
    "corporalNoDetail": "corporal (no detail)",

    "theftOther": "theft (other)",
    "miscellaneousOther": "miscellaneous (other)",
    "killOther": "killing (other)",
    "damageOther": "damage (other)",
    "sexualOther": "sexual (other)",
    "deceptionOther": "deception (other)",
    "royalOffencesOther": "royal offences (other)",
    "breakingPeaceOther": "breaking peace (other)",
    "violentTheftOther": "violent theft (other)",

    "theftunder1s": "theft under 1s",
    "theftunder5s": "theft under 5s",
    "theftunder10s": "theft under 10s",
    "theftunder40s": "theft under 40s",
    "theftunder100s": "theft under 100s",

    "victim_hisco_class": "victim occupational group",
    "victim_hisco_label": "victim occupational category",

    "victim_hisco_class_1": "victim occupational group",

    "lookup_victim_hisco_class_1|0.5" : "0.5 : Aristocrats",
    "lookup_victim_hisco_class_1|1" : "1  : Gentry and higher managers",
    "lookup_victim_hisco_class_1|2" : "2  : Higher professionals",
    "lookup_victim_hisco_class_1|3" : "3  : Lower managers",
    "lookup_victim_hisco_class_1|4" : "4  : Medium skilled professionals, clerical and sales personnel",
    "lookup_victim_hisco_class_1|5" : "5  : Lower skilled clerical and sales personnel",
    "lookup_victim_hisco_class_1|6" : "6  : Foremen",
    "lookup_victim_hisco_class_1|6.5" : "6.5 : Small scale manufacturers",
    "lookup_victim_hisco_class_1|7" : "7  : Medium-skilled workers",
    "lookup_victim_hisco_class_1|8" : "8  : Farmers and fishermen",
    "lookup_victim_hisco_class_1|9" : "9  : Lower-skilled workers",
    "lookup_victim_hisco_class_1|10" : "10 : Lower-skilled farm workers",
    "lookup_victim_hisco_class_1|11" : "11 : Unskilled workers",
    "lookup_victim_hisco_class_1|12" : "12 : Unskilled farm workers",

    "defendant_age_max_lte" : "maximum defendant age",
    "defendant_age_min_gte" : "minimum defendant age",

    "victim_age_max_lte" : "maximum victim age",
    "victim_age_min_gte" : "minimum victim age",

    "age_max_lte" : "maximum age",
    "age_min_gte" : "minimum age",

    "length_lte" : "maximum trial length (in words)",
    "length_gte" : "minimum trial length (in words)",

    "lookup_viz|table" : "Table(s)",
    "lookup_viz|pie" : "Pie chart(s)",
    "lookup_viz|bar" : "Bar chart(s)",
    "lookup_viz|line" : "Line chart(s)",
    "lookup_viz|table-cross" : "Cross-tabulation",
    "lookup_viz|bar-stacked" : "Stacked bar chart",
    "lookup_viz|bar-stacked-normalised" : "Stacked bar chart (normalised)",
    "lookup_viz|bar-grouped" : "Grouped bar chart",
    "lookup_viz|line-multi" : "Multi-line chart",

    "lookup_victim_institution|yes" : "Institution",
    "lookup_victim_institution|no" : "Individual",

    "person_type" : "person role",
    "militaryNavalDuty" : "Military or naval duty",

    "drawnAndQuartered" : "Drawn and quarter",

}

export default lookups;
