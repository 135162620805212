
import { useState, useEffect } from "react";

import axios from "axios";

import { useLocation } from "react-router-dom";

const useResultsNew = (apiPath, immediate_query, preprocess) => {

    const location = useLocation();

    const [results, setResults] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState(false);

    useEffect(() => {

        if (location.hash === '#results' || immediate_query) {

            setIsLoading(true);

            const fetchData = async () => {

                try {

                    const url = ( preprocess ? preprocess() : `${ apiPath }${ location.search }`);

                    const resp = await axios.get(url, {
                        withCredentials: false,
                    });

                    const data = await resp?.data;

                    setResults(data);
                    setError(false);
                    setIsLoading(false);

                } catch (error) {

                    console.log(error);
                    setError(error);
                    setIsLoading(false);

                }
            };

            fetchData();

        }

    }, [ apiPath, location.search, immediate_query, location.hash, preprocess ]);

    return [ results, isLoading, error ];

}

export default useResultsNew;
