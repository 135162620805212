
//import { useEffect, useRef } from "react";

//import { useLocation } from "react-router-dom";

const useAnchor = () => {
/*
    // This hook will scroll the current URL anchor (eg https://www.mysite.com/mypage#myanchor ) into view when the page is first loaded
    // and also whenever the URL anchor changes.
    // This code is adapted from:
    // 'Scrolling to an Anchor in React When Your Elements Are Rendered Asynchronously' By Eric Cobb
    // https://ericdcobb.medium.com/scrolling-to-an-anchor-in-react-when-your-elements-are-rendered-asynchronously-8c64f77b5f34

    const scrolledRef = useRef(false);
    const { hash } = useLocation();
    const hashRef = useRef(hash);

    useEffect(() => {

        if (hash) {

            // We want to reset if the hash has changed
            if (hashRef.current !== hash) {

                hashRef.current = hash;
                scrolledRef.current = false;

            }

            // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
            if (!scrolledRef.current) {

                const id = hash.replace('#', '');
                const element = document.getElementById(id);

                if (element) {
                    
                    // Add a 300ms delay so that we scroll to the correct place in very long pages
                    setTimeout(() => { element.scrollIntoView({ behavior: 'smooth' }); scrolledRef.current = true; }, 300);

                    // Version without delay
                    //element.scrollIntoView({ behavior: 'smooth' });
                    //scrolledRef.current = true;

                }
            }
        }
    });
    */
}

export default useAnchor;
