/*
import { useLayoutEffect } from "react";

import { useLocation } from "react-router-dom";

const useScrollToTop = () => {

    const location = useLocation();

    useLayoutEffect(() => {

        window.scroll(0, 0);

    }, [location]);

}

export default useScrollToTop;
*/

import { useLayoutEffect, useState } from "react";

import { useLocation } from "react-router-dom";

function useScrollToTop() {

    const { pathname } = useLocation();
    const hash = window.location.hash;

    const [done, setDone] = useState(false);

    const DEBUG = false;

    useLayoutEffect(() => {

        if (DEBUG) { console.log('running useScrollToTop useLayoutEffect'); }

        const checkForAnchor = () => {

            if (DEBUG) { console.log('Running checkForAnchor'); }

            if (!done) {

                if (DEBUG) { console.log('Was not done'); }

                const sectionId = hash.substring(1);
                const targetElement = document.getElementById(sectionId);

                if (targetElement) {

                    if (DEBUG) { console.log('found target element'); }
                    //targetElement.scrollIntoView({ behavior: "smooth" });
                    // Incredibly, another setTimeout seems to be needed, even when the element has been found
                    //setTimeout(() => { targetElement.scrollIntoView({ behavior: 'smooth' }); }, 300);
                    // For full session texts it seems an even longer timeout is required.
                    setTimeout(() => { targetElement.scrollIntoView({ behavior: 'smooth' }); }, 600);
                    setDone(true);
                    
                } else {

                    if (DEBUG) { console.log('Did not find target element'); } // This actually can fire! Navigate here and click 'Miscellaneous other': http://localhost:3000/data/record/t16750707-9
                    setTimeout(checkForAnchor, 300);

                }

            } else {

                if (DEBUG) { console.log('was done.'); }

            }

        }

        if (hash) {

            if (DEBUG) { console.log('hash present.'); }
            setTimeout(checkForAnchor, 300);

        } else {

            if (DEBUG) { console.log('hash not present. Scrolling to top.'); }
            //window.scroll(0, 0);
            setTimeout(() => { window.scroll(0, 0); }, 0); // Required for Firefox
        }

  }, [pathname, hash, done, DEBUG]);

  return null;

}

export default useScrollToTop;
