
import { useHistory } from "react-router-dom";

const useDateDecade = () => {

    //const location = useLocation();
    let history = useHistory();

    const handleChangeDateDecade = (event) => {

        event.preventDefault();
        //console.log(event.currentTarget.value);
        history.push({ pathname: '/search/date/' + event.currentTarget.value });

    };

    return [ handleChangeDateDecade ];
    
};

export default useDateDecade;
