
const Field = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <select className="form-select" name="rows" id="field_rows" value={ values['rows'] || '' } onChange={ handleChange }>
                <option value="">&lt; Select a second category (optional) ... &gt;</option>
                <option value="offence_category">{ pretty('offence_category') }</option>
                <option value="offence_subcategory">{ pretty('offence_subcategory') }</option>
                <option value="plea">{ pretty('plea') }</option>
                <option value="verdict_category">{ pretty('verdict_category') }</option>
                <option value="verdict_subcategory">{ pretty('verdict_subcategory') }</option>
                <option value="punishment_category">{ pretty('punishment_category') }</option>
                <option value="punishment_subcategory">{ pretty('punishment_subcategory') }</option>
                <option value="defendant_gender">{ pretty('defendant_gender') }</option>
                <option value="defendant_age">{ pretty('defendant_age') }</option>
                <option value="victim_gender">{ pretty('victim_gender') }</option>
                <option value="victim_age">{ pretty('victim_age') }</option>
                <option value="victim_hisco_class_1">{ pretty('victim_hisco_class') }</option>
                <option value="victim_hisco_label">{ pretty('victim_hisco_label') }</option>
                <option value="victim_institution">{ pretty('institutional_victim') }</option>
                <option value="decade">{ pretty('decade') }</option>
                <option value="year">{ pretty('year') }</option>
                {/*<option value="month">{ pretty('month') }</option>
                <option value="lenclass">{ pretty('lenclass') }</option>
                <option value="div_type">{ pretty('text_type') }</option>*/}
            </select>

            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-series" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
