
import queryString from "query-string";

const viewSelector = (searchmode, location) => {

    let qs = queryString.parse(location.search, { arrayFormat: "bracket" });

    let path = `${process.env.REACT_APP_API_URL}/data/oldbailey_record`;   

    if (searchmode === 'ordinarys_accounts') {

        path = `${process.env.REACT_APP_API_URL}/data/oldbailey_oa`;

    } else if (searchmode === 'name') {

        path = `${process.env.REACT_APP_API_URL}/data/oldbailey_person`;

    } else if (searchmode === 'associated_records') {

        path = `${process.env.REACT_APP_API_URL}/data/oldbailey_assocrec`;

    } else if (searchmode === 'statistical') {

        path = `${process.env.REACT_APP_API_URL}/data/oldbailey_trial`;

        if (qs['countby']) {

            // DEFAULT
            // defendant
            // trial
            // offence
            // victim
            // verdict
            // punishment

            if (qs['countby'] === 'defendant') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_defendant`; }
            if (qs['countby'] === 'trial') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_trial`; }
            if (qs['countby'] === 'offence') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_offence`; }
            if (qs['countby'] === 'victim') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
            if (qs['countby'] === 'verdict') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_verdict`; }
            if (qs['countby'] === 'punishment') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_punishment`; }

        } else {

            // Inspect parameters to decide what view to use.
            
            if (qs['series']) {

                if (qs['series'] === 'offence_category') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_offence`; }
                if (qs['series'] === 'offence_subcategory') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_offence`; }
                if (qs['series'] === 'plea') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_verdict`; }
                if (qs['series'] === 'verdict_category') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_verdict`; }
                if (qs['series'] === 'verdict_subcategory') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_verdict`; }
                if (qs['series'] === 'punishment_category') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_punishment`; }
                if (qs['series'] === 'punishment_subcategory') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_punishment`; }
                if (qs['series'] === 'defendant_gender') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_defendant`; }
                if (qs['series'] === 'defendant_age') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_defendant`; }
                if (qs['series'] === 'victim_gender') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
                if (qs['series'] === 'victim_age') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
                if (qs['series'] === 'victim_hisco_class_1') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
                if (qs['series'] === 'victim_hisco_label') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
                if (qs['series'] === 'victim_institution') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
                if (qs['series'] === 'decade') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_trial`; }
                if (qs['series'] === 'year') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_trial`; }
                    
            }

        }

    }

    return path;

}

export default viewSelector;
