
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const useSwitchDynaggs = () => {

    const location = useLocation();
    let history = useHistory();

    const switchDynaggs = (event) => {

        event.preventDefault();

        let qs = queryString.parse(location.search, { arrayFormat: "bracket" });

        const series = qs['series'];
        const rows = qs['rows'];
        const columns = qs['columns'];

        if (series && rows && !columns) {

            qs['series'] = rows;
            qs['rows'] = series;
            const stringified = queryString.stringify(qs, { arrayFormat: "bracket" });
            history.push({ pathname: location.pathname, search: stringified, hash: location.hash });

        } else if (series && rows && columns) {

            qs['series'] = columns;
            qs['rows'] = series;
            qs['columns'] = rows;
            const stringified = queryString.stringify(qs, { arrayFormat: "bracket" });
            history.push({ pathname: location.pathname, search: stringified, hash: location.hash });

        }

    }

    return [ switchDynaggs ];
    
};

export default useSwitchDynaggs;
