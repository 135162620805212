
const Field = (props) => {
    
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <select className="form-select" name="victim_hisco_class_1" id="field_victim_hisco_class_1" value={ values['victim_hisco_class_1'] || '' } onChange={ handleChange }>
                <option value="">&lt; Any Victim occupational group &gt;</option>
                <option value="0.5">0.5 : Aristocrats</option>
                <option value="1">1  : Gentry and higher managers</option>
                <option value="2">2  : Higher professionals</option>
                <option value="3">3  : Lower managers</option>
                <option value="4">4  : Medium skilled professionals, clerical and sales personnel</option>
                <option value="5">5  : Lower skilled clerical and sales personnel</option>
                <option value="6">6  : Foremen</option>
                <option value="6.5">6.5 : Small scale manufacturers</option>
                <option value="7">7  : Medium-skilled workers</option>
                <option value="8">8  : Farmers and fishermen</option>
                <option value="9">9  : Lower-skilled workers</option>
                <option value="10">10 : Lower-skilled farm workers</option>
                <option value="11">11 : Unskilled workers</option>
                <option value="12">12 : Unskilled farm workers</option>
            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-victim_hisco_class_1" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
