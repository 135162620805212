
import { useState } from "react";

import Post from "../Post";

//import options from '../../data/dhids/options';
import options from '../../data/oldbailey/options';

import oblogo from "../../data/oldbailey/OldBailey-Logo-White.png";
import videothumb from "../../data/oldbailey/videothumb.jpg";

//import image_ce from "../../data/oldbailey/courtroom_1809.jpg";
//import image_dc from "../../data/oldbailey/drunkards_children.jpg";
//import image_ts from "../../data/oldbailey/trial_scene.jpg";

//import hero1 from "../../data/oldbailey/hero1.jpg";
//import hero2 from "../../data/oldbailey/hero2.jpg";
//import hero3 from "../../data/oldbailey/hero3.jpg";
//import hero4 from "../../data/oldbailey/hero4.jpg";

//import billingsgate from "../../data/oldbailey/billingsgate.jpg";
import asc1 from "../../data/oldbailey/asc1.jpg";
import asc2 from "../../data/oldbailey/asc2.jpg";
import asc3 from "../../data/oldbailey/asc3.jpg";
import asc4 from "../../data/oldbailey/asc4.jpg";
import asc5 from "../../data/oldbailey/asc5.jpg";

import usePretty from "../../hooks/usePretty";
import oldBaileyLookups from '../../data/oldbailey/lookups';

import useSearchmode from "../../hooks/oldbailey/useSearchmode";

import SearchSelectButton from "./fields/SearchSelectButton";

import OnThisDay from "./OnThisDay";

import useScrollToTop from "../../hooks/useScrollToTop";

import { Link, useHistory, useLocation, /*useHref*/ } from "react-router-dom";

import useForm from "../../hooks/useForm";
import queryString from "query-string";

const Home = () => {

    useScrollToTop();
    
    document.title = "The Proceedings of the Old Bailey";

    const [ values, handleChange, , ] =
    useForm(false, false);
    const location = useLocation();
    let history = useHistory();

    /*
    const updateURL = (vals) => {

        let qs = queryString.parse(location.search, { arrayFormat: "bracket" });
        let newvals = {...qs, ...vals};

        for(var k in newvals) { if(!newvals[k]) delete newvals[k]; } // No filter() for objects in js yet

        const stringified = queryString.stringify(newvals, { arrayFormat: "bracket" });
        history.push({ pathname: location.pathname, search: stringified }); // Push the completed form into history so the back button retains the form contents
        history.push({ pathname: location.pathname, search: stringified, hash: 'results' });

        const results = document.getElementById('results'); // Unfortunately we need belt and braces here
                                                            // Although we set the hash above, this will not move the viewport
        if (results) {                                      // if the hash was already '#results'.
            results.scrollIntoView({ behavior: 'smooth' }); // So we do it again here.
        }                                                   

    };
    */

    //const keyword_href = useHref('/search/keyword');

    const handleSubmit = (event) => {

        event.preventDefault();

        const stringified = queryString.stringify(values, { arrayFormat: "bracket" });
        history.push({ pathname: location.pathname, search: stringified }); // Push the completed form into history so the back button retains the form contents
        history.push({ pathname: '/search/keyword', search: stringified, hash: 'results' });

        //let vals = values;
        //vals['from'] = 0;

        //updateURL(vals);
        
    };

    const [ pretty ] =
    usePretty(oldBaileyLookups);

    const searchmode = 'keyword';

    const [ handleSearchmodeClick ] =
    useSearchmode();

    const [helpslug, setHelpslug] = useState();

    const handleChangeHelpslug = (event) => {

        var button = event.currentTarget;
        var helpslug = button.getAttribute('data-bs-helpslug');
        if (helpslug) {
            setHelpslug(helpslug);
        }
    } 

    return (

        <>

        <div className="modal fade" id="helpModal" tabIndex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="btn float-end" data-bs-dismiss="modal" aria-label="Close">
                    <i className="bi bi-x-lg"></i>
                </button>

                { helpslug && (

                    <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=` + helpslug } options={ options } />

                ) }

                <button type="button" className="btn btn-secondary float-end" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid gx-0">

            <div id="carousel-banner" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-pause="false" data-bs-touch="false" data-bs-interval="8000">
                <div className="carousel-inner ob-homepage-carousel-inner">
                    <div className="carousel-item ob-carousel-item ob-carousel-item-1 active"></div>
                    <div className="carousel-item ob-carousel-item ob-carousel-item-4"></div>
                    <div className="carousel-item ob-carousel-item ob-carousel-item-3"></div>
                    <div className="carousel-item ob-carousel-item ob-carousel-item-2"></div>
                </div>
                <div className="ob-homepage-carousel-logo">
                    <img src={ oblogo } className="img-fluid" alt="The Proceedings of the Old Bailey logo" />
                </div>
                <div className="ob-homepage-carousel-search">

                    <div className="container">

                        <form onSubmit={ handleSubmit }>

                            
                            <div className="d-block d-lg-none mb-2">

                                <SearchSelectButton searchmode={ searchmode } pretty={ pretty } handleSearchmodeClick={ handleSearchmodeClick } />

                            </div>

                            <div className="input-group">

                                <div className="d-none d-lg-inline me-2">

                                    <SearchSelectButton searchmode={ searchmode } pretty={ pretty } handleSearchmodeClick={ handleSearchmodeClick } />

                                </div>

                                <input type="search" className="form-control search-feature-first-input" name="text" id="field_text" placeholder={ 'Enter keywords or a reference number ...' } value={ values['text'] || '' } onChange={ handleChange } />

                                <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-text" onClick={ handleChangeHelpslug }>
                                    <span className="material-symbols-sharp">help_outline</span>
                                </button>

                                <button type="submit" className="btn btn-outline-input-group btn-light bg-primary text-white search-feature-inline-submit">
                                    <span className="material-symbols-sharp">search</span>
                                </button>

                            </div>

                        </form> 

                    </div>

                </div>
                {/*
                <div id="arabin-quote" className="d-none d-xl-block text-white ob-body-text text-center">
                    <i>"I'll be bound there is not such a Court in the universe as this – not in the kingdom, and the whole British empire."</i>
                    <br/>
                    William St. Julien Arabin, Sergeant at Law, Arabiniana (1843)
                </div>
                */}
            </div>

            {/*<div className="container mt-5 mb-5 calc-vh">*/}

            <div className="ob-homepage-standfirst">

                <div className="container">

                    <div className="row">

                        <div className="col-12 d-flex justify-content-center">

                            <div className="text-center p-4 ob-homepage-standfirst-text">

                                <h1>The Proceedings of the Old Bailey, 1674-1913</h1>

                                <p className="ob-body-text">
                                    A fully searchable edition of the largest body of texts detailing the lives of non-elite people ever published, containing 197,752 trials held at London&#39;s central criminal court, and 475 <i>Ordinary’s Accounts</i> of the lives of executed convicts. If you are new to this site, please see <Link className="text-white" to={ '/about/gettingstarted' }>Getting Started</Link> and the <Link className="text-white" to={ 'about/searchhelp' }>Guide to Searching</Link>.
                                </p>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div className="bg-white">

                <div className="container">

                    <div className="row pt-4 pb-4">

                        <div className="col-12 col-lg-6 p-4">

                            <h1>Welcome to the New Old Bailey Online</h1>

                            <p className="ob-body-text">
                                Version 9.0 of this website, published on the occasion of its 20<sup>th</sup> anniversary, features improved, mobile-friendly design, enhanced search functions, and new and revised historical background pages. For more information, see <Link to={ '/about/whats-new' }>What’s New</Link>. We are grateful to University of Sheffield Alumni for funding this major update. The previous version, 8.0, will remain available as a fully functional website until 31 August 2024 at <a href="https://www.dhi.ac.uk/oldbaileyonline">https://www.dhi.ac.uk/oldbaileyonline</a>.
                            </p>

                            <figure className="arabin-quote">
                                <blockquote className="blockquote">
                                    <p>&ldquo;I'll be bound there is not such a Court in the universe as this – not in the kingdom, and the whole British empire.&rdquo;</p>
                                </blockquote>
                                <figcaption className="blockquote-footer">
                                    William St. Julien Arabin, Sergeant at Law, Arabiniana (1843)
                                </figcaption>
                            </figure>

                        </div>

                        <div className="col-12 col-lg-6 p-4">

                            <div className="ratio ratio-16x9">
                                <video poster={ videothumb } controls>
                                    <source src="https://www.dhi.ac.uk/san/old-bailey/homepagevideo.mp4" type="video/mp4"/>
                                    Sorry, your browser does not support the HTML5 &lt;Video&gt; tag.
                                </video>
                            </div>
                            <div className="video-caption">Tim Hitchcock provides an introduction to the Proceedings</div>

                        </div>

                    </div>

                </div>

            </div>

            <div className="bg-obred text-white">




                <div className="container">

                    <div className="row pt-4 pb-4">

                        <div className="col-12 col-lg-6 p-4">

                            <div id="ats-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-pause="false" data-bs-touch="false" data-bs-interval="5000">

                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={ asc1 } className="d-block w-100" alt=""/>
                                    </div>
                                    <div className="carousel-item">
                                        <img src={ asc2 } className="d-block w-100" alt=""/>
                                    </div>
                                    <div className="carousel-item">
                                        <img src={ asc3 } className="d-block w-100" alt=""/>
                                    </div>
                                    <div className="carousel-item">
                                        <img src={ asc4 } className="d-block w-100" alt=""/>
                                    </div>
                                    <div className="carousel-item">
                                        <img src={ asc5 } className="d-block w-100" alt=""/>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="col-12 col-lg-6 p-4">

                            <h1>Approaches to Search</h1>

                            <p className="ob-body-text">
                                This site allows you to undertake a wide range of keyword and category searches, to combine both types of search, and to refine your results as you go.  There are separate search pages for keyword; name; crime, verdict and punishment; browse by date; and statistical searches; and for the Ordinary of Newgate’s Accounts and the Associated Records database. There is also an Advanced Search page, which allows you to combine many of these searches.  See the <Link className="text-white" to={ 'about/searchhelp' }>Guide to Searching</Link>.
                                {/* At 127 million words, the <strong>Proceedings of the Old Bailey</strong> and <strong>Ordinary of Newgate’s Accounts </strong>cannot be read as a single text. To locate material relevant to your research you will need to use the search facilities provided. All types of searches can be pursued from the main <Link className="text-white" to={ '/search' }>Search</Link> page. The default setting for this page is <strong>Keyword</strong>, but the pull-down menu to the left of the search box also allows searching by <strong>Name; </strong>&nbsp;<strong>Crime, Verdict and Punishment;</strong>&nbsp;as well as <strong>Statistical</strong> queries; <strong>Browse by Date</strong>; and searches limited to either the <strong>Ordinary’s Accounts</strong> or <strong>Associated Records</strong>. Finally, an <strong>Advanced Search</strong> form allows searches on both keywords and all varieties of tagged information in the <strong>Proceedings</strong> and <strong>Ordinary of Newgate’s Accounts</strong>. */}
                            </p>

                            {/*
                            <p>
                                <Link className="text-white" to={ 'about/searchhelp' }>Guide to Searching</Link>
                            </p>
                            */}

                        </div>

                    </div>

                </div>

            </div>

            <div className="bg-white">

                 <div className="container">

                    <OnThisDay/>

                </div>            

            </div>


            </div>

        </>

    );

};

export default Home;

