
const Field = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>
            <select className="form-select" name="victim_age_min_gte" id="field_victim_age_min_gte" value={ values['victim_age_min_gte'] || '' } onChange={ handleChange }>
                <option value="">&lt; Any vic. age &gt;</option>
                <option value="1">{ pretty('1') }</option>
                <option value="2">{ pretty('2') }</option>
                <option value="3">{ pretty('3') }</option>
                <option value="4">{ pretty('4') }</option>
                <option value="5">{ pretty('5') }</option>
                <option value="6">{ pretty('6') }</option>
                <option value="7">{ pretty('7') }</option>
                <option value="8">{ pretty('8') }</option>
                <option value="9">{ pretty('9') }</option>
                <option value="10">{ pretty('10') }</option>
                <option value="11">{ pretty('11') }</option>
                <option value="12">{ pretty('12') }</option>
                <option value="13">{ pretty('13') }</option>
                <option value="14">{ pretty('14') }</option>
                <option value="15">{ pretty('15') }</option>
                <option value="16">{ pretty('16') }</option>
                <option value="17">{ pretty('17') }</option>
                <option value="18">{ pretty('18') }</option>
                <option value="19">{ pretty('19') }</option>
                <option value="20">{ pretty('20') }</option>
                <option value="21">{ pretty('21') }</option>
                <option value="22">{ pretty('22') }</option>
                <option value="23">{ pretty('23') }</option>
                <option value="24">{ pretty('24') }</option>
                <option value="25">{ pretty('25') }</option>
                <option value="26">{ pretty('26') }</option>
                <option value="27">{ pretty('27') }</option>
                <option value="28">{ pretty('28') }</option>
                <option value="29">{ pretty('29') }</option>
                <option value="30">{ pretty('30') }</option>
                <option value="31">{ pretty('31') }</option>
                <option value="32">{ pretty('32') }</option>
                <option value="33">{ pretty('33') }</option>
                <option value="34">{ pretty('34') }</option>
                <option value="35">{ pretty('35') }</option>
                <option value="36">{ pretty('36') }</option>
                <option value="37">{ pretty('37') }</option>
                <option value="38">{ pretty('38') }</option>
                <option value="39">{ pretty('39') }</option>
                <option value="40">{ pretty('40') }</option>
                <option value="41">{ pretty('41') }</option>
                <option value="42">{ pretty('42') }</option>
                <option value="43">{ pretty('43') }</option>
                <option value="44">{ pretty('44') }</option>
                <option value="45">{ pretty('45') }</option>
                <option value="46">{ pretty('46') }</option>
                <option value="47">{ pretty('47') }</option>
                <option value="48">{ pretty('48') }</option>
                <option value="49">{ pretty('49') }</option>
                <option value="50">{ pretty('50') }</option>
                <option value="51">{ pretty('51') }</option>
                <option value="52">{ pretty('52') }</option>
                <option value="53">{ pretty('53') }</option>
                <option value="54">{ pretty('54') }</option>
                <option value="55">{ pretty('55') }</option>
                <option value="56">{ pretty('56') }</option>
                <option value="57">{ pretty('57') }</option>
                <option value="58">{ pretty('58') }</option>
                <option value="59">{ pretty('59') }</option>
                <option value="60">{ pretty('60') }</option>
                <option value="61">{ pretty('61') }</option>
                <option value="62">{ pretty('62') }</option>
                <option value="63">{ pretty('63') }</option>
                <option value="64">{ pretty('64') }</option>
                <option value="65">{ pretty('65') }</option>
                <option value="66">{ pretty('66') }</option>
                <option value="67">{ pretty('67') }</option>
                <option value="68">{ pretty('68') }</option>
                <option value="69">{ pretty('69') }</option>
                <option value="70">{ pretty('70') }</option>
                <option value="71">{ pretty('71') }</option>
                <option value="72">{ pretty('72') }</option>
                <option value="73">{ pretty('73') }</option>
                <option value="74">{ pretty('74') }</option>
                <option value="75">{ pretty('75') }</option>
                <option value="76">{ pretty('76') }</option>
                <option value="77">{ pretty('77') }</option>
                <option value="78">{ pretty('78') }</option>
                <option value="79">{ pretty('79') }</option>
                <option value="80">{ pretty('80') }</option>
                <option value="81">{ pretty('81') }</option>
                <option value="82">{ pretty('82') }</option>
                <option value="83">{ pretty('83') }</option>
                <option value="84">{ pretty('84') }</option>
                <option value="85">{ pretty('85') }</option>
                <option value="86">{ pretty('86') }</option>
                <option value="87">{ pretty('87') }</option>
                <option value="88">{ pretty('88') }</option>
                <option value="89">{ pretty('89') }</option>
                <option value="90">{ pretty('90') }</option>
                <option value="91">{ pretty('91') }</option>
                <option value="92">{ pretty('92') }</option>
                <option value="93">{ pretty('93') }</option>
                <option value="94">{ pretty('94') }</option>
                <option value="95">{ pretty('95') }</option>
                <option value="96">{ pretty('96') }</option>
                <option value="97">{ pretty('97') }</option>
                <option value="98">{ pretty('98') }</option>
                <option value="99">{ pretty('99') }</option>
            </select>
            <span className="input-group-text bg-white">
                <i className="bi bi-dash-lg"></i>
            </span>
            <select className="form-select" name="victim_age_max_lte" id="field_victim_age_max_lte" value={ values['victim_age_max_lte'] || '' } onChange={ handleChange }>
                <option value="">&lt; Any vic. age &gt;</option>
                <option value="1">{ pretty('1') }</option>
                <option value="2">{ pretty('2') }</option>
                <option value="3">{ pretty('3') }</option>
                <option value="4">{ pretty('4') }</option>
                <option value="5">{ pretty('5') }</option>
                <option value="6">{ pretty('6') }</option>
                <option value="7">{ pretty('7') }</option>
                <option value="8">{ pretty('8') }</option>
                <option value="9">{ pretty('9') }</option>
                <option value="10">{ pretty('10') }</option>
                <option value="11">{ pretty('11') }</option>
                <option value="12">{ pretty('12') }</option>
                <option value="13">{ pretty('13') }</option>
                <option value="14">{ pretty('14') }</option>
                <option value="15">{ pretty('15') }</option>
                <option value="16">{ pretty('16') }</option>
                <option value="17">{ pretty('17') }</option>
                <option value="18">{ pretty('18') }</option>
                <option value="19">{ pretty('19') }</option>
                <option value="20">{ pretty('20') }</option>
                <option value="21">{ pretty('21') }</option>
                <option value="22">{ pretty('22') }</option>
                <option value="23">{ pretty('23') }</option>
                <option value="24">{ pretty('24') }</option>
                <option value="25">{ pretty('25') }</option>
                <option value="26">{ pretty('26') }</option>
                <option value="27">{ pretty('27') }</option>
                <option value="28">{ pretty('28') }</option>
                <option value="29">{ pretty('29') }</option>
                <option value="30">{ pretty('30') }</option>
                <option value="31">{ pretty('31') }</option>
                <option value="32">{ pretty('32') }</option>
                <option value="33">{ pretty('33') }</option>
                <option value="34">{ pretty('34') }</option>
                <option value="35">{ pretty('35') }</option>
                <option value="36">{ pretty('36') }</option>
                <option value="37">{ pretty('37') }</option>
                <option value="38">{ pretty('38') }</option>
                <option value="39">{ pretty('39') }</option>
                <option value="40">{ pretty('40') }</option>
                <option value="41">{ pretty('41') }</option>
                <option value="42">{ pretty('42') }</option>
                <option value="43">{ pretty('43') }</option>
                <option value="44">{ pretty('44') }</option>
                <option value="45">{ pretty('45') }</option>
                <option value="46">{ pretty('46') }</option>
                <option value="47">{ pretty('47') }</option>
                <option value="48">{ pretty('48') }</option>
                <option value="49">{ pretty('49') }</option>
                <option value="50">{ pretty('50') }</option>
                <option value="51">{ pretty('51') }</option>
                <option value="52">{ pretty('52') }</option>
                <option value="53">{ pretty('53') }</option>
                <option value="54">{ pretty('54') }</option>
                <option value="55">{ pretty('55') }</option>
                <option value="56">{ pretty('56') }</option>
                <option value="57">{ pretty('57') }</option>
                <option value="58">{ pretty('58') }</option>
                <option value="59">{ pretty('59') }</option>
                <option value="60">{ pretty('60') }</option>
                <option value="61">{ pretty('61') }</option>
                <option value="62">{ pretty('62') }</option>
                <option value="63">{ pretty('63') }</option>
                <option value="64">{ pretty('64') }</option>
                <option value="65">{ pretty('65') }</option>
                <option value="66">{ pretty('66') }</option>
                <option value="67">{ pretty('67') }</option>
                <option value="68">{ pretty('68') }</option>
                <option value="69">{ pretty('69') }</option>
                <option value="70">{ pretty('70') }</option>
                <option value="71">{ pretty('71') }</option>
                <option value="72">{ pretty('72') }</option>
                <option value="73">{ pretty('73') }</option>
                <option value="74">{ pretty('74') }</option>
                <option value="75">{ pretty('75') }</option>
                <option value="76">{ pretty('76') }</option>
                <option value="77">{ pretty('77') }</option>
                <option value="78">{ pretty('78') }</option>
                <option value="79">{ pretty('79') }</option>
                <option value="80">{ pretty('80') }</option>
                <option value="81">{ pretty('81') }</option>
                <option value="82">{ pretty('82') }</option>
                <option value="83">{ pretty('83') }</option>
                <option value="84">{ pretty('84') }</option>
                <option value="85">{ pretty('85') }</option>
                <option value="86">{ pretty('86') }</option>
                <option value="87">{ pretty('87') }</option>
                <option value="88">{ pretty('88') }</option>
                <option value="89">{ pretty('89') }</option>
                <option value="90">{ pretty('90') }</option>
                <option value="91">{ pretty('91') }</option>
                <option value="92">{ pretty('92') }</option>
                <option value="93">{ pretty('93') }</option>
                <option value="94">{ pretty('94') }</option>
                <option value="95">{ pretty('95') }</option>
                <option value="96">{ pretty('96') }</option>
                <option value="97">{ pretty('97') }</option>
                <option value="98">{ pretty('98') }</option>
                <option value="99">{ pretty('99') }</option>
            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-victim_age_max" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>
        </>

    );

};

export default Field;
