
import { useParams, useLocation, Redirect } from "react-router-dom";

const SearchRedirector = () => {
    
    const { page } = useParams();

    const slug = page.toLowerCase().slice(0, -4);

    const location = useLocation();

    return (     

        <>
            {/* <span>{ '/about/' + slug + location.hash }</span> */}
            <Redirect to={ '/about/' + slug + location.hash } />
        </>
    );

};

export default SearchRedirector;
