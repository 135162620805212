
import React from 'react'; // Needed to use the full Fragment syntax

import { ResponsiveContainer } from 'recharts';
import { XAxis, YAxis, ZAxis } from 'recharts';
import { Label } from 'recharts';
import { ScatterChart, Scatter } from 'recharts';

import { useMemo } from "react";

const MacroscopeScatter = (props) => {

    const findSubaggkeys = (bucket) => {

        let subaggkeys = []; // The only way I have found to identify subaggregations is to look for keys in the bucket 
                             // which are not 'key' or 'doc_count' :/

        for (let key of Object.keys(bucket)) {

            if (key !== 'key' && key !== 'doc_count') {

                subaggkeys.push(key);

            }

        }

        return subaggkeys;

    }

    const scatterdata = useMemo(() => {

        const makeRechartsScatterData = (esdata) => {
            
            let rcdata = [];

            esdata.forEach((bucket, idx) => {

                //let rcbucket = [];

                //rcbucket['key'] = pretty(bucket['key'], ['string', 'lookup_' + filterkey]);

                const subaggkeys = findSubaggkeys(bucket);

                bucket[subaggkeys[0]]['buckets'].forEach((subbucket, idx) => {

                    if (subbucket['doc_count'] > 0) {

                        const xrng = Math.random() * 1;
                        const yrng = Math.random() * 1;
                        //const xrng = 0;
                        //const yrng = 0;

                        rcdata.push({ 'year': (parseInt(subbucket['key']) + xrng), 'lenclass': (parseInt(bucket['key']) + yrng) });

                        //rcbucket[pretty(subbucket['key'], ['string', 'lookup_' + subaggkey])] = subbucket['doc_count'];
                        
                    }

                });

                //return rcbucket;
            })
            
            //console.log(rcdata);

            /*
            rcdata = [
                { 'year': 1670, 'lenclass': 200, 'doc_count': 1 },
                { 'year': 1680, 'lenclass': 100, 'doc_count': 20 },
                { 'year': 1690, 'lenclass': 300, 'doc_count': 30 },
                { 'year': 1700, 'lenclass': 250, 'doc_count': 40 },
                { 'year': 1710, 'lenclass': 400, 'doc_count': 50 },
                { 'year': 1720, 'lenclass': 280, 'doc_count': 60 },
            ];
            */

            return rcdata;

        }

        const formatter = (value) => {

            if (value === 4) return '100';
            if (value === 6) return '1k';
            if (value === 9) return '10k';
            if (value === 12) return '100k';
            return value;

        };

        //return makeRechartsScatterData(props.agg.buckets)
        return (

            <ResponsiveContainer width="100%" height="100%">
                <ScatterChart>
                    {/*<CartesianGrid />*/}
                    <XAxis type="number" dataKey="year" name="year" domain={ [1674, 1913] } ticks={ [ 1670, 1680, 1690, 1700, 1710, 1720, 1730, 1740, 1750, 1760, 1770, 1780, 1790, 1800, 1810, 1820, 1830, 1840, 1850, 1860, 1870, 1880, 1890, 1900, 1910 ] } />
                    <YAxis type="number" dataKey="lenclass" name="lenclass" domain={ [0, 12] } ticks={ [ 0, 4, 6, 9, 12 ] } tickFormatter={ formatter }>
                        <Label
                        value="Trial length (words)"
                        angle="270"
                        style={{
                            fontSize: '0.6rem',
                            fill: '#777',
                        }}
                        />
                    </YAxis>
                    <ZAxis type="number" range={ [10, 10] }/>
                    {/*<ZAxis range={[30, 31]} />*/}
                    {/*<Tooltip cursor={{ strokeDasharray: '3 3' }} />*/}
                    <Scatter data={ makeRechartsScatterData(props.agg.buckets) } fill="#852121" isAnimationActive={ false } />
                    {/*
                    <XAxis dataKey="key" />
                    <YAxis />
                    <Tooltip />
                    <Scatter name="A school" data={ makeRechartsScatterData(props.agg.buckets, props.filterkey, subaggkey) } fill="#8884d8" />
                    */}
                    {/*<Legend />*/}
                    {/* subaggbuckets.map((entry, idx) => 
                        <Line key={ idx } type="monotone" dataKey={ pretty(entry.key, ['string', 'lookup_' + subaggkey]) } stroke={ getColor(entry.key, idx) } dot={ false } />
                    )*/}
                </ScatterChart>
            </ResponsiveContainer>

        )

    }, [props.agg.buckets]);

    //console.log('MacroscopeScatter redrew.');

    return (

        <>
        { scatterdata }
        </>
    );

};

export default MacroscopeScatter;

