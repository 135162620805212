
const Field = (props) => {
    
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <input type="search" className="form-control" name="victim_label" id="field_victim_label" placeholder={ 'Search victim occupations (by keyword) ...' } value={ values['victim_label'] || '' } onChange={ handleChange } />
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-victim_label" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
