
const Field = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <select className="form-select" name="victim_hisco_label" id="field_victim_hisco_label" value={ values['victim_hisco_label'] || '' } onChange={ handleChange }>
                <option value="">&lt; Any Victim occupational category &gt;</option>
                <option value="Animal or Animal-Drawn Vehicle Driver, Specialisation Unknown">{ pretty('Animal or Animal-Drawn Vehicle Driver, Specialisation Unknown') }</option>
                <option value="Animal-Drawn Vehicle Driver (Road)">{ pretty('Animal-Drawn Vehicle Driver (Road)') }</option>
                <option value="Aristocrat">{ pretty('Aristocrat') }</option>
                <option value="Auctioneer">{ pretty('Auctioneer') }</option>
                <option value="Author, Journalist or Related Writer, Specialisation Unknown ">{ pretty('Author, Journalist or Related Writer, Specialisation Unknown ') }</option>
                <option value="Auxiliary Nurse">{ pretty('Auxiliary Nurse') }</option>
                <option value="Baker General">{ pretty('Baker General') }</option>
                <option value="Baker, General">{ pretty('Baker, General') }</option>
                <option value="Barber Hairdresser">{ pretty('Barber Hairdresser') }</option>
                <option value="Bartender">{ pretty('Bartender') }</option>
                <option value="Beam Warper">{ pretty('Beam Warper') }</option>
                <option value="Beef Cattle Farm Worker">{ pretty('Beef Cattle Farm Worker') }</option>
                <option value="Blacksmith General">{ pretty('Blacksmith General') }</option>
                <option value="Blacksmith Toolmaker or Machine-Tool Operator Specialisation Unknown">{ pretty('Blacksmith Toolmaker or Machine-Tool Operator Specialisation Unknown') }</option>
                <option value="Bleacher Dyer or Textile Product Finisher Specialisation Unknown">{ pretty('Bleacher Dyer or Textile Product Finisher Specialisation Unknown') }</option>
                <option value="Bookbinder (Hand or Machine)">{ pretty('Bookbinder (Hand or Machine)') }</option>
                <option value="Bookbinder (Hand)">{ pretty('Bookbinder (Hand)') }</option>
                <option value="Bookkeeper General">{ pretty('Bookkeeper General') }</option>
                <option value="Brewer General">{ pretty('Brewer General') }</option>
                <option value="Brick and Tile Kilnman">{ pretty('Brick and Tile Kilnman') }</option>
                <option value="Brick and Tile Moulder (Hand or Machine)">{ pretty('Brick and Tile Moulder (Hand or Machine)') }</option>
                <option value="Bricklayer (Construction)">{ pretty('Bricklayer (Construction)') }</option>
                <option value="Bricklayer (Construction) ">{ pretty('Bricklayer (Construction) ') }</option>
                <option value="Bricklayer or Stonemason">{ pretty('Bricklayer or Stonemason') }</option>
                <option value="Brush Maker (Hand)">{ pretty('Brush Maker (Hand)') }</option>
                <option value="Budgeting and Accounting Manager">{ pretty('Budgeting and Accounting Manager') }</option>
                <option value="Butcher General">{ pretty('Butcher General') }</option>
                <option value="Cabinetmaker">{ pretty('Cabinetmaker') }</option>
                <option value="Candle Maker">{ pretty('Candle Maker') }</option>
                <option value="Candle Maker ">{ pretty('Candle Maker ') }</option>
                <option value="Carpenter General">{ pretty('Carpenter General') }</option>
                <option value="Carpet Weaver (Hand or Machine)">{ pretty('Carpet Weaver (Hand or Machine)') }</option>
                <option value="Cartwright">{ pretty('Cartwright') }</option>
                <option value="Chambermaid and Room Attendant">{ pretty('Chambermaid and Room Attendant') }</option>
                <option value="Chemist, General">{ pretty('Chemist, General') }</option>
                <option value="Child">{ pretty('Child') }</option>
                <option value="Chocolate Maker">{ pretty('Chocolate Maker') }</option>
                <option value="Clerical or Related Worker Specialisation Unknown">{ pretty('Clerical or Related Worker Specialisation Unknown') }</option>
                <option value="Clerical or Related Worker, Specialisation Unknown">{ pretty('Clerical or Related Worker, Specialisation Unknown') }</option>
                <option value="Coach-Body Builder">{ pretty('Coach-Body Builder') }</option>
                <option value="Colour Developer">{ pretty('Colour Developer') }</option>
                <option value="Commercial Traveller">{ pretty('Commercial Traveller') }</option>
                <option value="Cook Specialisation Unknown">{ pretty('Cook Specialisation Unknown') }</option>
                <option value="Cook, Specialisation Unknown">{ pretty('Cook, Specialisation Unknown') }</option>
                <option value="Cooper">{ pretty('Cooper') }</option>
                <option value="Customs officers">{ pretty('Customs officers') }</option>
                <option value="Cutler">{ pretty('Cutler') }</option>
                <option value="Dairy Farm Worker General">{ pretty('Dairy Farm Worker General') }</option>
                <option value="Dairy Farm Worker, General">{ pretty('Dairy Farm Worker, General') }</option>
                <option value="Dispensing Optician">{ pretty('Dispensing Optician') }</option>
                <option value="Dispensing Optician ">{ pretty('Dispensing Optician ') }</option>
                <option value="Domestic servant general">{ pretty('Domestic servant general') }</option>
                <option value="Domestic servant, general">{ pretty('Domestic servant, general') }</option>
                <option value="Domestic servant, general ">{ pretty('Domestic servant, general ') }</option>
                <option value="Engineers Specialisation Unknown">{ pretty('Engineers Specialisation Unknown') }</option>
                <option value="Engraver and Etcher (Artistic)">{ pretty('Engraver and Etcher (Artistic)') }</option>
                <option value="Factory Worker">{ pretty('Factory Worker') }</option>
                <option value="Finance Clerk">{ pretty('Finance Clerk') }</option>
                <option value="Fisherman Deep-Sea or Inland and Coastal Water">{ pretty('Fisherman Deep-Sea or Inland and Coastal Water') }</option>
                <option value="Fisherman, Deep-Sea or Inland and Coastal Water">{ pretty('Fisherman, Deep-Sea or Inland and Coastal Water') }</option>
                <option value="Fur Tailor">{ pretty('Fur Tailor') }</option>
                <option value="Gardener">{ pretty('Gardener') }</option>
                <option value="General Farmer">{ pretty('General Farmer') }</option>
                <option value="General Physician">{ pretty('General Physician') }</option>
                <option value="Gentry">{ pretty('Gentry') }</option>
                <option value="Germination Worker (Malting)">{ pretty('Germination Worker (Malting)') }</option>
                <option value="Goldsmith and Silversmith">{ pretty('Goldsmith and Silversmith') }</option>
                <option value="Gunsmith">{ pretty('Gunsmith') }</option>
                <option value="Hat Maker General">{ pretty('Hat Maker General') }</option>
                <option value="Hat Maker, General">{ pretty('Hat Maker, General') }</option>
                <option value="Head Teacher">{ pretty('Head Teacher') }</option>
                <option value="Hosiery knitter (Hand)">{ pretty('Hosiery knitter (Hand)') }</option>
                <option value="Housebuilder General">{ pretty('Housebuilder General') }</option>
                <option value="Housekeeper (Private Service in Hotels or in Other Institutions)">{ pretty('Housekeeper (Private Service in Hotels or in Other Institutions)') }</option>
                <option value="Housekeeper (Private Service)">{ pretty('Housekeeper (Private Service)') }</option>
                <option value="Instrumentalist">{ pretty('Instrumentalist') }</option>
                <option value="Jeweller General">{ pretty('Jeweller General') }</option>
                <option value="Labourer">{ pretty('Labourer') }</option>
                <option value="Lace Weaver (Hand or Machine)">{ pretty('Lace Weaver (Hand or Machine)') }</option>
                <option value="Launderer General">{ pretty('Launderer General') }</option>
                <option value="Launderer, General">{ pretty('Launderer, General') }</option>
                <option value="Leather Currier">{ pretty('Leather Currier') }</option>
                <option value="Leather Goods Maker General">{ pretty('Leather Goods Maker General') }</option>
                <option value="Legal Clerk ">{ pretty('Legal Clerk ') }</option>
                <option value="Livestock Worker, General">{ pretty('Livestock Worker, General') }</option>
                <option value="Manager, Specialisation Unknown">{ pretty('Manager, Specialisation Unknown') }</option>
                <option value="Medical Assistant">{ pretty('Medical Assistant') }</option>
                <option value="Medical Dental Veterinary and Related Workers Not Elsewhere Classified">{ pretty('Medical Dental Veterinary and Related Workers Not Elsewhere Classified') }</option>
                <option value="Medical Doctor Specialisation Unknown">{ pretty('Medical Doctor Specialisation Unknown') }</option>
                <option value="Medical Doctor, Specialisation Unknown">{ pretty('Medical Doctor, Specialisation Unknown') }</option>
                <option value="Messenger">{ pretty('Messenger') }</option>
                <option value="Metal Casters">{ pretty('Metal Casters') }</option>
                <option value="Metal Marker">{ pretty('Metal Marker') }</option>
                <option value="Metal Moulder or Coremaker Specialisation Unknown">{ pretty('Metal Moulder or Coremaker Specialisation Unknown') }</option>
                <option value="Metal Pourer">{ pretty('Metal Pourer') }</option>
                <option value="Metal Processor Specialisation Unknown">{ pretty('Metal Processor Specialisation Unknown') }</option>
                <option value="Military Specialisation Unknown">{ pretty('Military Specialisation Unknown') }</option>
                <option value="Milliner General">{ pretty('Milliner General') }</option>
                <option value="Miner General">{ pretty('Miner General') }</option>
                <option value="Minister of Religion">{ pretty('Minister of Religion') }</option>
                <option value="Musical Instrument Tuner">{ pretty('Musical Instrument Tuner') }</option>
                <option value="Musical Instrument Tuner ">{ pretty('Musical Instrument Tuner ') }</option>
                <option value="Nursery Worker">{ pretty('Nursery Worker') }</option>
                <option value="Nursery Worker ">{ pretty('Nursery Worker ') }</option>
                <option value="Office Clerk, General">{ pretty('Office Clerk, General') }</option>
                <option value="Officer">{ pretty('Officer') }</option>
                <option value="Optical Instrument Maker and Repairer">{ pretty('Optical Instrument Maker and Repairer') }</option>
                <option value="Ordinary Seaman">{ pretty('Ordinary Seaman') }</option>
                <option value="Organ Builder">{ pretty('Organ Builder') }</option>
                <option value="Other Agricultural and Animal Husbandry Workers">{ pretty('Other Agricultural and Animal Husbandry Workers') }</option>
                <option value="Other Bakers Pastry Cooks and Confectionery Makers">{ pretty('Other Bakers Pastry Cooks and Confectionery Makers') }</option>
                <option value="Other Bakers, Pastry Cooks and Confectionery Makers ">{ pretty('Other Bakers, Pastry Cooks and Confectionery Makers ') }</option>
                <option value="Other Barbers Hairdressers Beauticians and Related Workers">{ pretty('Other Barbers Hairdressers Beauticians and Related Workers') }</option>
                <option value="Other Barbers, Hairdressers, Beauticians and Related Workers">{ pretty('Other Barbers, Hairdressers, Beauticians and Related Workers') }</option>
                <option value="Other Blacksmiths Toolmakers and Machine-Tool Operators Not Elsewhere Classified">{ pretty('Other Blacksmiths Toolmakers and Machine-Tool Operators Not Elsewhere Classified') }</option>
                <option value="Other Bleachers Dyers and Textile Product Finishers">{ pretty('Other Bleachers Dyers and Textile Product Finishers') }</option>
                <option value="Other Brewers Wine and Beverage Makers">{ pretty('Other Brewers Wine and Beverage Makers') }</option>
                <option value="Other Brewers, Wine and Beverage Makers">{ pretty('Other Brewers, Wine and Beverage Makers') }</option>
                <option value="Other Carpenters Joiners and Parquetry Workers">{ pretty('Other Carpenters Joiners and Parquetry Workers') }</option>
                <option value="Other Charworkers Cleaners and Related Workers">{ pretty('Other Charworkers Cleaners and Related Workers') }</option>
                <option value="Other Charworkers, Cleaners and Related Workers">{ pretty('Other Charworkers, Cleaners and Related Workers') }</option>
                <option value="Other Clerks">{ pretty('Other Clerks') }</option>
                <option value="Other Dockers and Freight Handlers">{ pretty('Other Dockers and Freight Handlers') }</option>
                <option value="Other Food and Beverage Processors">{ pretty('Other Food and Beverage Processors') }</option>
                <option value="Other Glass Formers Cutters Grinders and Finishers">{ pretty('Other Glass Formers Cutters Grinders and Finishers') }</option>
                <option value="Other Glass Formers, Cutters, Grinders and Finishers">{ pretty('Other Glass Formers, Cutters, Grinders and Finishers') }</option>
                <option value="Other Glaziers">{ pretty('Other Glaziers') }</option>
                <option value="Other Grain Millers and Related Workers">{ pretty('Other Grain Millers and Related Workers') }</option>
                <option value="Other Housekeeping and Related Service Supervisors">{ pretty('Other Housekeeping and Related Service Supervisors') }</option>
                <option value="Other Insurance, Real Estate and Securities Salesmen">{ pretty('Other Insurance, Real Estate and Securities Salesmen') }</option>
                <option value="Other Jewellery and Precious Metal Workers">{ pretty('Other Jewellery and Precious Metal Workers') }</option>
                <option value="Other Knitters">{ pretty('Other Knitters') }</option>
                <option value="Other Leather Goods Makers">{ pretty('Other Leather Goods Makers') }</option>
                <option value="Other Livestock Workers">{ pretty('Other Livestock Workers') }</option>
                <option value="Other Maids and Related Housekeeping Service Workers">{ pretty('Other Maids and Related Housekeeping Service Workers') }</option>
                <option value="Other Metal Drawers and Extruders">{ pretty('Other Metal Drawers and Extruders') }</option>
                <option value="Other Metal Processors">{ pretty('Other Metal Processors') }</option>
                <option value="Other Military Ranks">{ pretty('Other Military Ranks') }</option>
                <option value="Other Miners and Quarrymen">{ pretty('Other Miners and Quarrymen') }</option>
                <option value="Other Painters">{ pretty('Other Painters') }</option>
                <option value="Other Paper Makers ">{ pretty('Other Paper Makers ') }</option>
                <option value="Other Plasterers">{ pretty('Other Plasterers') }</option>
                <option value="Other Poultry Farm Workers">{ pretty('Other Poultry Farm Workers') }</option>
                <option value="Other Production and Related Workers Not Elsewhere Classified">{ pretty('Other Production and Related Workers Not Elsewhere Classified') }</option>
                <option value="Other Railway Engine-Drivers and Firemen">{ pretty('Other Railway Engine-Drivers and Firemen') }</option>
                <option value="Other Sales workers">{ pretty('Other Sales workers') }</option>
                <option value="Other Salesmen Shop Assistants and Demonstrators">{ pretty('Other Salesmen Shop Assistants and Demonstrators') }</option>
                <option value="Other Salesmen, Shop Assistants and Demonstrators">{ pretty('Other Salesmen, Shop Assistants and Demonstrators') }</option>
                <option value="Other Ships Deck Ratings Barge Crews and Boatmen">{ pretty('Other Ships Deck Ratings Barge Crews and Boatmen') }</option>
                <option value="Other Ships Deck Ratings, Barge Crews and Boatmen">{ pretty('Other Ships Deck Ratings, Barge Crews and Boatmen') }</option>
                <option value="Other Ships' Deck Officers and Pilots">{ pretty('Other Ships\' Deck Officers and Pilots') }</option>
                <option value="Other Ships' Deck Ratings, Barge Crews and Boatmen">{ pretty('Other Ships\' Deck Ratings, Barge Crews and Boatmen') }</option>
                <option value="Other Shoemakers and Shoe Repairers">{ pretty('Other Shoemakers and Shoe Repairers') }</option>
                <option value="Other Shoemakers and Shoe Repairers ">{ pretty('Other Shoemakers and Shoe Repairers ') }</option>
                <option value="Other Spinners and Winders">{ pretty('Other Spinners and Winders') }</option>
                <option value="Other Street Vendors Canvassers and News Vendors">{ pretty('Other Street Vendors Canvassers and News Vendors') }</option>
                <option value="Other Street Vendors, Canvassers and News Vendors">{ pretty('Other Street Vendors, Canvassers and News Vendors') }</option>
                <option value="Other Tailors Dressmakers Sewers Upholsterers and Related Workers">{ pretty('Other Tailors Dressmakers Sewers Upholsterers and Related Workers') }</option>
                <option value="Other Tailors and Dressmakers">{ pretty('Other Tailors and Dressmakers') }</option>
                <option value="Other Teachers">{ pretty('Other Teachers') }</option>
                <option value="Other Transport Equipment Operators">{ pretty('Other Transport Equipment Operators') }</option>
                <option value="Other Transport and Communications Supervisors">{ pretty('Other Transport and Communications Supervisors') }</option>
                <option value="Other University and Higher Education Teachers">{ pretty('Other University and Higher Education Teachers') }</option>
                <option value="Other Watch Clock and Precision Instrument Makers">{ pretty('Other Watch Clock and Precision Instrument Makers') }</option>
                <option value="Other Watch, Clock and Precision Instrument Makers">{ pretty('Other Watch, Clock and Precision Instrument Makers') }</option>
                <option value="Other Weavers and Related Workers">{ pretty('Other Weavers and Related Workers') }</option>
                <option value="Other Working Proprietors (Catering, Lodging and Leisure Services)">{ pretty('Other Working Proprietors (Catering, Lodging and Leisure Services)') }</option>
                <option value="Packer Hand or Machine">{ pretty('Packer Hand or Machine') }</option>
                <option value="Packer, Hand or Machine ">{ pretty('Packer, Hand or Machine ') }</option>
                <option value="Paper Box Maker (Hand or Machine)">{ pretty('Paper Box Maker (Hand or Machine)') }</option>
                <option value="Paper Maker Specialisation Unknown">{ pretty('Paper Maker Specialisation Unknown') }</option>
                <option value="Paviour">{ pretty('Paviour') }</option>
                <option value="Pawnbroker">{ pretty('Pawnbroker') }</option>
                <option value="Personal Maid Valet">{ pretty('Personal Maid Valet') }</option>
                <option value="Personal Maid, Valet">{ pretty('Personal Maid, Valet') }</option>
                <option value="Pharmacist">{ pretty('Pharmacist') }</option>
                <option value="Piano Maker">{ pretty('Piano Maker') }</option>
                <option value="Plasterer General">{ pretty('Plasterer General') }</option>
                <option value="Plasterer, General ">{ pretty('Plasterer, General ') }</option>
                <option value="Plumber General">{ pretty('Plumber General') }</option>
                <option value="Policeman and other Maintainers of Law and Order (except Military)">{ pretty('Policeman and other Maintainers of Law and Order (except Military)') }</option>
                <option value="Post Office Counter Clerk">{ pretty('Post Office Counter Clerk') }</option>
                <option value="Postman">{ pretty('Postman') }</option>
                <option value="Precision Instrument Maker">{ pretty('Precision Instrument Maker') }</option>
                <option value="Precision Instrument Maker ">{ pretty('Precision Instrument Maker ') }</option>
                <option value="Printer General">{ pretty('Printer General') }</option>
                <option value="Printer, General">{ pretty('Printer, General') }</option>
                <option value="Prison Guard">{ pretty('Prison Guard') }</option>
                <option value="Production Supervisor or Foreman General">{ pretty('Production Supervisor or Foreman General') }</option>
                <option value="Professional Midwife">{ pretty('Professional Midwife') }</option>
                <option value="Rope Maker General">{ pretty('Rope Maker General') }</option>
                <option value="Rubber Product Maker Specialisation Unknown">{ pretty('Rubber Product Maker Specialisation Unknown') }</option>
                <option value="Saddler and Harness Maker">{ pretty('Saddler and Harness Maker') }</option>
                <option value="Sail Tent and Awning Maker">{ pretty('Sail Tent and Awning Maker') }</option>
                <option value="Salesperson Wholesale or Retail Trade">{ pretty('Salesperson Wholesale or Retail Trade') }</option>
                <option value="Salesperson, Wholesale or Retail Trade">{ pretty('Salesperson, Wholesale or Retail Trade') }</option>
                <option value="Sawyer General">{ pretty('Sawyer General') }</option>
                <option value="Sawyer, General ">{ pretty('Sawyer, General ') }</option>
                <option value="Sculptor, Painter or Related Artist, Specialisation Unknown">{ pretty('Sculptor, Painter or Related Artist, Specialisation Unknown') }</option>
                <option value="Seaman Able or Ordinary">{ pretty('Seaman Able or Ordinary') }</option>
                <option value="Seaman, Able or Ordinary">{ pretty('Seaman, Able or Ordinary') }</option>
                <option value="Service Workers Not Elsewhere Classified">{ pretty('Service Workers Not Elsewhere Classified') }</option>
                <option value="Sheep Farm Worker">{ pretty('Sheep Farm Worker') }</option>
                <option value="Ship Plater">{ pretty('Ship Plater') }</option>
                <option value="Ship s Master (Sea or Inland Waterways)">{ pretty('Ship s Master (Sea or Inland Waterways)') }</option>
                <option value="Shoe Repairer">{ pretty('Shoe Repairer') }</option>
                <option value="Shoe-maker General">{ pretty('Shoe-maker General') }</option>
                <option value="Shoe-maker, General">{ pretty('Shoe-maker, General') }</option>
                <option value="Silk Degummer">{ pretty('Silk Degummer') }</option>
                <option value="Small Subsistence Farmer (Husbandman)">{ pretty('Small Subsistence Farmer (Husbandman)') }</option>
                <option value="Spinner Thread and Yarn">{ pretty('Spinner Thread and Yarn') }</option>
                <option value="Spinner Weaver Knitter Dyer or Related Worker Specialisation Unknown.">{ pretty('Spinner Weaver Knitter Dyer or Related Worker Specialisation Unknown.') }</option>
                <option value="Stock Broker">{ pretty('Stock Broker') }</option>
                <option value="Stone Cutter or Carver Specialisation Unknown">{ pretty('Stone Cutter or Carver Specialisation Unknown') }</option>
                <option value="Stone Cutter or Carver, Specialisation Unknown">{ pretty('Stone Cutter or Carver, Specialisation Unknown') }</option>
                <option value="Street Vendor">{ pretty('Street Vendor') }</option>
                <option value="Sugar Processor or Refiner Specialisation Unknown">{ pretty('Sugar Processor or Refiner Specialisation Unknown') }</option>
                <option value="Sugar Processor or Refiner, Specialisation Unknown ">{ pretty('Sugar Processor or Refiner, Specialisation Unknown ') }</option>
                <option value="Tailor Specialisation Unknown">{ pretty('Tailor Specialisation Unknown') }</option>
                <option value="Tailor, Specialisation Unknown">{ pretty('Tailor, Specialisation Unknown') }</option>
                <option value="Tax Collector">{ pretty('Tax Collector') }</option>
                <option value="Tax Collector ">{ pretty('Tax Collector ') }</option>
                <option value="Teacher Level and Subject Unknown Not University and Higher Education Level">{ pretty('Teacher Level and Subject Unknown Not University and Higher Education Level') }</option>
                <option value="Teacher, Level and Subject Unknown, Not University and Higher Education Level">{ pretty('Teacher, Level and Subject Unknown, Not University and Higher Education Level') }</option>
                <option value="Textile Bleacher">{ pretty('Textile Bleacher') }</option>
                <option value="Textile Printer">{ pretty('Textile Printer') }</option>
                <option value="Timber-Carrier Driver">{ pretty('Timber-Carrier Driver') }</option>
                <option value="Tinsmith">{ pretty('Tinsmith') }</option>
                <option value="Transport Conductor Specialisation Unknown">{ pretty('Transport Conductor Specialisation Unknown') }</option>
                <option value="Transport Conductor, Specialisation Unknown">{ pretty('Transport Conductor, Specialisation Unknown') }</option>
                <option value="Undertaker">{ pretty('Undertaker') }</option>
                <option value="Upholsterer or Related Worker Specialisation Unknown">{ pretty('Upholsterer or Related Worker Specialisation Unknown') }</option>
                <option value="Verger">{ pretty('Verger') }</option>
                <option value="Waiter General">{ pretty('Waiter General') }</option>
                <option value="Waiter, General">{ pretty('Waiter, General') }</option>
                <option value="Warehouse Porter">{ pretty('Warehouse Porter') }</option>
                <option value="Warehouse Porter ">{ pretty('Warehouse Porter ') }</option>
                <option value="Watch and Clock Assembler">{ pretty('Watch and Clock Assembler') }</option>
                <option value="Watch and Clock Assembler or Repairer">{ pretty('Watch and Clock Assembler or Repairer') }</option>
                <option value="Watchman">{ pretty('Watchman') }</option>
                <option value="Well-Driller and Borer (except Oil and Gas Wells)">{ pretty('Well-Driller and Borer (except Oil and Gas Wells)') }</option>
                <option value="Wholesale Trade Salesperson">{ pretty('Wholesale Trade Salesperson') }</option>
                <option value="Wire Drawer (Hand or Machine)">{ pretty('Wire Drawer (Hand or Machine)') }</option>
                <option value="Women s or Men s Hairdresser">{ pretty('Women s or Men s Hairdresser') }</option>
                <option value="Women's or Men's Hairdresser">{ pretty('Women\'s or Men\'s Hairdresser') }</option>
                <option value="Wood Shipwright">{ pretty('Wood Shipwright') }</option>
                <option value="Wood Turner">{ pretty('Wood Turner') }</option>
                <option value="Worker No Further Information">{ pretty('Worker No Further Information') }</option>
                <option value="Working Proprietor (Cafe Bar and Snack Bar)">{ pretty('Working Proprietor (Cafe Bar and Snack Bar)') }</option>
                <option value="Working Proprietor (Cafe, Bar and Snack Bar)">{ pretty('Working Proprietor (Cafe, Bar and Snack Bar)') }</option>
                <option value="Working Proprietor (Catering Lodging or Leisure Services) Specialisation Unknown">{ pretty('Working Proprietor (Catering Lodging or Leisure Services) Specialisation Unknown') }</option>
                <option value="Working Proprietor (Catering, Lodging or Leisure Services), Specialisation Unknown">{ pretty('Working Proprietor (Catering, Lodging or Leisure Services), Specialisation Unknown') }</option>
                <option value="Working Proprietor (Guest House)">{ pretty('Working Proprietor (Guest House)') }</option>
                <option value="Working Proprietor (Hiring Out)">{ pretty('Working Proprietor (Hiring Out)') }</option>
                <option value="Working Proprietor (Hotel and Restaurant)">{ pretty('Working Proprietor (Hotel and Restaurant)') }</option>
                <option value="Working Proprietor (Restaurant)">{ pretty('Working Proprietor (Restaurant)') }</option>
                <option value="Working Proprietor (Retail Trade)">{ pretty('Working Proprietor (Retail Trade)') }</option>
                <option value="Working Proprietor (Wholesale or Retail Trade)">{ pretty('Working Proprietor (Wholesale or Retail Trade)') }</option>
                <option value="aristocrat">{ pretty('aristocrat') }</option>
                <option value="small manufacturers">{ pretty('small manufacturers') }</option>
            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-victim_hisco_label" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
