
import React from 'react'; // Needed to use the full Fragment syntax

//import { Link } from "react-router-dom";

import { domToReact } from 'html-react-parser';

import options from '../../data/oldbailey/options';

const options_toc = {

    replace: (domNode) => {

        if (
            domNode.name === 'h2'
            && domNode.attribs
            && domNode.attribs.id
        ) {

            //return <Link to={ attribs.href.substring(39, attribs.href.length) }>{ domToReact(children, options) }</Link>
            //return <a href={ `/${process.env.REACT_APP_BASENAME}/about/` + attribs.href.substring(39, attribs.href.length) }>{ domToReact(children, options) }</a>
            return <li><a href={ '#' + domNode.attribs.id }>{ domToReact(domNode.children, options) }</a></li>;

        } else if (domNode.type === 'tag') {

            return ( <>{ domToReact(domNode.children, options_toc) }</> );

        } else {

            return (<></>);

        }
       
    }
};

export default options_toc;
