
import { useLocation, Redirect } from "react-router-dom";
import queryString from "query-string";

const BrowseRedirector = () => {

    /*
    https://www.oldbaileyonline.org/browse.jsp?id=f17220510-1&div=f17220510-1&terms=sheffield#highlight
    https://www.oldbaileyonline.org/browse.jsp?id=OA16921026&div=OA16921026&terms=regret#highlight
    https://www.oldbaileyonline.org/browse.jsp?id=t17760221-4-off18&div=t17760221-4#highlight
    https://www.oldbaileyonline.org/browse.jsp?dir=sessionsPapers
    https://www.oldbaileyonline.org/browse.jsp?dir=sessionsPapers&decade=172
    https://www.oldbaileyonline.org/browse.jsp?dir=ordinarysAccounts
    https://www.oldbaileyonline.org/browse.jsp?dir=ordinarysAccounts&decade=173
    */

    const location = useLocation();
    const qs = queryString.parse(location.search, { arrayFormat: "bracket" });

    return (     

        <>

        { qs['div'] && (

            <>

            { qs['terms'] && (

                <>
                {/* <span>{ '/record/' + qs['div'] + '?text=' + qs['terms'] }</span> */}
                <Redirect to={ '/record/' + qs['div'] + '?text=' + qs['terms'] } />
                </>

            ) }

            { !qs['terms'] && (

                <>
                {/* <span>{ '/record/' + qs['div'] }</span> */}
                <Redirect to={ '/record/' + qs['div'] } />
                </>

            ) }

            </>

        ) }

        { qs['dir'] && (

            <>

            { qs['decade'] && (

                <>
                {/* <span>{ '/search/date/' + qs['decade'] + '0' }</span> */}
                <Redirect to={ '/search/date/' + qs['decade'] + '0' } />
                </>

            ) }

            { !qs['decade'] && (

                <>
                {/* <span>{ '/search/date' }</span> */}
                <Redirect to={ '/search/date' } />
                </>

            ) }

            </>

        ) }

        </>

    );

};

export default BrowseRedirector;
