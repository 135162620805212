
const Loading = () => {

    return (

        <div className="w-100 text-center">
            <i className="display-6 bi bi-box spin-fast"></i>
        </div>

    );

};

export default Loading;
