
const SearchSelectButton = (props) => {

    const searchmode = props.searchmode;
    const handleSearchmodeClick = props.handleSearchmodeClick;
   
    return (

        <>

        <div className="search-select-tooltip-container">
            <div className="search-select-tooltip">
                You can choose different ways to search using this button.
            </div>
        </div>

        <div className="dropdown d-grid gap-2"> 
            <button className="btn btn-light bg-primary text-white dropdown-toggle search-feature-mode-select text-start" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                { searchmode === 'keyword' && ( <><span className="material-symbols-sharp search-dropdown-button-icon">key</span><span className="ms-2 me-3">Keyword</span></> ) }
                { searchmode === 'name' && ( <><span className="material-symbols-sharp search-dropdown-button-icon">description</span><span className="ms-2 me-3">Name</span></> ) }
                { searchmode === 'crime' && ( <><span className="material-symbols-sharp search-dropdown-button-icon">balance</span><span className="ms-2 me-3">Crime, Verdict and Punishment</span></> ) }
                { searchmode === 'date' && ( <><span className="material-symbols-sharp search-dropdown-button-icon">calendar_month</span><span className="ms-2 me-3">Browse by Date</span></> ) }
                { searchmode === 'statistical' && ( <><span className="material-symbols-sharp search-dropdown-button-icon">monitoring</span><span className="ms-2 me-3">Statistical</span></> ) }
                { searchmode === 'ordinarys_accounts' && ( <><span className="material-symbols-sharp search-dropdown-button-icon">import_contacts</span><span className="ms-2 me-3">Ordinary's Accounts</span></> ) }
                { searchmode === 'associated_records' && ( <><span className="material-symbols-sharp search-dropdown-button-icon">grid_on</span><span className="ms-2 me-3">Associated Records</span></> ) }
                { searchmode === 'advanced' && ( <><span className="material-symbols-sharp search-dropdown-button-icon">tune</span><span className="ms-2 me-3">Advanced</span></> ) }
            </button>
            <ul className="dropdown-menu">
                <li><button type="button" className={ 'dropdown-item' + (searchmode === 'keyword' ? ' active' : '') } data-searchmode="keyword" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">key</span><span className="ms-1">Keyword</span></button></li>
                <li><button type="button" className={ 'dropdown-item' + (searchmode === 'name' ? ' active' : '') } data-searchmode="name" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">description</span><span className="ms-1">Name</span></button></li>
                <li><button type="button" className={ 'dropdown-item' + (searchmode === 'crime' ? ' active' : '') } data-searchmode="crime" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">balance</span><span className="ms-1">Crime, Verdict and Punishment</span></button></li>
                <li><button type="button" className={ 'dropdown-item' + (searchmode === 'date' ? ' active' : '') } data-searchmode="date" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">calendar_month</span><span className="ms-1">Browse by Date</span></button></li>
                <li><button type="button" className={ 'dropdown-item' + (searchmode === 'statistical' ? ' active' : '') } data-searchmode="statistical" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">monitoring</span><span className="ms-1">Statistical</span></button></li>
                <li><button type="button" className={ 'dropdown-item' + (searchmode === 'ordinarys_accounts' ? ' active' : '') } data-searchmode="ordinarys_accounts" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">import_contacts</span><span className="ms-1">Ordinary's Accounts</span></button></li>
                <li><button type="button" className={ 'dropdown-item' + (searchmode === 'associated_records' ? ' active' : '') } data-searchmode="associated_records" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">grid_on</span><span className="ms-1">Associated Records</span></button></li>
                <li><button type="button" className={ 'dropdown-item' + (searchmode === 'advanced' ? ' active' : '') } data-searchmode="advanced" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">tune</span><span className="ms-1">Advanced</span></button></li>
            </ul> 
        </div>

        </>

    );

};

export default SearchSelectButton;
