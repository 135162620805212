
const Field = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <select className="form-select" name="libarch" id="field_libarch" value={ values['libarch'] || '' } onChange={ handleChange }>
                <option value="">&lt; Any { pretty('library or archive') } &gt;</option>
                <option value="">{ pretty('') }</option>
                <option value="All Souls College, Oxford">{ pretty('All Souls College, Oxford') }</option>
                <option value="Bank of England">{ pretty('Bank of England') }</option>
                <option value="Bishopsgate Library">{ pretty('Bishopsgate Library') }</option>
                <option value="Bodleian Library, Oxford">{ pretty('Bodleian Library, Oxford') }</option>
                <option value="British Library">{ pretty('British Library') }</option>
                <option value="Chadwyck-Healey microfiche">{ pretty('Chadwyck-Healey microfiche') }</option>
                <option value="Glasgow University Library">{ pretty('Glasgow University Library') }</option>
                <option value="Guildhall Library">{ pretty('Guildhall Library') }</option>
                <option value="Hackney Archives Department">{ pretty('Hackney Archives Department') }</option>
                <option value="Inner Temple Library">{ pretty('Inner Temple Library') }</option>
                <option value="John Johnson Collection, Oxford">{ pretty('John Johnson Collection, Oxford') }</option>
                <option value="John Rylands Library, Manchester">{ pretty('John Rylands Library, Manchester') }</option>
                <option value="Lincolns Inn Library">{ pretty('Lincolns Inn Library') }</option>
                <option value="London Metropolitan Archives">{ pretty('London Metropolitan Archives') }</option>
                <option value="National Library of Scotland">{ pretty('National Library of Scotland') }</option>
                <option value="Post Office Archive">{ pretty('Post Office Archive') }</option>
                <option value="RPI 18th-century microfilm">{ pretty('RPI 18th-century microfilm') }</option>
                <option value="Senate House Library, University of London">{ pretty('Senate House Library, University of London') }</option>
                <option value="The National Archives">{ pretty('The National Archives') }</option>
                <option value="UMI Microfilms">{ pretty('UMI Microfilms') }</option>
                <option value="Westminster City Archives">{ pretty('Westminster City Archives') }</option>
            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-libarch" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
