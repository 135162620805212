
const FieldSearchIn = (props) => {
    
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>
            <select className="form-select" name="div_type" id="field_div_type" value={ values['div_type'] || '' } onChange={ handleChange }>
                <option value="">&lt; Search all Proceedings text &gt;</option>
                <option value="trialAccount">Search only trial accounts</option>
                <option value="advertisements">Search only advertisements</option>
                <option value="frontMatter">Search only front matter</option>
                <option value="punishmentSummary">Search only punishment summaries</option>
                <option value="supplementaryMaterial">Search only supplementary material</option>
            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-div_type" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default FieldSearchIn;
