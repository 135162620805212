
const SearchButton = () => {
    
    return (

        <>

            <button type="submit" className="btn btn-outline-input-group btn-light bg-primary text-white search-feature-inline-submit">
                <span className="ms-2 me-2">Search</span>
                <span className="material-symbols-sharp">search</span>
            </button>

        </>

    );

};

export default SearchButton;
