
const Field = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <select className="form-select" name="person_type" id="field_person_type" value={ values['person_type'] || '' } onChange={ handleChange }>
                <option value="">&lt; Any person role &gt;</option>
                <option value="defendantName">{ pretty('defendantName') }</option>
                <option value="judiciaryName">{ pretty('judiciaryName') }</option>
                <option value="jurorName">{ pretty('jurorName') }</option>
                <option value="victimName">{ pretty('victimName') }</option>
                <option value="witnessName">{ pretty('witnessName') }</option>
            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="fieldcontextname" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
