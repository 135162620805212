
import useResults from "../../hooks/useResults";
import usePretty from "../../hooks/usePretty";

import { Link } from "react-router-dom";

import Loading from "../Loading";
import Error from "../Error";

import lookups from '../../data/oldbailey/lookups';

const DateTable = (props) => {  

    const decade = props.decade;

    const [ results, isLoading, error ] =
    useResults(`${process.env.REACT_APP_API_URL}/data/oldbailey_datetable`, 'decade=' + decade, true);

    const [ pretty ] =
    usePretty(lookups);
 
    const renderTable = (tdata) => {

        const rows = tdata.map((row, rowidx) => {
               
            const cells = row.map((cell, cellidx) => {

                    const items = cell.map((item, itemidx) => { 

                        const doctype = ( item._source.idkey.startsWith('OA') ? "Ordinary's Acc." : 'Proceedings' );

                        const idkey = item._source.idkey;
                        const datestring = ( idkey.startsWith('OA') ? idkey.substring(2) : (idkey.startsWith('t') ? idkey.substring(1) : idkey) );

                        // Javascript date handling is broken: https://stackoverflow.com/questions/51159704/chrome-javascript-date-constructor-appears-to-get-dates-before-1884-wrong

                        //const isodate = datestring.substring(0, 4) + '-' + datestring.substring(4, 6) + '-' + datestring.substring(6, 8) + ' ';
                        //const date = new Date(isodate);
                        //const nicedate = pretty(date);
                        //const abrdate = nicedate.substring(0, nicedate.length - 4);

                        const year = datestring.substring(0, 4);
                        const month = datestring.substring(4, 6);
                        const day = datestring.substring(6, 8);

                        const src = item._source.images[0] || 'https://www.dhi.ac.uk/san/ob/noimage.gif';

                        return (

                            <div key={ itemidx } className={ 'datetable-cell mb-2 ' + ( item._source.idkey.startsWith('OA') ? 'datetable-cell-oa' : '' ) }>

                                <Link to={ 

                                    '/record/' + 

                                    /*( item._source.firstdiv ? item._source.firstdiv : idkey )*/
                                    ( idkey )

                                }>

                                    <div className="datetable-image-holder">
                                        <img className="datetable-image img-fluid" src={ src } alt="Title page of the document"/>
                                    </div>
                                    <div className="datetable-legend text-center">
                                        { doctype }<br/>
                                        { Number(day) }{ pretty(Number(day), ['ordinal']) } { pretty(Number(month), ['month']) } { Number(year) }
                                    </div>

                                </Link>

                            </div>

                        )

                    });

                    return (<td key={ cellidx }><div className="mt-4 mb-4 d-flex justify-content-center flex-wrap">{ items }</div></td>)

            });

            const rowlabel = decade.substring(0,3) + rowidx;

            if ( // Omit missing years

                rowlabel === '1670' ||
                rowlabel === '1671' ||
                rowlabel === '1672' ||
                rowlabel === '1673' ||

                rowlabel === '1914' ||
                rowlabel === '1915' ||
                rowlabel === '1916' ||
                rowlabel === '1917' ||
                rowlabel === '1918' ||
                rowlabel === '1919'

            ) { 

                return null;

            } 

            return (<tr key={ rowidx }><th className="text-center"><div className="mt-4">{ rowlabel }</div></th>{ cells }</tr>);

        });

        const table = (

            <table className='table'>

                <thead>
                    <tr>
                        <th className="text-center date-table-set-width-small"></th>
                        <th className="text-center date-table-set-width">January</th>
                        <th className="text-center date-table-set-width">February</th>
                        <th className="text-center date-table-set-width">March</th>
                        <th className="text-center date-table-set-width">April</th>
                        <th className="text-center date-table-set-width">May</th>
                        <th className="text-center date-table-set-width">June</th>
                        <th className="text-center date-table-set-width">July</th>
                        <th className="text-center date-table-set-width">August</th>
                        <th className="text-center date-table-set-width">September</th>
                        <th className="text-center date-table-set-width">October</th>
                        <th className="text-center date-table-set-width">November</th>
                        <th className="text-center date-table-set-width">December</th>
                    </tr>
                </thead>
                <tbody>{ rows }</tbody>

            </table>

        )

        return table;

    }

    let table;
    
    if (results.hits && results.hits.hits) {

        const years = 10;
        const months = 12;

        const tdata = Array.apply(null, Array(years)).map(function() {
            return Array.apply(null, Array(months)).map(function() {
                return [];
            });
        });

        results.hits.hits.forEach((hit, idx) => {

            const year = parseInt(hit._source.year.substring(3));
            const month = parseInt(hit._source.month - 1);

            tdata[year][month].push(hit);

        });

        table = renderTable(tdata);

    }    

    return (

        <div>

            { isLoading && ( <div className="mt-5 mb-5 pt-5 pb-5"><Loading /></div> ) }

            { error && ( <div className="mt-5 mb-5 pt-5 pb-5"><Error error={ error } /></div> ) }

            { (!isLoading && !error) && (

                <>
                    { table }
                </>

            ) }

            { results && results.hits && results.hits.total === 0 && (

                <div>

                    <strong className="fs-6 ms-1 me-1">No records</strong> matched this query.

                </div>

            ) }
                       
        </div>

    );

};

export default DateTable;
