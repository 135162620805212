
const Field = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <select className="form-select" name="punishment" id="field_punishment" value={ values['punishment'] || '' } onChange={ handleChange }>

                <option value="">&lt; Any { pretty('punishment') } &gt;</option>

                <option value="corporal">{ pretty('corporal') } &gt; Any subcategory</option>
                <option value="pillory">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('corporal') } &gt; { pretty('pillory') }</option>
                <option value="privateWhipping">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('corporal') } &gt; { pretty('privateWhipping') }</option>
                <option value="publicWhipping">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('corporal') } &gt; { pretty('publicWhipping') }</option>
                <option value="whipping">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('corporal') } &gt; { pretty('whipping') }</option>
                <option value="corporalNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('corporal') } &gt; { pretty('corporalNoDetail') }</option>

                <option value="death">{ pretty('death') } &gt; Any subcategory</option>
                <option value="burning">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('death') } &gt; { pretty('burning') }</option>
                <option value="deathAndDissection">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('death') } &gt; { pretty('deathAndDissection') }</option>
                <option value="drawnAndQuartered">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('death') } &gt; { pretty('drawnAndQuartered') }</option>
                <option value="executed">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('death') } &gt; { pretty('executed') }</option>
                <option value="hangingInChains">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('death') } &gt; { pretty('hangingInChains') }</option>
                <option value="respited">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('death') } &gt; { pretty('respited') }</option>
                <option value="respitedForPregnancy">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('death') } &gt; { pretty('respitedForPregnancy') }</option>
                <option value="deathNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('death') } &gt; { pretty('deathNoDetail') }</option>

                <option value="imprison">{ pretty('imprison') } &gt; Any subcategory</option>
                <option value="hardLabour">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('imprison') } &gt; { pretty('hardLabour') }</option>
                <option value="houseOfCorrection">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('imprison') } &gt; { pretty('houseOfCorrection') }</option>
                <option value="insanity">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('imprison') } &gt; { pretty('insanity') }</option>
                <option value="newgate">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('imprison') } &gt; { pretty('newgate') }</option>
                <option value="otherInstitution">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('imprison') } &gt; { pretty('otherInstitution') }</option>
                <option value="penalServitude">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('imprison') } &gt; { pretty('penalServitude') }</option>
                <option value="preventiveDetention">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('imprison') } &gt; { pretty('preventiveDetention') }</option>
                <option value="imprisonNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('imprison') } &gt; { pretty('imprisonNoDetail') }</option>

                <option value="miscPunish">{ pretty('miscPunish') } &gt; Any subcategory</option>
                <option value="branding">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscPunish') } &gt; { pretty('branding') }</option>
                <option value="brandingOnCheek">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscPunish') } &gt; { pretty('brandingOnCheek') }</option>
                <option value="fine">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscPunish') } &gt; { pretty('fine') }</option>
                <option value="forfeiture">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscPunish') } &gt; { pretty('forfeiture') }</option>
                <option value="militaryNavalDuty">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscPunish') } &gt; { pretty('militaryNavalDuty') }</option>
                <option value="sureties">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscPunish') } &gt; { pretty('sureties') }</option>
                <option value="miscPunishNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('miscPunish') } &gt; { pretty('miscPunishNoDetail') }</option>

                <option value="noPunish">{ pretty('noPunish') } &gt; Any subcategory</option>
                <option value="pardon">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('noPunish') } &gt; { pretty('pardon') }</option>
                <option value="sentenceRespited">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('noPunish') } &gt; { pretty('sentenceRespited') }</option>
                <option value="noPunishNoDetail">&nbsp;&nbsp;&nbsp;&nbsp;{ pretty('noPunish') } &gt; { pretty('noPunishNoDetail') }</option>

                <option value="transport">{ pretty('transport') }</option>

            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-punishment" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
