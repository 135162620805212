
import { useLocation, Redirect } from "react-router-dom";
import queryString from "query-string";

const StatsRedirector = () => {

    /*
    https://www.oldbaileyonline.org/stats.jsp?y=offenceCategory&x=&countBy=&render=&_offences_offenceCategory_offenceSubcategory=&_verdicts_verdictCategory_verdictSubcategory=&_punishments_punishmentCategory_punishmentSubcategory=&_defendantNames_defendantGender=&defendantAgeFrom=&defendantAgeTo=&_victimNames_victimGender=&victimAgeFrom=&victimAgeTo=&_divs_fulltext=&kwparse=and&fromMonth=&fromYear=&toMonth=&toYear=
    */

    const location = useLocation();
    const qs = queryString.parse(location.search, { arrayFormat: "bracket" });

    let searchmode = 'statistical';

    let nps = {}

    if (qs['y'] && qs['y'] === 'offenceCategory') { nps['series'] = 'offence_category'; }

    //const stringified = ( Object.keys(nps).length > 0 ? '?' + queryString.stringify(nps, { arrayFormat: "bracket" }) : '');

    return (     

        <>
            {/* <span>{ '/search/' + searchmode + stringified + '#results' }</span> */}
            {/* <span>{ '/search/' + searchmode }</span> */}
            <Redirect to={ '/search/' + searchmode } />
        </>
 
    );

};

export default StatsRedirector;
