
import { Link } from "react-router-dom";

//import dhilogo from "../../data/oldbailey/dhilogo.svg";

const Footer = () => {

    return (

        <footer>

            <div id="footer-top-links">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center p-2">
                            <Link className="" to={ "/about/legal-info" }>
                                Copyright & Citation
                            </Link>
                            <span className="vertical-divider">|</span>
                            <Link className="" to={ "/about/about" }>
                                About this Project
                            </Link>
                            <span className="vertical-divider">|</span>
                            <Link className="" to={ "/about/contact" }>
                                Contact
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row pt-4 pb-4">
                    <div className="col-12 col-md-8 col-lg-6 mb-3">
                        <h1>About this website</h1>
                        This project was originally funded by the Arts and Humanities Research Council and the Big Lottery Fund.
                        Project directors are Tim Hitchcock and Robert Shoemaker; the data manager is Sharon Howard and the chief
                        technical officer is Jamie McLaughlin. This website is published by the <a href="https://www.dhi.ac.uk/">Digital Humanities Institute</a> at the 
                        University of Sheffield. See <Link to={ 'about/about' }>About this Project</Link>.
                    </div>
                    <div className="col-12 col-md-4 col-lg-6">
                        <div className="row">
                            <div className="col-6 col-md-12 order-md-2 text-md-end">
                                <a href="https://www.dhi.ac.uk/">
                                    <img src={ process.env.PUBLIC_URL + '/dhilogo.svg' } className="img-fluid dhilogo" alt="The Digital Humanities Institute Sheffield logo" />
                                </a>
                            </div>
                            <div className="col-6 col-md-12 order-md-1 text-white footer-version text-end mb-3">
                                <div>Version 9.0</div>
                                <div>Autumn 2023</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div id="footer-bottom-links" className="col-12 text-center p-2">
                            {/*
                            Version 9.0
                        <span className="vertical-divider">|</span>
                            Autumn 2023
                        <span className="vertical-divider">|</span>
                            */}
                            ISBN 978-0-9557876-0-7
                        <span className="vertical-divider">|</span>
                            &copy; 2003-2023 Old Bailey Proceedings Online
                    </div>
                </div>
            </div> 

        </footer>

    );

};

export default Footer;
