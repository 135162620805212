
import { useCallback } from "react";

import queryString from "query-string";
import { useLocation } from "react-router-dom";

import useResults from "../../hooks/useResultsNew";

import MacroscopeScatter from "./MacroscopeScatter";
import Loading from "../Loading";
import Error from "../Error";

const Macroscope = (props) => {

    const handleChangeHelpslug = props.handleChangeHelpslug;
    //const searchmode = props.searchmode;

    const location = useLocation();

    const preprocess = useCallback( () => {

        let qs = queryString.parse(location.search, { arrayFormat: "bracket" });

        let path = `${process.env.REACT_APP_API_URL}/data/oldbailey_macroscope`;   

        if (qs['text'] && /^([a-z][0-9]{8}[a]?-[0-9]{1,4})$/.test(qs['text'])) {
            qs['idkey'] = qs['text'];
            delete qs['text'];
        }

        if (qs['month_gte'] && qs['year_gte']) {
            qs['year_month_gte'] = qs['year_gte'] + qs['month_gte'].padStart(2, "0");
            delete qs['month_gte'];
            delete qs['year_gte'];
        }

        if (qs['month_lte'] && qs['year_lte']) {
            qs['year_month_lte'] = qs['year_lte'] + (qs['month_lte']).padStart(2, "0");
            delete qs['month_lte'];
            delete qs['year_lte'];
        }

        const stringified = queryString.stringify(qs, { arrayFormat: "bracket" });

        return `${ path }?${ stringified }`;

    }, [ location.search ]);

    const [ results, isLoading, error ] =
    //useResults(`${process.env.REACT_APP_API_URL}/data/oldbailey_macroscope`);
    useResults( null, false, preprocess );

    //console.log('Macroscope redrew.');

    return (

        <div>

            { isLoading && ( <div className="mt-5 mb-5 pt-5 pb-5"><Loading /></div> ) }

            { error && ( <div className="mt-5 mb-5 pt-5 pb-5"><Error error={ error } /></div> ) }

            { (!isLoading && !error && results && results.aggregations && results.hits.total > 0) && (

                <div className="bg-white border-top pt-3 pe-3">
                <div className="text-end">
                    <button type="button" className="btn btn-link text-muted macroscope-help" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-macroscope" onClick={ handleChangeHelpslug }>
                        What is this?
                    </button>
                </div>

                <div className="macroscope">
                                   
                    <MacroscopeScatter
                    filterkey={ 'lenclass' }
                    viz={ 'scatter' }
                    agg={ results.aggregations.lenclass }
                    total={ results.hits.total }
                    />

                </div>
                </div>

            ) }

        </div>

    );

};

export default Macroscope;
