
import { useEffect, useState, useCallback } from "react";

import { Link, useLocation } from "react-router-dom";

//import useSearchmode from "../../hooks/oldbailey/useSearchmode";

import oblogo from "../../data/oldbailey/OldBailey-Logo-White.png";

//import { Dropdown } from "bootstrap";

//import "bootstrap";

const Navbar = () => {

    //const [ handleSearchmodeClick ] =
    //useSearchmode();

    //const searchmode = 'keyword';

    const handleCollapse = () => {

        //console.log('handleCollapse');

        const dts = document.querySelectorAll(".dropdown-toggle");
        //console.log(dts);

        dts.forEach( (dt, i) => {
 
            //console.log(dt);

            if (dt && dt.getAttribute("aria-expanded") === "true") {
                // user click to hide, .hide() reloads the page in Safari!
                dt.click();
            } else {
                //console.log('no dropdown-toggle or aria-expanded is not true');
            }

        });

        const nt = document.querySelector(".navbar-toggler");
        //console.log(nt);

        if (nt && nt.getAttribute("aria-expanded") === "true") {
            // user click to hide, .hide() reloads the page in Safari!
            nt.click();
        } else {
            //console.log('no navbar-toggler or aria-expanded is not true');
        }

        /*
        if (
            dropdownToggle &&
            dropdownToggle.getAttribute("aria-expanded") === "true"
        ) {
            // user click to hide, .hide() reloads the page in Safari!
            dropdownToggle.click();
        } else {
            console.log('no dropdownToggle or aria-expanded is not true');
        }
        */

        // hide the menu if it is expanded (i.e in small screen responsive view)
        //if (!toggle) {
        //    toggleFunc();
        //}

    };

    const location = useLocation();

    //const searchDropdown = new Dropdown(document.getElementById('searchDropdown'));
    //const bootstrap = require('bootstrap');
    //var ModaleditarIntegrante = new bootstrap.Dropdown('#searchDropdown');

    /*
    useEffect(() => {

        console.log('Navbar useEffect ran');

        const searchDropdown = new Dropdown(document.getElementById('searchDropdown'));

        //var donk = document.getElementById('search-dropdown');
        //console.log(donk);
        //var blonk = Dropdown.getOrCreateInstance(donk)
        //console.log(blonk);
        //blonk.hide();

    }, [ location ]);
    */

    const shouldLogoBeShown = useCallback( () => {

        if (location.pathname === '/' && window.scrollY < 200) {

            return false;

        } else {

            return true;

        }

    }, [ location.pathname ]);

    const [ showLogo, setShowLogo ] = useState(shouldLogoBeShown);
    
    // Display the navbar logo as we scroll down this page.
    useEffect(() => {

        const handleScroll = () => {

            setShowLogo(shouldLogoBeShown);

        };
  
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, [ shouldLogoBeShown ]);

    useEffect(() => {

        setShowLogo(shouldLogoBeShown);

    }, [ location.pathname, shouldLogoBeShown ]);



    let topnav = '';

    if (location.pathname.startsWith('/search/')) { topnav = 'search'; }

    if (location.pathname === "/about/proceedings") { topnav = 'about'; }
    if (location.pathname === "/about/publishinghistory") { topnav = 'about'; }
    if (location.pathname === "/about/value") { topnav = 'about'; }
    if (location.pathname === "/about/advertising") { topnav = 'about'; }
    if (location.pathname === "/about/linked-records") { topnav = 'about'; }
    if (location.pathname === "/about/ordinarys-accounts") { topnav = 'about'; }
    if (location.pathname === "/about/locating-information-about-old-bailey-trials-after-1913") { topnav = 'about'; }
    
    if (location.pathname === "/about/history") { topnav = 'history'; }
    if (location.pathname === "/about/crime") { topnav = 'history'; }
    if (location.pathname === "/about/london-life") { topnav = 'history'; }
    if (location.pathname === "/about/communities") { topnav = 'history'; }
    if (location.pathname === "/about/gender") { topnav = 'history'; }
    if (location.pathname === "/about/the-old-bailey") { topnav = 'history'; }
    if (location.pathname === "/about/glossary") { topnav = 'history'; }
    if (location.pathname === "/about/bibliography") { topnav = 'history'; }

    if (location.pathname === "/about/guides") { topnav = 'guides'; }
    if (location.pathname === "/about/gettingstarted") { topnav = 'guides'; }
    if (location.pathname === "/about/searchhelp") { topnav = 'guides'; }
    if (location.pathname === "/about/howtoreadtrial") { topnav = 'guides'; }
    if (location.pathname === "/about/mediatedtexts") { topnav = 'guides'; }
    if (location.pathname === "/about/doingstatistics") { topnav = 'guides'; }
    if (location.pathname === "/about/zotero") { topnav = 'guides'; }
    if (location.pathname === "/about/obapidemonstrator") { topnav = 'guides'; }
    if (location.pathname === "/about/universityteaching") { topnav = 'guides'; }
    if (location.pathname === "/about/projects") { topnav = 'guides'; }

    if (location.pathname === "/about/data") { topnav = 'downloads'; }

    if (location.pathname === "/about/project") { topnav = 'project'; }
    if (location.pathname === "/about/about") { topnav = 'project'; }
    if (location.pathname === "/about/a-narrative-history-of-the-project") { topnav = 'project'; }
    if (location.pathname === "/about/legal-info") { topnav = 'project'; }
    if (location.pathname === "/about/whats-new") { topnav = 'project'; }
    if (location.pathname === "/about/whats-new-archive") { topnav = 'project'; }
    if (location.pathname === "/about/projects") { topnav = 'project'; }
    if (location.pathname === "/about/contact") { topnav = 'project'; }

    return (

            <nav className="navbar sticky-top navbar-expand-lg navbar-dark ob-main-navbar border-bottom border-white pt-1 pb-0 ps-3 pe-3 nav-shadow">

                <div className="container-fluid">

                    <Link to={"/"} className="navbar-brand pt-2 pb-2">
                        <img className={ showLogo ? '' : 'd-none' } src={ oblogo } alt="The Proceedings of the Old Bailey logo" />                       
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="material-symbols-sharp navbar-togger-icon">menu</span>
                    </button>

                    <div className="collapse navbar-collapse ob-navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                
                            <li className="nav-item dropdown me-3">
                                <a className={ "nav-link dropdown-toggle" + ( topnav === 'search' ? ' active' : '' ) } href={ ' #' } role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Search
                                </a>
                                <ul className="dropdown-menu" id="searchDropdown">

                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/search/keyword' ? ' active' : '' ) } to={ "/search/keyword" } onClick={ handleCollapse }>Keyword</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/search/name' ? ' active' : '' ) } to={ "/search/name" } onClick={ handleCollapse }>Name</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/search/crime' ? ' active' : '' ) } to={ "/search/crime" } onClick={ handleCollapse }>Crime, Verdict and Punishment</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/search/date' ? ' active' : '' ) } to={ "/search/date" } onClick={ handleCollapse }>Browse by Date</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/search/statistical' ? ' active' : '' ) } to={ "/search/statistical" } onClick={ handleCollapse }>Statistical</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/search/ordinarys_accounts' ? ' active' : '' ) } to={ "/search/ordinarys_accounts" } onClick={ handleCollapse }>Ordinary's Accounts</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/search/associated_records' ? ' active' : '' ) } to={ "/search/associated_records" } onClick={ handleCollapse }>Associated Records</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/search/advanced' ? ' active' : '' ) } to={ "/search/advanced" } onClick={ handleCollapse }>Advanced</Link></li>

                                </ul>
                            </li>

                            <li className="nav-item dropdown me-3">
                                <a className={ "nav-link dropdown-toggle" + ( topnav === 'about' ? ' active' : '' ) } href={ ' #' } role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    About the Proceedings
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/proceedings' ? ' active' : '' ) } to={ "/about/proceedings" } onClick={ handleCollapse }>Overview</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/publishinghistory' ? ' active' : '' ) } to={ "/about/publishinghistory" } onClick={ handleCollapse }>Publishing History</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/value' ? ' active' : '' ) } to={ "/about/value" } onClick={ handleCollapse }>Value as a Historical Source</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/advertising' ? ' active' : '' ) } to={ "/about/advertising" } onClick={ handleCollapse }>Advertisements</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/linked-records' ? ' active' : '' ) } to={ "/about/linked-records" } onClick={ handleCollapse }>Associated Records</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/ordinarys-accounts' ? ' active' : '' ) } to={ "/about/ordinarys-accounts" } onClick={ handleCollapse }>Ordinary of Newgate’s Accounts</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/locating-information-about-old-bailey-trials-after-1913' ? ' active' : '' ) } to={ "/about/locating-information-about-old-bailey-trials-after-1913" } onClick={ handleCollapse }>Locating Information about Old Bailey Trials after 1913</Link></li>
                                </ul>
                            </li>                                 

                            <li className="nav-item dropdown me-3">
                                <a className={ "nav-link dropdown-toggle" + ( topnav === 'history' ? ' active' : '' ) } href={ ' #' } role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Historical background
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/history' ? ' active' : '' ) } to={ "/about/history" } onClick={ handleCollapse }>Overview</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/crime' ? ' active' : '' ) } to={ "/about/crime" } onClick={ handleCollapse }>Crime, Justice and Punishment</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/london-life' ? ' active' : '' ) } to={ "/about/london-life" } onClick={ handleCollapse }>History of London</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/communities' ? ' active' : '' ) } to={ "/about/communities" } onClick={ handleCollapse }>Community Histories</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/gender' ? ' active' : '' ) } to={ "/about/gender" } onClick={ handleCollapse }>Gender in the Proceedings</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/the-old-bailey' ? ' active' : '' ) } to={ "/about/the-old-bailey" } onClick={ handleCollapse }>The Old Bailey Courthouse</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/glossary' ? ' active' : '' ) } to={ "/about/glossary" } onClick={ handleCollapse }>Glossary</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/bibliography' ? ' active' : '' ) } to={ "/about/bibliography" } onClick={ handleCollapse }>Bibliography</Link></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown me-3">
                                <a className={ "nav-link dropdown-toggle" + ( topnav === 'guides' ? ' active' : '' ) } href={ ' #' } role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Guides
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/guides' ? ' active' : '' ) } to={ "/about/guides" } onClick={ handleCollapse }>Overview</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/gettingstarted' ? ' active' : '' ) } to={ "/about/gettingstarted" } onClick={ handleCollapse }>Getting Started</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/searchhelp' ? ' active' : '' ) } to={ "/about/searchhelp" } onClick={ handleCollapse }>Guide to Searching</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/howtoreadtrial' ? ' active' : '' ) } to={ "/about/howtoreadtrial" } onClick={ handleCollapse }>How to Read an Old Bailey Trial</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/mediatedtexts' ? ' active' : '' ) } to={ "/about/mediatedtexts" } onClick={ handleCollapse }>How are the Proceedings Different when Read Online?</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/doingstatistics' ? ' active' : '' ) } to={ "/about/doingstatistics" } onClick={ handleCollapse }>Doing Statistics</Link></li>
                                    {/*<li><Link className={ "dropdown-item" + ( location.pathname === '/about/zotero' ? ' active' : '' ) } to={ "/about/zotero" } onClick={ handleCollapse }>Organising Your Research With Reference Management Tools (e.g. Zotero)</Link></li>*/}
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/api' ? ' active' : '' ) } to={ "/about/api" } onClick={ handleCollapse }>The Old Bailey API</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/universityteaching' ? ' active' : '' ) } to={ "/about/universityteaching" } onClick={ handleCollapse }>Using the Proceedings in University Teaching</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/projects' ? ' active' : '' ) } to={ "/about/projects" } onClick={ handleCollapse }>Digital Projects using Old Bailey Online Data</Link></li>
                                </ul>
                            </li>

                            {/*
                            <li className="nav-item me-3">
                                <Link className={ "nav-link" + ( topnav === 'downloads' ? ' active' : '' ) } to={ "/about/data" }>
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        Downloads
                                    </span>
                                </Link>
                            </li>
                            */}

                            <li className="nav-item me-3">
                                <Link className={ "nav-link" + ( topnav === 'downloads' ? ' active' : '' ) } to={ "/about/data" } onClick={ handleCollapse }>
                                    Downloads
                                </Link>
                            </li>

                            <li className="nav-item dropdown me-3">
                                <a className={ "nav-link dropdown-toggle" + ( topnav === 'project' ? ' active' : '' ) } href={ ' #' } role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Project
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/project' ? ' active' : '' ) } to={ "/about/project" } onClick={ handleCollapse }>Overview</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/about' ? ' active' : '' ) } to={ "/about/about" } onClick={ handleCollapse }>About the Project</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/a-narrative-history-of-the-project' ? ' active' : '' ) } to={ "/about/a-narrative-history-of-the-project" } onClick={ handleCollapse }>History of the Old Bailey Online Project</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/legal-info' ? ' active' : '' ) } to={ "/about/legal-info" } onClick={ handleCollapse }>Copyright Information and Citation Guide</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/whats-new' ? ' active' : '' ) } to={ "/about/whats-new" } onClick={ handleCollapse }>What’s New (Autumn 2023)</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/whats-new-archive' ? ' active' : '' ) } to={ "/about/whats-new-archive" } onClick={ handleCollapse }>What’s New Archive</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/projects' ? ' active' : '' ) } to={ "/about/projects" } onClick={ handleCollapse }>Digital Projects Using Old Bailey Online Data</Link></li>
                                    <li><Link className={ "dropdown-item" + ( location.pathname === '/about/contact' ? ' active' : '' ) } to={ "/about/contact" } onClick={ handleCollapse }>Contact Us</Link></li>
                                </ul>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>

    );

};

export default Navbar;
