
const Field = (props) => {
    
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;
    const first = props.first;

    return (

        <>

            <input type="search" className={ 'form-control' + (first ? ' search-feature-first-input' : '' ) } name="surname" id="field_surname" placeholder={ 'Search all surnames ...' } value={ values['surname'] || '' } onChange={ handleChange } />
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-surname" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
