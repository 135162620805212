
import { useState, useEffect } from "react";

import axios from "axios";

const usePost = (url, options) => {

    const [post, setPost] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [error, setError] = useState(false);

    useEffect(() => {

        //console.log('usePost useEffect ran');

        setIsLoading(true);

        const fetchData = async () => {

            try {

                const resp = await axios.get(`${ url }`, {
                    //withCredentials: true,
                    withCredentials: false,
                });

                const data = await resp?.data;

                setPost(data);
                setIsLoading(false);

            } catch (error) {

                console.log(error);
                setError(error);
                setIsLoading(false);

            }
        };

        fetchData();

    }, [ url ]);

    return [ post, isLoading, error ];

}

export default usePost;
