
import React from 'react'; // Needed to use the full Fragment syntax

import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { LineChart, Line, XAxis, YAxis, ZAxis } from 'recharts';
import { BarChart, Bar } from 'recharts';
import { ScatterChart, Scatter } from 'recharts';

import usePretty from "../hooks/usePretty";

import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Agg = (props) => {

    // Custom layout props

    const table3_solo_vizclass = props.table3_solo_vizclass || 'col-12 ps-4 pe-4 mb-5';
    const table3_multi_vizclass = props.table3_multi_vizclass || 'col-12 col-xl-6 ps-4 pe-4 mb-5';

    const pie3_solo_vizclass = props.pie3_solo_vizclass || 'col-12 mb-5';
    const pie3_multi_vizclass = props.pie3_multi_vizclass || 'col-12 col-xl-6 mb-5';
    const pie3_solo_chartclass = props.pie3_solo_chartclass || 'col-5';
    const pie3_solo_legendclass = props.pie3_solo_legendclass || 'col-7';
    const pie3_multi_chartclass = props.pie3_multi_chartclass || 'col-5';
    const pie3_multi_legendclass = props.pie3_multi_legendclass || 'col-7';
    const pie3_solo_rechartsclass = props.pie3_solo_rechartsclass || 'h-10em-responsive recharts-white-text-tooltip';
    const pie3_multi_rechartsclass = props.pie3_multi_rechartsclass || 'h-10em-responsive recharts-white-text-tooltip';

    const bar3_solo_vizclass = props.bar3_solo_vizclass || 'col-12 mb-5';
    const bar3_multi_vizclass = props.bar3_multi_vizclass || 'col-12 mb-5';
    const bar3_solo_chartclass = props.bar3_solo_chartclass || 'col-12 col-xl-9 mb-2';
    const bar3_solo_legendclass = props.bar3_solo_legendclass || 'col-12 col-xl-3';
    const bar3_multi_chartclass = props.bar3_multi_chartclass || 'col-12 col-xl-9 mb-2';
    const bar3_multi_legendclass = props.bar3_multi_legendclass || 'col-12 col-xl-3';
    const bar3_solo_rechartsclass = props.bar3_solo_rechartsclass || 'h-10em recharts-white-text-tooltip';
    const bar3_multi_rechartsclass = props.bar3_multi_rechartsclass || 'h-10em recharts-white-text-tooltip';

    const line3_solo_vizclass = props.line3_solo_vizclass || 'col-12 mb-5';
    const line3_multi_vizclass = props.line3_multi_vizclass || 'col-12 mb-5';
    const line3_solo_chartclass = props.line3_solo_chartclass || 'col-12 col-xl-9 mb-2';
    const line3_solo_legendclass = props.line3_solo_legendclass || 'col-12 col-xl-3';
    const line3_multi_chartclass = props.line3_multi_chartclass || 'col-12 col-xl-9 mb-2';
    const line3_multi_legendclass = props.line3_multi_legendclass || 'col-12 col-xl-3';
    const line3_solo_rechartsclass = props.line3_solo_rechartsclass || 'h-10em recharts-white-text-tooltip';
    const line3_multi_rechartsclass = props.line3_multi_rechartsclass || 'h-10em recharts-white-text-tooltip';

    const tablecross6_solo_vizclass = props.tablecross6_solo_vizclass || 'col-12 mb-5 ps-4 pe-4 pb-4 overflow-auto';
    const tablecross6_multi_vizclass = props.tablecross6_multi_vizclass || 'col-12 mb-5 ps-4 pe-4 pb-4 overflow-auto';

    const barstacked6_solo_vizclass = props.barstacked6_solo_vizclass || 'col-12 mb-5';
    const barstacked6_multi_vizclass = props.barstacked6_multi_vizclass || 'col-12 mb-5';
    const barstacked6_solo_chartclass = props.barstacked6_solo_chartclass || 'col-12 col-xl-9 mb-2';
    const barstacked6_solo_legendclass = props.barstacked6_solo_legendclass || 'col-12 col-xl-3';
    const barstacked6_multi_chartclass = props.barstacked6_multi_chartclass || 'col-12 col-xl-9 mb-2';
    const barstacked6_multi_legendclass = props.barstacked6_multi_legendclass || 'col-12 col-xl-3';
    const barstacked6_solo_rechartsclass = props.barstacked6_solo_rechartsclass || 'h-10em recharts-white-text-tooltip';
    const barstacked6_multi_rechartsclass = props.barstacked6_multi_rechartsclass || 'h-10em recharts-white-text-tooltip';

    const barstackednormalised6_solo_vizclass = props.barstackednormalised6_solo_vizclass || 'col-12 mb-5';
    const barstackednormalised6_multi_vizclass = props.barstackednormalised6_multi_vizclass || 'col-12 mb-5';
    const barstackednormalised6_solo_chartclass = props.barstackednormalised6_solo_chartclass || 'col-12 col-xl-9 mb-2';
    const barstackednormalised6_solo_legendclass = props.barstackednormalised6_solo_legendclass || 'col-12 col-xl-3';
    const barstackednormalised6_multi_chartclass = props.barstackednormalised6_multi_chartclass || 'col-12 col-xl-9 mb-2';
    const barstackednormalised6_multi_legendclass = props.barstackednormalised6_multi_legendclass || 'col-12 col-xl-3';
    const barstackednormalised6_solo_rechartsclass = props.barstackednormalised6_solo_rechartsclass || 'h-10em recharts-white-text-tooltip';
    const barstackednormalised6_multi_rechartsclass = props.barstackednormalised6_multi_rechartsclass || 'h-10em recharts-white-text-tooltip';

    const bargrouped6_solo_vizclass = props.bargrouped6_solo_vizclass || 'col-12 mb-5';
    const bargrouped6_multi_vizclass = props.bargrouped6_multi_vizclass || 'col-12 mb-5';
    const bargrouped6_solo_chartclass = props.bargrouped6_solo_chartclass || 'col-12 col-xl-9 mb-2';
    const bargrouped6_solo_legendclass = props.bargrouped6_solo_legendclass || 'col-12 col-xl-3';
    const bargrouped6_multi_chartclass = props.bargrouped6_multi_chartclass || 'col-12 col-xl-9 mb-2';
    const bargrouped6_multi_legendclass = props.bargrouped6_multi_legendclass || 'col-12 col-xl-3';
    const bargrouped6_solo_rechartsclass = props.bargrouped6_solo_rechartsclass || 'h-10em recharts-white-text-tooltip';
    const bargrouped6_multi_rechartsclass = props.bargrouped6_multi_rechartsclass || 'h-10em recharts-white-text-tooltip';

    const linemulti6_solo_vizclass = props.linemulti6_solo_vizclass || 'col-12 mb-5';
    const linemulti6_multi_vizclass = props.linemulti6_multi_vizclass || 'col-12 mb-5';
    const linemulti6_solo_chartclass = props.linemulti6_solo_chartclass || 'col-12 col-xl-9 mb-2';
    const linemulti6_solo_legendclass = props.linemulti6_solo_legendclass || 'col-12 col-xl-3';
    const linemulti6_multi_chartclass = props.linemulti6_multi_chartclass || 'col-12 col-xl-9 mb-2';
    const linemulti6_multi_legendclass = props.linemulti6_multi_legendclass || 'col-12 col-xl-3';
    const linemulti6_solo_rechartsclass = props.linemulti6_solo_rechartsclass || 'h-10em recharts-white-text-tooltip';
    const linemulti6_multi_rechartsclass = props.linemulti6_multi_rechartsclass || 'h-10em recharts-white-text-tooltip';

    const location = useLocation();

    const COLORS = [ '#00b7d2', '#cc1076', '#2CA02C', '#ffce3c', '#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5' ]

    const KEY_FOLD_IDX = 14;

    const MATCH_SCALE = false; // Use the same scale for all charts in this agg, using maxcount. Implementation needs work.

    const objectDepth = (o) =>
        Object (o) === o ? 1 + Math.max (-1, ...Object.values(o).map (objectDepth)) : 0

    const aggtype = (props.viz || '') + '-' + objectDepth(props.agg);

    let ancestorkeys = props.ancestorkeys || [];

    let ancestortotals = props.ancestortotals || [];

    let colorAllocations = props.colorAllocations || {};

    let maxcount = props.maxcount || 0;

    const getColor = (key, idx) => {

        if (colorAllocations.hasOwnProperty(key)) {

            return colorAllocations[key];

        } else {

            colorAllocations[key] = COLORS[Object.keys(colorAllocations).length % COLORS.length];

            return colorAllocations[key];

        }

    }

    const [ pretty ] =
    usePretty(props.lookups);

    const findSubaggkeys = (bucket) => {

        let subaggkeys = []; // The only way I have found to identify subaggregations is to look for keys in the bucket 
                             // which are not 'key' or 'doc_count' :/

        for (let key of Object.keys(bucket)) {

            if (key !== 'key' && key !== 'doc_count') {

                subaggkeys.push(key);

            }

        }

        return subaggkeys;

    }

    const findSubaggBuckets = (esdata) => {

        let sabo = {}; // Use object first to add up totals for unique values
        let subaggkey;

        esdata.forEach((bucket, idx) => {

            const subaggkeys = findSubaggkeys(bucket);

            subaggkey = subaggkeys[0];

            bucket[subaggkey]['buckets'].forEach((subbucket, idx) => {

                if (sabo[subbucket['key']]) {

                    sabo[subbucket['key']] = sabo[subbucket['key']] + subbucket['doc_count'];

                } else {

                    sabo[subbucket['key']] = subbucket['doc_count'];

                }

            });

        });

        let saba = []; // Change the object into an array, so we can sort it as the final step.

        for (let key of Object.keys(sabo)) {

            saba.push( { 'key': key, 'doc_count': sabo[key] } );

        }

        saba.sort((a,b) => (a.doc_count < b.doc_count) ? 1 : ((b.doc_count < a.doc_count) ? -1 : 0));

        return [subaggkey, saba];

    }

    const makeRechartsMultilineData = (esdata, filterkey, subaggkey) => {

        const rcdata = esdata.map((bucket, idx) => {

            let rcbucket = {};

            rcbucket['key'] = pretty(bucket['key'], ['string', 'lookup_' + filterkey]);

            const subaggkeys = findSubaggkeys(bucket);

            bucket[subaggkeys[0]]['buckets'].forEach((subbucket, idx) => {

                rcbucket[pretty(subbucket['key'], ['string', 'lookup_' + subaggkey])] = subbucket['doc_count'];

            });

            return rcbucket;
        })

        return rcdata;

    }

    const makeRechartsScatterData = (esdata, filterkey, subaggkey) => {

        // http://localhost:3000/data/search/statistical?initviz=scatter&rows=year&series=lenclass#results
        // http://localhost:3000/data/search/statistical?initviz=scatter&rows=year&series=lenclass&text=stepney#results
        
        let rcdata = [];

        esdata.forEach((bucket, idx) => {

            //let rcbucket = [];

            //rcbucket['key'] = pretty(bucket['key'], ['string', 'lookup_' + filterkey]);

            const subaggkeys = findSubaggkeys(bucket);

            bucket[subaggkeys[0]]['buckets'].forEach((subbucket, idx) => {

                if (subbucket['doc_count'] > 0) {

                    const xrng = Math.random() * 1;
                    const yrng = Math.random() * 100;

                    rcdata.push({ 'year': (parseInt(subbucket['key']) + xrng), 'lenclass': (parseInt(bucket['key']) + yrng), 'doc_count': subbucket['doc_count'] });

                    //rcbucket[pretty(subbucket['key'], ['string', 'lookup_' + subaggkey])] = subbucket['doc_count'];
                    
                }

            });

            //return rcbucket;
        })
        
        console.log(rcdata);

        /*
        rcdata = [
            { 'year': 1670, 'lenclass': 200, 'doc_count': 1 },
            { 'year': 1680, 'lenclass': 100, 'doc_count': 20 },
            { 'year': 1690, 'lenclass': 300, 'doc_count': 30 },
            { 'year': 1700, 'lenclass': 250, 'doc_count': 40 },
            { 'year': 1710, 'lenclass': 400, 'doc_count': 50 },
            { 'year': 1720, 'lenclass': 280, 'doc_count': 60 },
        ];
        */

        return rcdata;

    }

    const makeTabularData = (esdata, subaggbuckets, ancestortotals, total, filterkey) => {

        let headings = ['key', 'doc_count'];

        // We have to iterate through the data twice. Once to get the headings, and once to write the rows.
        // Otherwise, if every subbucket is not present in the first bucket, we will end up with misaligned data.

        esdata.forEach((bucket, idx) => {

            for (var key in bucket) {

                if (bucket.hasOwnProperty(key)) {

                    let value = bucket[key];

                    if (!headings.includes(key)) {

                        // This means we have found a subbucket
                        // We support a single subaggregation
                        // We ignore any further subaggregations

                        value['buckets'].forEach((sbucket, idx) => {

                            if (!headings.includes(String(sbucket['key']))) {

                                headings.push(String(sbucket['key']));

                            }

                        });

                    }

                }

            }

        });

        let rows = [];

        // Now we use subaggbuckets to enter totals on the first row.
        // This is disabled for now because the totals are calculated by dumbly adding all the values in subaggs, in the function findSubaggBuckets().
        // This is not reliable and produces a different total to that which will be displayed if the user clicks through to add the relevant filters.

        /*
        if (subaggbuckets) {

            let row = Array(headings.length).fill(0, 0);

            subaggbuckets.forEach((subaggbucket, idx) => {

                if (headings.includes(subaggbucket['key'])) {

                    row[headings.indexOf(subaggbucket['key'])] = subaggbucket['doc_count'];

                }

            });

            // Now we enter either the ancestortotal or the grand total in the doc_count cell
            
            if (ancestortotals.length > 0) {

                row[headings.indexOf('doc_count')] = ancestortotals.at(-1);

            } else {

                row[headings.indexOf('doc_count')] = total;

            }
            
            rows.push(row);

        }
        */

        // Now we populate the rest of the rows

        esdata.forEach((bucket, idx) => {

            let row = Array(headings.length).fill(0, 0);

            for (var key in bucket) {

                if (bucket.hasOwnProperty(key)) {

                    let value = bucket[key];

                    if (!headings.includes(String(key))) {

                        // This means we have found a subbucket

                        value['buckets'].forEach((sbucket, idx) => {

                            row[headings.indexOf(String(sbucket['key']))] = sbucket['doc_count'];

                        });

                    }

                    if (headings.includes(String(key))) {

                        row[headings.indexOf(String(key))] = value;

                    }

                }

            }

            rows.push(row);

        });

        headings[0] = filterkey;
        headings[1] = 'total';
        //rows[0][0] = 'total';



        // Now calculate some totals
        
        let rowtotals = Array(rows.length).fill(0);
        let coltotals = Array(headings.length).fill(0);

        let rowmaxs = Array(rows.length).fill(0);
        let rowmins = Array(rows.length).fill(Number.MAX_SAFE_INTEGER);

        let colmaxs = Array(headings.length).fill(0);
        let colmins = Array(headings.length).fill(Number.MAX_SAFE_INTEGER);

        let tabletotal = 0;
        let tablemax = 0;
        let tablemin = Number.MAX_SAFE_INTEGER;

        rows.forEach((row, rowidx) => {

            row.forEach((cell, cellidx) => {

                coltotals[cellidx] = coltotals[cellidx] + cell;
                if (cell > colmaxs[cellidx]) { colmaxs[cellidx] = cell; }
                if (cell < colmins[cellidx]) { colmins[cellidx] = cell; }

                if (cellidx > 1 || (headings.length === 2 && cellidx > 0)) { // Exclude the key column. In two dimensional tables, also exclude the total column.

                    rowtotals[rowidx] = rowtotals[rowidx] + cell;
                    if (cell > rowmaxs[rowidx]) { rowmaxs[rowidx] = cell; }
                    if (cell < rowmins[rowidx]) { rowmins[rowidx] = cell; }

                    if (cell > tablemax) { tablemax = cell; }
                    if (cell < tablemin) { tablemin = cell; }

                    tabletotal = tabletotal + cell;

                }

            });

        });

        

        return {

            'headings': headings,
            'rows': rows,

            'rowtotals': rowtotals,
            'coltotals': coltotals,

            'rowmins': rowmins,
            'rowmaxs': rowmaxs,

            'colmins': colmins,
            'colmaxs': colmaxs,

            'tabletotal': tabletotal,
            'tablemin': tablemin,
            'tablemax': tablemax,

            'estotal' : total,

        };

    }

    const heatcol = (value) => {

        if (isNaN(value) || value === 0.0) { return 'rgba(0, 0, 255, 30%)'; }

        var h = (1.0 - value) * 240
        return 'hsla(' + h + ', 100%, 50%, 30%)';

    }

    const redcol = (value) => {

        if (isNaN(value) || value === 0.0) { return 'rgba(255, 255, 255, 30%)'; }

        var r = value * 255;
        return 'rgba(255, ' + (255 - r) + ', ' + (255 - r) + ', 30%)';

    }

    const renderTable = (tdata, propsfilters, filterkey, subaggkey, ancestortotals, propstotal) => {
              
        const cross = (tdata.headings.length === 2 ? false : true);

        const topts = {

            'coltotals': true, // Display column totals, calculated using the visible column values. These cannot be clicked through because they are not provided by elasticsearch.
            'rowtotals': true, // Display row totals, calculated using the elasticsearch subagg totals. These can be clicked through because they are provided by elasticsearch.
            'rowtotals_clickable': false, // Allow row totals to be clicked. You might not want them to be, to maintain consistency with column totals, which are never clickable.
            'rowtotals_calc': true, // Use row totals which are calculated using the visible column values, not the elasticsearch values.
            'maintotal_calc': true, // Use a main total which is calculated using the visible table values, not the elasticsearch value.

            'celltotals': true, // Display cell totals. You might want to omit these when generating heatmaps.
            'colpc': 'visual', // Display a column percentage, calculated using the visible column total.
            'rowpc': 'visual', // Display a row percentage, calculated using the visible row total.

            //'cellshade': 'heat', // Shade the cells like a heatmap.
            'cellshade': 'red', // Shade the cells red for the highest values.

            'shaderel': 'table' // Decide the intensity of the cell shading relative to the whole table.
            //'shaderel': 'row' // Decide the intensity of the cell shading relative to each row.
            //'shaderel': 'col' // Decide the intensity of the cell shading relative to each column.

        }

        const headings = tdata.headings.map((cell, headingidx) => {

            if (!topts.rowtotals && cross && headingidx === 1) { // If we are displaying a cross table and rowtotals are not required, do not display them

                return null;

            }

            if (cross && headingidx === 0) { return (<th key={ headingidx }></th>); }

            return (

                <th key={ headingidx }>

                    { pretty(cell, ['string', 'lookup_' + subaggkey]) }

                </th>

            );

        });

        const coltotals = tdata.headings.map((cell, headingidx) => {

            if (!topts.rowtotals && cross && headingidx === 1) { // If we are displaying a cross table and rowtotals are not required, do not display them

                return null;

            }

            if (headingidx === 0) { return (<th key={ headingidx }>Total</th>); }

            if (headingidx === 1) { return (

                <td key={ headingidx }>
                    { topts.maintotal_calc ? pretty(tdata.tabletotal) : pretty(tdata.estotal) }
                    <div className="text-muted" style={ { width: '8em', textAlign: 'right' } }>100%</div>
                </td>

            ); }

            //if (headingidx < 2) { return (<td></td>); }

            return (

                <td key={ headingidx }>
                    { pretty(tdata.coltotals[headingidx]) }

                    { topts.rowpc && topts.rowpc === 'visual' && (

                        <div className="text-muted">{ pretty(tdata.coltotals[headingidx] / tdata.coltotals[1], 'percent') }%</div>

                    )}

                </td>

            );

        });

        const rows = tdata.rows.map((row, rowidx) => {
               
            const cells = row.map((cell, cellidx) => {

                if (cellidx === 0) {

                    return (

                        <td
                        key={ cellidx }
                        className={ cross ? 'fw-bold' : '' }
                        >

                            { pretty(cell, ['string', 'lookup_' + filterkey]) }

                        </td>

                    )

                } else {

                    let filters = [];
                    if (propsfilters) {

                        filters = filters.concat(propsfilters); // We concat to our new array here so we do not alter the array handed down
                                                                // through the props.
                    }

                    // We do not need this clause since we are not including the subaggbucket totals at the top of the table at moment, because they are unreliable.
                    //if (rowidx > 0) {

                        filters.push(filterkey + '|' + tdata.rows[rowidx][0]);

                    //}

                    if (cellidx > 1) {

                        filters.push(subaggkey + '|' + tdata.headings[cellidx]);

                    }

                    //const esaggtotal = props.total;

                    //const establetotal = (ancestortotals.length === 0 && props.total ? props.total : ancestortotals.at(-1));
                    //const vitabletotal = tdata.tabletotal;
                    const vitablemax = tdata.tablemax;
                    const vitablemin = tdata.tablemin;

                    //const esrowtotal = row[1];
                    const virowtotal = tdata.rowtotals[rowidx];
                    const virowmax = tdata.rowmaxs[rowidx];
                    const virowmin = tdata.rowmins[rowidx]

                    //const escoltotal = 999; // Column totals are not available through elasticsearch
                    const vicoltotal = tdata.coltotals[cellidx];
                    const vicolmax = tdata.colmaxs[cellidx];
                    const vicolmin = tdata.colmins[cellidx];

                    let style = {};

                    if (topts.cellshade) {

                        if (cellidx > 1 || (!cross && cellidx > 0)) { // Only apply background color to cells which are not headings or totals

                            let intensity = 0;

                            if (topts.shaderel && topts.shaderel === 'table') { intensity = (cell - vitablemin) / (vitablemax -vitablemin); }
                            if (topts.shaderel && topts.shaderel === 'row') { intensity = (cell - virowmin) / (virowmax -virowmin); }
                            if (topts.shaderel && topts.shaderel === 'col') { intensity = (cell - vicolmin) / (vicolmax -vicolmin); }

                            if (topts.cellshade === 'heat') { style = { 'backgroundColor': heatcol(intensity) }; }
                            if (topts.cellshade === 'red') { style = { 'backgroundColor': redcol(intensity) }; }

                        }

                    }

                    if (!topts.rowtotals && cross && cellidx === 1) { // If we are displaying a cross table and rowtotals are not required, do not display them

                        return null;

                    }

                    const total_is_clickable = (!cross || !topts.rowtotals || topts.rowtotals_clickable || cellidx > 1);
                    //const total_is_clickable = false;

                    return (

                        <td
                            key={ cellidx }
                            style={ style }
                        >

                            <div className="text-nowrap">

                                { topts.celltotals && (

                                    <>

                                    { total_is_clickable && (

                                        <div
                                        style={ { 'width': '4rem', 'textAlign': 'left' } }
                                        className="btn btn-link text-decoration-none"
                                        data-filters={ JSON.stringify(filters) }
                                        onClick={ props.handleAddFilters }
                                        >
                                            { pretty(cell) }

                                        </div>

                                    ) }

                                    { !total_is_clickable && (

                                        <div
                                        style={ { 'width': '4rem', 'textAlign': 'left', 'display': 'inline-block' } }
                                        >
                                            { (topts.rowtotals_calc && cellidx === 1) ? pretty(tdata.rowtotals[rowidx]) : pretty(cell) }

                                        </div>

                                    ) }
                                    </>

                                ) }

                                { topts.colpc && topts.colpc === 'visual' && (

                                    <span
                                    className="text-muted"
                                    style={ { 'display': 'inline-block', 'width': '4rem', 'textAlign':'right' } }
                                    >
                                    { pretty(cell / vicoltotal, ['percent']) }%
                                    </span>

                                ) }

                            </div>

                            
                            { topts.rowpc && topts.rowpc === 'visual' && cellidx > 1 && (

                                <div className="text-muted">{ pretty(cell / virowtotal, ['percent']) }%</div>

                            ) }

                            {/*
                            <div className="text-nowrap">{ (cell - vitablemin) / (vitablemax - vitablemin) }</div>

                            <div className="text-nowrap">ea: { esaggtotal }</div>

                            <div className="text-nowrap">ett: { establetotal }</div>
                            <div className="text-nowrap">vtt: { vitabletotal }</div>
                            <div className="text-nowrap">vtx: { vitablemax }</div>
                            <div className="text-nowrap">vti: { vitablemin }</div>

                            <div className="text-nowrap">ert: { esrowtotal }</div>
                            <div className="text-nowrap">vrt: { virowtotal }</div>
                            <div className="text-nowrap">vtx: { virowmax }</div>
                            <div className="text-nowrap">vti: { virowmin }</div>

                            <div className="text-nowrap">vct: { vicoltotal }</div>
                            <div className="text-nowrap">vcx: { vicolmax }</div>
                            <div className="text-nowrap">vci: { vicolmin }</div>
                            */}

                        </td>

                    )

                }

            });

            return (<tr key={ rowidx }>{ cells }</tr>);

        });

        return (

            <table className='table'>
                <thead>
                    <tr>{ headings }</tr>
                </thead>
                <tbody>

                    { topts.coltotals && cross && (
                        <tr>{ coltotals }</tr>
                    ) }

                    { rows }

                </tbody>
            </table>

        )



    }

    const getDownloadUrl = (propsfilters) => {

        let url = `${process.env.REACT_APP_API_URL}/data/csv/agg/` + props.view;
        
        let qs = queryString.parse(location.search, { arrayFormat: "bracket" });

        if (qs.viz) { delete qs.viz; }
        if (qs.sort) { delete qs.sort; }

        if (propsfilters) {

            // rows -> series
            qs['series'] = qs['rows'];

            // columns -> rows
            qs['rows'] = qs['columns'];

            // delete columns
            if (qs.columns) { delete qs.columns; }

            // add filter
            let filters = [];
            filters = filters.concat(propsfilters);
            filters = [...new Set(filters)]; // Remove any duplicates
            
            qs['filters'] = filters;

            //console.log('********');
            //console.log(propsfilters);
            //console.log('********');

        }

        url = url + '?' + queryString.stringify(qs, { arrayFormat: "bracket" });

        return url;

    }

    const makeRechartsPieBarData = (esdata, filterkey) => {

        const rcdata = esdata.map((bucket, idx) => {

            let rcbucket = {};

            rcbucket['key'] = pretty(bucket['key'], ['string', 'lookup_' + filterkey]);
            rcbucket['Total'] = bucket['doc_count'];

            return rcbucket;
        })

        return rcdata;

    }

    let subaggkey;
    let subaggbuckets;

    if (
        aggtype === 'table-cross-6' ||
        aggtype === 'bar-stacked-6' ||
        aggtype === 'bar-stacked-normalised-6' ||
        aggtype === 'bar-grouped-6' ||
        aggtype === 'line-multi-6' ||
        aggtype === 'scatter-6'
    ) {

        [subaggkey, subaggbuckets] = findSubaggBuckets(props.agg.buckets);

    }

    const getmaxforbuckets = (buckets, maxc, depth) => {

        buckets.forEach((bucket, idx) => {

            if (depth > 0) { // We avoid the top level buckets because they make the scale too big

                if (bucket.doc_count > maxc) { maxc = bucket.doc_count; }

            }

            const saks = findSubaggkeys(bucket);

            saks.forEach((sak) => {

                maxc = getmaxforbuckets(bucket[sak]['buckets'], maxc, depth + 1);

            });

        });

        return maxc;

    }

    const getBucketsVisualTotal = (buckets) => {

        let bucket_visual_total = 0;
        buckets.forEach((bucket, idx) => { bucket_visual_total = bucket_visual_total + bucket.doc_count; });

        return bucket_visual_total;

    }


    if (MATCH_SCALE) {

        maxcount = getmaxforbuckets(props.agg.buckets, maxcount, 0);

    }
 
    let salegitems = []; // sub aggregation legend items
    let folded_salegitems = [];

    if (
        aggtype === 'table-cross-6' ||
        aggtype === 'bar-stacked-6' ||
        aggtype === 'bar-stacked-normalised-6' ||
        aggtype === 'bar-grouped-6' ||
        aggtype === 'line-multi-6' ||
        aggtype === 'scatter-6'
    ) {

        if (subaggbuckets) {

            if (Array.isArray(subaggbuckets)) {

                //let subaggbucket_visual_total = 0;
                //subaggbuckets.forEach((bucket, idx) => { subaggbucket_visual_total = subaggbucket_visual_total + bucket.doc_count; });

                const subaggbucket_visual_total = getBucketsVisualTotal(subaggbuckets);

                subaggbuckets.forEach((bucket, idx) => {
       
                    let filters = [];

                    if (props.filters) {

                        filters = filters.concat(props.filters); // We concat to our new array here so we do not alter the array handed down
                                                                 // through the props.
                    }

                    filters.push(subaggkey + '|' + bucket.key);

                    const buckethtml = (

                        <div
                        key={ idx }
                        className="text-decoration-none"
                        data-filters={ JSON.stringify(filters) }
                        onClick={ props.handleAddFilters }
                        >
                            <span className="btn btn-link agg-legend-label" title={ pretty(bucket.key, ['string', 'lookup_' + subaggkey]) }>
                                <span className="agg-legend-square me-2" style={ { backgroundColor: getColor(bucket.key, idx) } }></span>
                                { pretty(bucket.key, ['string', 'lookup_' + subaggkey]) }
                            </span>

                            {/*
                            // These totals are calculated by dumbly adding all the values in subaggs, in the function findSubaggBuckets().
                            // This is not reliable and produces a different total to that which will be displayed if the user clicks through to add the relevant filters.
                            */ }

                            <span className="agg-legend-count ms-2" title={ pretty(bucket.doc_count) }>
                                { pretty(bucket.doc_count) }
                            </span>
                            <span className="agg-legend-count ms-2 text-muted small" title={ pretty(bucket.doc_count / subaggbucket_visual_total, 'percent') + '%' }>
                                { pretty(bucket.doc_count / subaggbucket_visual_total, 'percent') + '%' }
                            </span>
                        </div>

                    );

                    if (idx < KEY_FOLD_IDX) {
                        salegitems = salegitems.concat(buckethtml);
                    } else {
                        folded_salegitems = folded_salegitems.concat(buckethtml);
                    }

                });

            }
        }

    }
 
    let legitems = []; // legend items
    let folded_legitems = [];
    let subaggs = [];
    
    if (props.agg.buckets) {

        if (Array.isArray(props.agg.buckets)) {
           
            //let bucket_visual_total = 0;
            //props.agg.buckets.forEach((bucket, idx) => { bucket_visual_total = bucket_visual_total + bucket.doc_count; });
            
            const bucket_visual_total = getBucketsVisualTotal(props.agg.buckets);

            props.agg.buckets.forEach((bucket, idx) => {
 
                let filters = [];

                if (props.filters) {

                    filters = filters.concat(props.filters); // We concat to our new array here so we do not alter the array handed down
                                                             // through the props.
                }

                filters.push(props.filterkey + '|' + bucket.key);

                if (
                    aggtype === '-9' ||
                    aggtype === '-6' ||
                    aggtype === 'table-cross-9' ||
                    aggtype === 'table-9' ||
                    aggtype === 'table-6' ||
                    aggtype === 'pie-9' ||
                    aggtype === 'pie-6' ||
                    aggtype === 'bar-9' ||
                    aggtype === 'bar-6' ||
                    aggtype === 'bar-stacked-9' ||
                    aggtype === 'bar-stacked-normalised-9' ||
                    aggtype === 'bar-grouped-9' ||
                    aggtype === 'line-9' ||
                    aggtype === 'line-6' ||
                    aggtype === 'line-multi-9' ||
                    aggtype === 'scatter-9'
                ) {

                    const subaggkeys = findSubaggkeys(bucket);

                    subaggs = subaggs.concat(subaggkeys.map((subaggkey) => (

                        <Agg
                        key={ bucket.key + '-' + subaggkey }
                        handleAddFilters={ props.handleAddFilters }
                        filters={ filters }
                        filterkey={ subaggkey }
                        ancestorkeys={ ancestorkeys.concat([ bucket.key ]) }
                        ancestortotals={ ancestortotals.concat([ bucket.doc_count ]) }
                        viz={ props.viz }
                        agg={ bucket[subaggkey] }
                        colorAllocations={ colorAllocations }
                        maxcount={ maxcount }
                        total={ props.total }
                        view={ props.view }
                        lookups={ props.lookups }
                        />

                    )));

                }

                if (aggtype === '-3' ||
                    aggtype === 'table-3' ||
                    aggtype === 'pie-3' ||
                    aggtype === 'bar-3' ||
                    aggtype === 'line-3'
                ) {
 
                    const buckethtml = (

                        <React.Fragment key={ idx }> {/* Need to use full Fragment syntax to be able to apply a key */}

                            <div
                            className="text-decoration-none"
                            data-filters={ JSON.stringify(filters) }
                            onClick={ props.handleAddFilters }
                            >

                                <span className="btn btn-link text-start agg-legend-label" title={ pretty(bucket.key, ['string', 'lookup_' + props.filterkey]) }>
                                    { aggtype !== '-3' && aggtype !== 'line-3' && (
                                        <span className="agg-legend-square me-2" style={ { backgroundColor: getColor(bucket.key, idx) } }></span>
                                    ) }
                                    { pretty(bucket.key, ['string', 'lookup_' + props.filterkey]) }
                                    <span className="agg-legend-count ms-1" title={ pretty(bucket.doc_count) }>
                                        { pretty(bucket.doc_count) }
                                    </span>
                                    <span className="agg-legend-count ms-2 text-muted small" title={ pretty(bucket.doc_count / bucket_visual_total, 'percent') + '%' }>
                                        { pretty(bucket.doc_count / bucket_visual_total, 'percent') + '%' }
                                    </span>
                                </span> 

                           </div>

                        </React.Fragment>

                    );

                    if (idx < KEY_FOLD_IDX) {
                        legitems = legitems.concat(buckethtml);
                    } else {
                        folded_legitems = folded_legitems.concat(buckethtml);
                    }

                }

            });

        }
    }


    const propfilters = props['filters'];
    let ancestor_filter = null;
    let ancestor_filterkey = null;

    if (propfilters) {
        ancestor_filter = propfilters[0];
        ancestor_filterkey = ancestor_filter.substr(0, ancestor_filter.indexOf('|'));
    }

    const title = ancestorkeys.map((ancestorkey, idx) => (

        <span key={ ancestorkey }>

            <span className="text-muted fw-bold fs-3 agg-title-separator"> / </span>

            <span
            className="text-decoration-none agg-title-section"
            data-filters={ JSON.stringify(props.filters.slice(0, idx + 1)) }
            onClick={ props.handleAddFilters }
            >
                { pretty(ancestorkey, ['string', 'lookup_' + ancestor_filterkey]) }
            </span>

            {/*

            { (idx < ancestorkeys.length - 1) && (

                <span className="text-muted fw-bold fs-3"> / </span>

            )}

            */}

        </span>

    ));

    const legend = (

        <>
            { legitems }
            { salegitems }

            { (folded_legitems.length > 0 || folded_salegitems.length > 0) && (

                <>
                <div className="collapse" id={ 'legendFold' + ancestorkeys.join('-') }>
                    { folded_legitems }
                    { folded_salegitems }
                </div>
                <div className="text-toggle text-muted mt-2" type="button" data-bs-toggle="collapse" data-bs-target={ '#legendFold' + ancestorkeys.join('-') } aria-expanded="false">
                    <span className="text-collapsed">
                        <i className="bi bi-caret-down-fill me-2"></i>
                        Show more
                    </span>
                    <span className="text-expanded">
                        <i className="bi bi-caret-up-fill me-2"></i>
                        Show fewer
                    </span>
                </div>
                </>

            ) }
        </>

    );

    //console.log('Agg redrew.');

    return (

        <>

            { (
                aggtype === '-9' ||
                aggtype === '-6' ||
                aggtype === 'table-cross-9' ||
                aggtype === 'table-9' ||
                aggtype === 'table-6' ||
                aggtype === 'pie-9' ||
                aggtype === 'pie-6' ||
                aggtype === 'bar-9' ||
                aggtype === 'bar-6' ||
                aggtype === 'bar-stacked-9' ||
                aggtype === 'bar-stacked-normalised-9' ||
                aggtype === 'bar-grouped-9' ||
                aggtype === 'line-9' ||
                aggtype === 'line-6' ||
                aggtype === 'line-multi-9' ||
                aggtype === 'scatter-9'
              ) && (

                <>
                    { subaggs }
                </>

            )}

            { aggtype === '-3' && (

                <div className="col-6 col-lg-3 mb-5">
                    <h4 className="agg-h4">{ title }</h4>
                    { ancestortotals.length > 0 && (

                        <>
                            <span className="agg-legend-label fw-bold">Total</span>
                            <span className="agg-legend-count ms-1" title={ pretty(ancestortotals.at(-1)) }>
                                { pretty(ancestortotals.at(-1)) }
                            </span>
                            <br/>
                        </>

                    ) }
                    { legend }
                </div>

            )}

            { aggtype === 'pie-3' && (

                <div className={ (ancestortotals.length > 0) ? pie3_multi_vizclass : pie3_solo_vizclass }>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="agg-h4">{ title }</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className={ (ancestortotals.length > 0) ? pie3_multi_chartclass : pie3_solo_chartclass }>
                            <div className={ (ancestortotals.length > 0) ? pie3_multi_rechartsclass : pie3_solo_rechartsclass  }>
                                <ResponsiveContainer width="110%" height="110%">
                                    <PieChart>
                                        <Pie data={ makeRechartsPieBarData(props.agg.buckets, props.filterkey) } nameKey="key" dataKey="Total" stroke="none">
                                            { props.agg.buckets.map((entry, idx) => 
                                                <Cell key={ idx } fill={ getColor(entry.key, idx) } />
                                            )}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className={ (ancestortotals.length > 0) ? pie3_multi_legendclass : pie3_solo_legendclass }>
                            { /*ancestortotals.length > 0 &&*/ (

                                <>
                                    <span className="agg-legend-label fw-bold">Total</span>
                                    <span className="agg-legend-count ms-1" title={ pretty(ancestortotals.at(-1)) }>
                                        {/* pretty(ancestortotals.at(-1)) */}
                                        { pretty(getBucketsVisualTotal(props.agg.buckets)) }
                                    </span>
                                </>

                            ) }
                            { legend }

                            <div className="mt-4">
                                <a className="text-muted text-decoration-none" target="_blank" rel="noreferrer" href={ getDownloadUrl(props.filters) }>
                                    <i className="bi bi-download"></i>
                                    <span className="ms-2 small">Download this data</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            )}

            { aggtype === 'table-3' && (

                <div className={ (ancestortotals.length > 0) ? table3_multi_vizclass : table3_solo_vizclass }>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="agg-h4">{ title }</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className={ 'col-12' }>

                            { renderTable(makeTabularData(props.agg.buckets, subaggbuckets, ancestortotals, props.total, props.filterkey), props.filters, props.filterkey, subaggkey, ancestortotals, props.total) }

                            <div className="text-end">

                                { ancestortotals.length > 0 && (

                                    <>
                                        <span className="fw-bold">Total </span>
                                        <span title={ pretty(ancestortotals.at(-1)) }>
                                            {/* pretty(ancestortotals.at(-1)) */}
                                            { pretty(getBucketsVisualTotal(props.agg.buckets)) }
                                        </span>
                                    </>

                                ) }

                                { ancestortotals.length === 0 && props.total && (

                                    <>
                                        <span className="fw-bold">Total </span>
                                        <span title={ props.total }>
                                            {/* pretty(props.total) */}
                                            { pretty(getBucketsVisualTotal(props.agg.buckets)) }
                                        </span>
                                    </>

                                ) }

                                <a className="ms-4 text-muted text-decoration-none" target="_blank" rel="noreferrer" href={ getDownloadUrl(props.filters) }>
                                    <i className="bi bi-download"></i>
                                    <span className="ms-2 small">Download this data</span>
                                </a>

                            </div>

                        </div>
                    </div>
                </div>

            )}

            { aggtype === 'bar-stacked-6' && (

                <div className={ (ancestortotals.length > 0) ? barstacked6_multi_vizclass : barstacked6_solo_vizclass }>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="agg-h4">{ title }</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className={ (ancestortotals.length > 0) ? barstacked6_multi_chartclass : barstacked6_solo_chartclass }>
                            <div className={ (ancestortotals.length > 0) ? barstacked6_multi_rechartsclass : barstacked6_solo_rechartsclass }>
                                <ResponsiveContainer width="100%" height="100%">
                                     <BarChart data={ makeRechartsMultilineData(props.agg.buckets, props.filterkey, subaggkey) }>
                                        <XAxis dataKey="key" />
                                        <YAxis type="number" domain={ [0, maxcount] } />
                                        <Tooltip />
                                        {/*<Legend />*/}
                                        { subaggbuckets.map((entry, idx) => 
                                            <Bar key={ idx } dataKey={ pretty(entry.key, ['string', 'lookup_' + subaggkey]) } stackId="a" fill={ getColor(entry.key, idx) } /> // Keep or omit the 'stackId' attribute to have stacked bars or little separate ones
                                        )}
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className={ (ancestortotals.length > 0) ? barstacked6_multi_legendclass : barstacked6_solo_legendclass }>
                            { ancestortotals.length > 0 && (

                                <>
                                    <span className="agg-legend-label fw-bold">Total</span>
                                    <span className="agg-legend-count ms-1" title={ pretty(ancestortotals.at(-1)) }>
                                        { pretty(ancestortotals.at(-1)) }
                                    </span>
                                </>

                            ) }
                            { legend }

                            <div className="mt-4">
                                <a className="text-muted text-decoration-none" target="_blank" rel="noreferrer" href={ getDownloadUrl(props.filters) }>
                                    <i className="bi bi-download"></i>
                                    <span className="ms-2 small">Download this data</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            )}

            { aggtype === 'bar-stacked-normalised-6' && (

                <div className={ (ancestortotals.length > 0) ? barstackednormalised6_multi_vizclass : barstackednormalised6_solo_vizclass }>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="agg-h4">{ title }</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className={ (ancestortotals.length > 0) ? barstackednormalised6_multi_chartclass : barstackednormalised6_solo_chartclass }>
                            <div className={ (ancestortotals.length > 0) ? barstackednormalised6_multi_rechartsclass : barstackednormalised6_solo_rechartsclass }>
                                <ResponsiveContainer width="100%" height="100%">
                                     <BarChart data={ makeRechartsMultilineData(props.agg.buckets, props.filterkey, subaggkey) } stackOffset="expand">
                                        <XAxis dataKey="key" />
                                        <YAxis type="number" domain={ [0, maxcount] } />
                                        <Tooltip />
                                        {/*<Legend />*/}
                                        { subaggbuckets.map((entry, idx) => 
                                            <Bar key={ idx } dataKey={ pretty(entry.key, ['string', 'lookup_' + subaggkey]) } stackId="a" fill={ getColor(entry.key, idx) } /> // Keep or omit the 'stackId' attribute to have stacked bars or little separate ones
                                        )}
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className={ (ancestortotals.length > 0) ? barstackednormalised6_multi_legendclass : barstackednormalised6_solo_legendclass }>
                            { ancestortotals.length > 0 && (

                                <>
                                    <span className="agg-legend-label fw-bold">Total</span>
                                    <span className="agg-legend-count ms-1" title={ pretty(ancestortotals.at(-1)) }>
                                        { pretty(ancestortotals.at(-1)) }
                                    </span>
                                </>

                            ) }

                            { legend }

                            <div className="mt-4">
                                <a className="text-muted text-decoration-none" target="_blank" rel="noreferrer" href={ getDownloadUrl(props.filters) }>
                                    <i className="bi bi-download"></i>
                                    <span className="ms-2 small">Download this data</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            )}

            { aggtype === 'table-cross-6' && (

                <div className={ (ancestortotals.length > 0) ? tablecross6_multi_vizclass : tablecross6_solo_vizclass }>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="agg-h4">{ title }</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">

                            { renderTable(makeTabularData(props.agg.buckets, subaggbuckets, ancestortotals, props.total, props.filterkey), props.filters, props.filterkey, subaggkey, ancestortotals, props.total) }

                        </div>
                        <div className="col-12 text-end">

                            { ancestortotals.length > 0 && (

                                <>
                                    {/*
                                    <span className="fw-bold">Total </span>
                                    <span title={ pretty(ancestortotals.at(-1)) }>
                                        { pretty(ancestortotals.at(-1)) }
                                    </span>
                                    */}
                                </>

                            ) }

                            { ancestortotals.length === 0 && props.total && (

                                <>
                                    {/*
                                    <span className="fw-bold">Total </span>
                                    <span title={ props.total }>
                                        { pretty(props.total) }
                                    </span>
                                    */}
                                </>

                            ) }

                            <a className="ms-4 text-muted text-decoration-none" target="_blank" rel="noreferrer" href={ getDownloadUrl(props.filters) }>
                                <i className="bi bi-download"></i>
                                <span className="ms-2 small">Download this data</span>
                            </a>

                        </div>
                    </div>
                </div>

            )}

            { aggtype === 'bar-grouped-6' && (

                <div className={ (ancestortotals.length > 0) ? bargrouped6_multi_vizclass : bargrouped6_solo_vizclass }>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="agg-h4">{ title }</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className={ (ancestortotals.length > 0) ? bargrouped6_multi_chartclass : bargrouped6_solo_chartclass }>
                            <div className={ (ancestortotals.length > 0) ? bargrouped6_multi_rechartsclass : bargrouped6_solo_rechartsclass }>
                                <ResponsiveContainer width="100%" height="100%">
                                     <BarChart data={ makeRechartsMultilineData(props.agg.buckets, props.filterkey, subaggkey) }>
                                        <XAxis dataKey="key" />
                                        <YAxis type="number" domain={ [0, maxcount] } />
                                        <Tooltip />
                                        {/*<Legend />*/}
                                        { subaggbuckets.map((entry, idx) => 
                                            <Bar key={ idx } dataKey={ pretty(entry.key, ['string', 'lookup_' + subaggkey]) } fill={ getColor(entry.key, idx) } /> // Keep or omit the 'stackId' attribute to have stacked bars or little separate ones
                                        )}
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className={ (ancestortotals.length > 0) ? bargrouped6_multi_legendclass : bargrouped6_solo_legendclass }>
                            { ancestortotals.length > 0 && (

                                <>
                                    <span className="agg-legend-label fw-bold">Total</span>
                                    <span className="agg-legend-count ms-1" title={ pretty(ancestortotals.at(-1)) }>
                                        { pretty(ancestortotals.at(-1)) }
                                    </span>
                                </>

                            ) }

                            { legend }

                            <div className="mt-4">
                                <a className="text-muted text-decoration-none" target="_blank" rel="noreferrer" href={ getDownloadUrl(props.filters) }>
                                    <i className="bi bi-download"></i>
                                    <span className="ms-2 small">Download this data</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            )}

            { aggtype === 'bar-3' && (

                <div className={ (ancestortotals.length > 0) ? bar3_multi_vizclass : bar3_solo_vizclass }>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="agg-h4">{ title }</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className={ (ancestortotals.length > 0) ? bar3_multi_chartclass : bar3_solo_chartclass }>
                            <div className={ (ancestortotals.length > 0) ? bar3_multi_rechartsclass : bar3_solo_rechartsclass }>
                                <ResponsiveContainer width="100%" height="100%">
                                 <BarChart data={ makeRechartsPieBarData(props.agg.buckets, props.filterkey) }>
                                    <XAxis dataKey="key" />
                                    <YAxis type="number" domain={ [0, maxcount] } />
                                    <Tooltip />
                                    {/*<Legend />*/}
                                    <Bar dataKey="Total">
                                    { props.agg.buckets.map((entry, idx) => 
                                        <Cell key={ idx } fill={ getColor(entry.key, idx) } />
                                    )}
                                    </Bar>
                                </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className={ (ancestortotals.length > 0) ? bar3_multi_legendclass : bar3_solo_legendclass }>
                            { /*ancestortotals.length > 0 &&*/ (

                                <>
                                    <span className="agg-legend-label fw-bold">Total</span>
                                    <span className="agg-legend-count ms-1" title={ pretty(ancestortotals.at(-1)) }>
                                        {/* pretty(ancestortotals.at(-1)) */}
                                        { pretty(getBucketsVisualTotal(props.agg.buckets)) }
                                    </span>
                                </>

                            ) }
                            { legend }

                            <div className="mt-4">
                                <a className="text-muted text-decoration-none" target="_blank" rel="noreferrer" href={ getDownloadUrl(props.filters) }>
                                    <i className="bi bi-download"></i>
                                    <span className="ms-2 small">Download this data</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            )}

            { aggtype === 'line-multi-6' && (

                <div className={ (ancestortotals.length > 0) ? linemulti6_multi_vizclass : linemulti6_solo_vizclass }>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="agg-h4">{ title }</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className={ (ancestortotals.length > 0) ? linemulti6_multi_chartclass : linemulti6_solo_chartclass }>
                            <div className={ (ancestortotals.length > 0) ? linemulti6_multi_rechartsclass : linemulti6_solo_rechartsclass }>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={ makeRechartsMultilineData(props.agg.buckets, props.filterkey, subaggkey) }>
                                        <XAxis dataKey="key" />
                                        <YAxis />
                                        <Tooltip />
                                        {/*<Legend />*/}
                                        { subaggbuckets.map((entry, idx) => 
                                            <Line key={ idx } type="monotone" dataKey={ pretty(entry.key, ['string', 'lookup_' + subaggkey]) } stroke={ getColor(entry.key, idx) } dot={ false } />
                                        )}
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className={ (ancestortotals.length > 0) ? linemulti6_multi_legendclass : linemulti6_solo_legendclass }>
                            { ancestortotals.length > 0 && (

                                <>
                                    <span className="agg-legend-label fw-bold">Total</span>
                                    <span className="agg-legend-count ms-1" title={ pretty(ancestortotals.at(-1)) }>
                                        { pretty(ancestortotals.at(-1)) }
                                    </span>
                                </>

                            ) }

                            { legend }

                            <div className="mt-4">
                                <a className="text-muted text-decoration-none" target="_blank" rel="noreferrer" href={ getDownloadUrl(props.filters) }>
                                    <i className="bi bi-download"></i>
                                    <span className="ms-2 small">Download this data</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            )}

            { aggtype === 'scatter-6' && (

                <div className={ (ancestortotals.length > 0) ? linemulti6_multi_vizclass : linemulti6_solo_vizclass }>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="agg-h4">{ title }</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className={ (ancestortotals.length > 0) ? linemulti6_multi_chartclass : linemulti6_solo_chartclass }>
                            <div className={ (ancestortotals.length > 0) ? linemulti6_multi_rechartsclass : linemulti6_solo_rechartsclass }>
                                <ResponsiveContainer width="100%" height="100%">
                                    <ScatterChart data={ makeRechartsScatterData(props.agg.buckets, props.filterkey, subaggkey) }>
                                        {/*<CartesianGrid />*/}
                                        <XAxis type="number" dataKey="year" name="year" domain={ [1670, 1920] }/>
                                        <YAxis type="number" dataKey="lenclass" name="lenclass" domain={ [0, 2000] }/>
                                        <ZAxis type="number" dataKey="doc_count" name="doc_count" range={ [10, 100] } domain={ [1, 100] }/>
                                        {/*<ZAxis range={[30, 31]} />*/}
                                        {/*<Tooltip cursor={{ strokeDasharray: '3 3' }} />*/}
                                        <Scatter data={ makeRechartsScatterData(props.agg.buckets, props.filterkey, subaggkey) } fill="#8884d8" />
                                        {/*
                                        <XAxis dataKey="key" />
                                        <YAxis />
                                        <Tooltip />
                                        <Scatter name="A school" data={ makeRechartsScatterData(props.agg.buckets, props.filterkey, subaggkey) } fill="#8884d8" />
                                        */}
                                        {/*<Legend />*/}
                                        {/* subaggbuckets.map((entry, idx) => 
                                            <Line key={ idx } type="monotone" dataKey={ pretty(entry.key, ['string', 'lookup_' + subaggkey]) } stroke={ getColor(entry.key, idx) } dot={ false } />
                                        )*/}
                                    </ScatterChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className={ (ancestortotals.length > 0) ? linemulti6_multi_legendclass : linemulti6_solo_legendclass }>
                            { ancestortotals.length > 0 && (

                                <>
                                    <span className="agg-legend-label fw-bold">Total</span>
                                    <span className="agg-legend-count ms-1" title={ pretty(ancestortotals.at(-1)) }>
                                        { pretty(ancestortotals.at(-1)) }
                                    </span>
                                </>

                            ) }
                            { legend }
                        </div>
                    </div>
                </div>

            )}

            { aggtype === 'line-3' && (

                <div className={ (ancestortotals.length > 0) ? line3_multi_vizclass : line3_solo_vizclass }>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="agg-h4">{ title }</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className={ (ancestortotals.length > 0) ? line3_multi_chartclass : line3_solo_chartclass }>
                            <div className={ (ancestortotals.length > 0) ? line3_multi_rechartsclass : line3_solo_rechartsclass }>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={ makeRechartsPieBarData(props.agg.buckets, props.filterkey) }>
                                        <XAxis dataKey="key" />
                                        <YAxis />
                                        <Tooltip />
                                        {/*<Legend />*/}
                                        <Line type="monotone" dataKey="Total" stroke={ COLORS[0 % COLORS.length] } dot={ false } />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className={ (ancestortotals.length > 0) ? line3_multi_legendclass : line3_solo_legendclass }>
                            { /*ancestortotals.length > 0 &&*/ (

                                <>
                                    <span className="agg-legend-label fw-bold">Total</span>
                                    <span className="agg-legend-count ms-1" title={ pretty(ancestortotals.at(-1)) }>
                                        { /*pretty(ancestortotals.at(-1))*/ }
                                        { pretty(getBucketsVisualTotal(props.agg.buckets)) }
                                    </span>
                                </>

                            ) }
                            { legend }

                            <div className="mt-4">
                                <a className="text-muted text-decoration-none" target="_blank" rel="noreferrer" href={ getDownloadUrl(props.filters) }>
                                    <i className="bi bi-download"></i>
                                    <span className="ms-2 small">Download this data</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            )}

        </>

    );

};

export default Agg;

