
const Error = (props) => {

    return (

        <div className="w-100 text-center">
            <i className="display-4 bi bi-bug spin-slow"></i>
            <p>{ props.error.toString() }</p>
        </div>

    );

};

export default Error;
