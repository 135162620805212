
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

const useForm = () => {

    const location = useLocation();
    let history = useHistory();

    const updateURL = (vals) => {

        let qs = queryString.parse(location.search, { arrayFormat: "bracket" });
        let newvals = {...qs, ...vals};

        for(var k in newvals) { if(!newvals[k]) delete newvals[k]; } // No filter() for objects in js yet

        const stringified = queryString.stringify(newvals, { arrayFormat: "bracket" });
        history.push({ pathname: location.pathname, search: stringified, hash: 'results' });

    };

    const handleAddFilters = (event) => {

        let vals = queryString.parse(location.search, { arrayFormat: "bracket" });

        let filters = vals['filters'] || [];

        const newfilters = JSON.parse(event.currentTarget.dataset.filters);
        filters = filters.concat(newfilters);

        filters = [...new Set(filters)]; // Remove any duplicates

        vals['filters'] = filters;
        vals['from'] = 0;

        updateURL(vals);
    };

    const handleRemoveFilter = (event) => {

        let vals = queryString.parse(location.search, { arrayFormat: "bracket" });

        let filters = vals['filters'] || [];

        filters.splice(filters.indexOf(event.currentTarget.dataset.filter), 1);

        vals['filters'] = filters;
        vals['from'] = 0;

        updateURL(vals);
    };

    return [ handleAddFilters, handleRemoveFilter ];
    
};

export default useForm;
