
const Field = (props) => {
    
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <select className="form-select" name="countby" id="field_countby" value={ values['countby'] || '' } onChange={ handleChange }>
                <option value="">&lt; Decide what to count by automatically &gt;</option>
                <option value="defendant">Count by defendant</option>
                <option value="trial">Count by trial</option>
                <option value="offence">Count by offence</option>
                <option value="victim">Count by victim</option>
                <option value="verdict">Count by verdict</option>
                <option value="punishment">Count by punishment</option>
            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-countby" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
