
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const useViz = (defaultviz) => {

    const location = useLocation();
    let history = useHistory();

    const [viz, setViz] = useState(defaultviz);

    useEffect(() => {

        const qs = queryString.parse(location.search, { arrayFormat: "bracket" });

        if (qs['viz']) {

            setViz(qs['viz']);

        } else if (qs['initviz']) {

            setViz(qs['initviz']);

        } else {

            //if (qs['rows']) {

            //    setViz('table-cross'); // If two statistics categories are selected, make a cross-tabulation table the default.

            //} else {

                setViz(defaultviz);

            //}

        }

    }, [ location.search, defaultviz ]);

    const handleChangeViz = (event) => {

        //if (location.hash === '#results') {

            const vals = { 'viz': event.target.value, 'initviz': event.target.value };
            const qs = queryString.parse(location.search, { arrayFormat: "bracket" });
            const newvals = {...qs, ...vals};

            const stringified = queryString.stringify(newvals, { arrayFormat: "bracket" });
            history.push({ pathname: location.pathname, search: stringified, hash: location.hash });

        //} else {

        //    setViz(event.target.value)

        //}

    };

    return [ viz, handleChangeViz ];
    
};

export default useViz;
