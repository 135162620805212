
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const useStagedSearch = () => {

    const location = useLocation();
    let history = useHistory();

    //const { hash } = useLocation();

    const [ showResults, setShowResults ] = useState(location.hash === '#results');

    useEffect(() => {

        // Ensures that we set the page state variable to the correct value when the address bar URL changes.
        // This is especially required for when the user clicks 'back'.
        //let qs = queryString.parse(location.search, { arrayFormat: "bracket" });
        //setPage(Math.ceil((qs['from'] || 0) / pagesize) + 1)

        setShowResults(location.hash === '#results');


    }, [ location.hash ]);

    const handleEditQueryButton = (event) => {

        event.preventDefault();

        //const vals = { 'from': nfrom };
        let qs = queryString.parse(location.search, { arrayFormat: "bracket" });
        delete qs.from;
        delete qs.viz;

        const stringified = queryString.stringify(qs, { arrayFormat: "bracket" });

        history.push({ pathname: location.pathname, search: stringified, hash: '' });

    }

    return [ showResults, handleEditQueryButton ];
    
};

export default useStagedSearch;
