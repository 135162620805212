
const Field = (props) => {
    
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <input type="search" className="form-control" name="crime_location" id="field_crime_location" placeholder={ 'Search where offences took place (by keyword) ...' } value={ values['crime_location'] || '' } onChange={ handleChange } />
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-crime_location" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
