
import { BrowserRouter as Router } from "react-router-dom";

import Navbar from "./Navbar";
import OBRouter from "./OBRouter";
import Footer from "./Footer";

const Base = () => {
    
    return (

        <Router basename={process.env.REACT_APP_BASENAME}>

            <Navbar/>

            <OBRouter/>

            <Footer/>

        </Router>

    );

};

export default Base;
