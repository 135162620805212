
import { Link, useLocation } from "react-router-dom";

import parse from 'html-react-parser';

import queryString from "query-string";

const Hit = (props) => {

    let highlight = '';

    if (props.hit.highlight) {

        for (let key of Object.keys(props.hit.highlight)) {

            highlight = highlight + props.hit.highlight[key].map((hil) => {

                return hil + ' ';

            }) + ' ';

        }

    }
    
    let text_param_string = '';

    const location = useLocation();

    const qs = queryString.parse(location.search, { arrayFormat: "bracket" });

    if (qs['text']) { text_param_string = '?text=' + qs['text']; }
    else if (qs['surname']) { text_param_string = '?text=' + qs['surname']; }

    const url = '/record/' + (

        (
            props.hit._source.collection === 'person' ||
            props.hit._source.collection === 'defendant' ||
            props.hit._source.collection === 'offence' ||
            props.hit._source.collection === 'victim' ||
            props.hit._source.collection === 'punishment' ||
            props.hit._source.collection === 'verdict'
        ) ?

        props.hit._source.div1_idkey + text_param_string : props.hit._source.idkey + text_param_string);

    return (
        
        <div className="col-12">

            <div className="row">

                <div className={ 

                    ( props.hit._source.collection === 'assocrec' ? 'd-none' : 'col-4 col-xxl-3 p-0' )

                }>

                    { props.hit._source.images && props.hit._source.images.length > 0 && (

                        <Link to={ url }>

                            <div className="hit-image-holder border hit-image-shadow me-1 me-sm-2 me-md-3 me-lg-4 me-xl-5">
                                <img className="hit-image img-fluid" src={ props.hit._source.images[0] } alt="Associated with this hit"/>
                            </div>

                        </Link>

                    ) }

                </div>

                <div className={

                    ( props.hit._source.collection === 'assocrec' ? 'col-12' : 'col-8 col-xxl-9' ) 

                }>

                    <Link to={ url } className="text-decoration-none text-black">

                        <h3 className="hit-title mt-0">
                            { props.idx }. { props.hit._source.title }
                        </h3>

                    </Link>

                    <div className="hit-text">

                        <>

                            { props.hit.highlight && props.hit._source.collection !== 'assocrec' && (

                                parse(highlight)

                            ) }

                            
                            { !props.hit.highlight && props.hit._source.text && props.hit._source.collection !== 'assocrec' && (

                                parse(props.hit._source.text)

                            ) }

                            { props.hit._source.collection === 'assocrec' && (

                                <> 

                                { (props.hit._source.trial.length > 0) && ( <span><span className="assocrec-field-label">Trial reference number:</span> { props.hit._source.trial }.</span> ) }

                                { (props.hit._source.surname.length > 0) && (

                                    <span>
                                        <span className="assocrec-field-label"> Name and Offence: </span>
                                        { props.hit._source.given_name } { props.hit._source.surname }. { props.hit._source.offence_description }

                                    </span>
                                    
                                ) }

                                { (props.hit._source.trial.doctype > 0) && (

                                    <span>
                                        <span className="assocrec-field-label">Document Type and Location: </span>
                                        { props.hit._source.doctype }. { props.hit._source.libarch }
                                    </span>

                                ) }

                                { (props.hit._source.description.length > 0) && ( <span> <span className="assocrec-field-label">Description:</span> { props.hit._source.description }.</span> ) }

                                { (props.hit._source.comments.length > 0) && ( <span> <span className="assocrec-field-label">Comments:</span> { props.hit._source.comments }.</span> ) }

                                </>

                            ) }


                        </>

                    </div>

                </div>
                    
            </div>

            { !props.last && (

                <hr className="hit-hr"/>

            ) }

        </div>

    );

};

export default Hit;
