
import { useState, useEffect } from "react";

import axios from "axios";

import { useLocation } from "react-router-dom";

const useResults = (apiPath, suffix, initquery, ignoreurl) => {

    const location = useLocation();

    const [results, setResults] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState(false);

    useEffect(() => {

        //if (location.search.length > 1 || initquery) {
        if (location.hash === '#results' || initquery || ignoreurl) {

            //console.log('useResults useEffect ran');

            // I know all this suffix stuff looks disgraceful.
            // The problem is that you can only pass a primitive (eg a string, not an object) into useEffect as a dependency
            // without triggering an infinite loop.

            let qsuffix;

            if (!suffix) {

                qsuffix = '';

            } else {

                if (location.search.length > 0 && !ignoreurl) { qsuffix = '&' + suffix; } else { qsuffix = '?' + suffix; }

            }

            setIsLoading(true);

            const fetchData = async () => {

                let real_api_path = apiPath;

                if (typeof apiPath === 'function') {

                    real_api_path = apiPath(location.search);

                }

                try {

                    const realurl = ( ignoreurl === true ? `${ real_api_path }${ qsuffix }` : `${ real_api_path }${ location.search }${ qsuffix }`);

                    const resp = await axios.get(realurl, {
                        //withCredentials: true,
                        withCredentials: false,
                    });

                    const data = await resp?.data;

                    setResults(data);
                    setError(false);
                    setIsLoading(false);

                    //const results = document.getElementById('results');
                    //if (results) {
                    //    results.scrollIntoView({ behavior: 'smooth' });
                    //}

                } catch (error) {

                    console.log(error);
                    setError(error);
                    setIsLoading(false);

                }
            };

            fetchData();

        }

    }, [ apiPath, location.search, suffix, initquery, location.hash, ignoreurl ]);

    return [ results, isLoading, error ];

}

export default useResults;
