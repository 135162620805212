
const FieldDate = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <div className="col-12">
                <div className="input-group input-date-top">
                    <span className="input-group-text bg-white text-muted input-date-label">From</span>
                    <select className="form-select" name="month_gte" id="field_month_gte" value={ values['month_gte'] || '' } onChange={ handleChange }>
                        <option value="">&lt; Any { pretty('month') } &gt;</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    <select className="form-select" name="year_gte" id="field_year_gte" value={ values['year_gte'] || '' } onChange={ handleChange }>
                        <option value="">&lt; Any { pretty('year') } &gt;</option>
                        <option value="1676">{ pretty('1676') }</option>
                        <option value="1677">{ pretty('1677') }</option>
                        <option value="1678">{ pretty('1678') }</option>
                        <option value="1679">{ pretty('1679') }</option>
                        <option value="1680">{ pretty('1680') }</option>
                        <option value="1681">{ pretty('1681') }</option>
                        <option value="1682">{ pretty('1682') }</option>
                        <option value="1683">{ pretty('1683') }</option>
                        <option value="1684">{ pretty('1684') }</option>
                        <option value="1685">{ pretty('1685') }</option>
                        <option value="1686">{ pretty('1686') }</option>
                        <option value="1687">{ pretty('1687') }</option>
                        <option value="1688">{ pretty('1688') }</option>
                        <option value="1689">{ pretty('1689') }</option>
                        <option value="1690">{ pretty('1690') }</option>
                        <option value="1691">{ pretty('1691') }</option>
                        <option value="1692">{ pretty('1692') }</option>
                        <option value="1693">{ pretty('1693') }</option>
                        <option value="1694">{ pretty('1694') }</option>
                        <option value="1695">{ pretty('1695') }</option>
                        <option value="1696">{ pretty('1696') }</option>
                        <option value="1697">{ pretty('1697') }</option>
                        <option value="1698">{ pretty('1698') }</option>
                        <option value="1699">{ pretty('1699') }</option>
                        <option value="1700">{ pretty('1700') }</option>
                        <option value="1702">{ pretty('1702') }</option>
                        <option value="1703">{ pretty('1703') }</option>
                        <option value="1704">{ pretty('1704') }</option>
                        <option value="1706">{ pretty('1706') }</option>
                        <option value="1707">{ pretty('1707') }</option>
                        <option value="1708">{ pretty('1708') }</option>
                        <option value="1709">{ pretty('1709') }</option>
                        <option value="1710">{ pretty('1710') }</option>
                        <option value="1711">{ pretty('1711') }</option>
                        <option value="1712">{ pretty('1712') }</option>
                        <option value="1713">{ pretty('1713') }</option>
                        <option value="1714">{ pretty('1714') }</option>
                        <option value="1715">{ pretty('1715') }</option>
                        <option value="1716">{ pretty('1716') }</option>
                        <option value="1717">{ pretty('1717') }</option>
                        <option value="1718">{ pretty('1718') }</option>
                        <option value="1719">{ pretty('1719') }</option>
                        <option value="1720">{ pretty('1720') }</option>
                        <option value="1721">{ pretty('1721') }</option>
                        <option value="1722">{ pretty('1722') }</option>
                        <option value="1723">{ pretty('1723') }</option>
                        <option value="1724">{ pretty('1724') }</option>
                        <option value="1725">{ pretty('1725') }</option>
                        <option value="1726">{ pretty('1726') }</option>
                        <option value="1727">{ pretty('1727') }</option>
                        <option value="1728">{ pretty('1728') }</option>
                        <option value="1729">{ pretty('1729') }</option>
                        <option value="1730">{ pretty('1730') }</option>
                        <option value="1731">{ pretty('1731') }</option>
                        <option value="1732">{ pretty('1732') }</option>
                        <option value="1733">{ pretty('1733') }</option>
                        <option value="1734">{ pretty('1734') }</option>
                        <option value="1735">{ pretty('1735') }</option>
                        <option value="1736">{ pretty('1736') }</option>
                        <option value="1737">{ pretty('1737') }</option>
                        <option value="1738">{ pretty('1738') }</option>
                        <option value="1739">{ pretty('1739') }</option>
                        <option value="1740">{ pretty('1740') }</option>
                        <option value="1741">{ pretty('1741') }</option>
                        <option value="1742">{ pretty('1742') }</option>
                        <option value="1743">{ pretty('1743') }</option>
                        <option value="1744">{ pretty('1744') }</option>
                        <option value="1745">{ pretty('1745') }</option>
                        <option value="1746">{ pretty('1746') }</option>
                        <option value="1747">{ pretty('1747') }</option>
                        <option value="1748">{ pretty('1748') }</option>
                        <option value="1749">{ pretty('1749') }</option>
                        <option value="1750">{ pretty('1750') }</option>
                        <option value="1751">{ pretty('1751') }</option>
                        <option value="1752">{ pretty('1752') }</option>
                        <option value="1753">{ pretty('1753') }</option>
                        <option value="1754">{ pretty('1754') }</option>
                        <option value="1755">{ pretty('1755') }</option>
                        <option value="1756">{ pretty('1756') }</option>
                        <option value="1757">{ pretty('1757') }</option>
                        <option value="1758">{ pretty('1758') }</option>
                        <option value="1759">{ pretty('1759') }</option>
                        <option value="1760">{ pretty('1760') }</option>
                        <option value="1761">{ pretty('1761') }</option>
                        <option value="1762">{ pretty('1762') }</option>
                        <option value="1763">{ pretty('1763') }</option>
                        <option value="1764">{ pretty('1764') }</option>
                        <option value="1765">{ pretty('1765') }</option>
                        <option value="1766">{ pretty('1766') }</option>
                        <option value="1767">{ pretty('1767') }</option>
                        <option value="1768">{ pretty('1768') }</option>
                        <option value="1769">{ pretty('1769') }</option>
                        <option value="1770">{ pretty('1770') }</option>
                        <option value="1771">{ pretty('1771') }</option>
                        <option value="1772">{ pretty('1772') }</option>
                    </select>
                    <button type="button" className="btn btn-outline-input-group btn-light bg-white text-muted input-date-help" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-date" onClick={ handleChangeHelpslug }>
                        <span className="material-symbols-sharp">help_outline</span>
                    </button>
                </div>
            </div>
            {/*
            <div className="input-date-dash">
                <i className="bi bi-dash-lg"></i>
            </div>
            */}
            <div className="col-12">
                <div className="input-group input-date-bottom">
                    <span className="input-group-text bg-white text-muted input-date-label">To</span>
                    <select className="form-select" name="month_lte" id="field_month_lte" value={ values['month_lte'] || '' } onChange={ handleChange }>
                        <option value="">&lt; Any { pretty('month') } &gt;</option>
                        <option value="12">December</option>
                        <option value="11">November</option>
                        <option value="10">October</option>
                        <option value="9">September</option>
                        <option value="8">August</option>
                        <option value="7">July</option>
                        <option value="6">June</option>
                        <option value="5">May</option>
                        <option value="4">April</option>
                        <option value="3">March</option>
                        <option value="2">February</option>
                        <option value="1">January</option>
                    </select>
                    <select className="form-select" name="year_lte" id="field_year_lte" value={ values['year_lte'] || '' } onChange={ handleChange }>
                        <option value="">&lt; Any { pretty('year') } &gt;</option>
                        <option value="1772">{ pretty('1772') }</option>
                        <option value="1771">{ pretty('1771') }</option>
                        <option value="1770">{ pretty('1770') }</option>
                        <option value="1769">{ pretty('1769') }</option>
                        <option value="1768">{ pretty('1768') }</option>
                        <option value="1767">{ pretty('1767') }</option>
                        <option value="1766">{ pretty('1766') }</option>
                        <option value="1765">{ pretty('1765') }</option>
                        <option value="1764">{ pretty('1764') }</option>
                        <option value="1763">{ pretty('1763') }</option>
                        <option value="1762">{ pretty('1762') }</option>
                        <option value="1761">{ pretty('1761') }</option>
                        <option value="1760">{ pretty('1760') }</option>
                        <option value="1759">{ pretty('1759') }</option>
                        <option value="1758">{ pretty('1758') }</option>
                        <option value="1757">{ pretty('1757') }</option>
                        <option value="1756">{ pretty('1756') }</option>
                        <option value="1755">{ pretty('1755') }</option>
                        <option value="1754">{ pretty('1754') }</option>
                        <option value="1753">{ pretty('1753') }</option>
                        <option value="1752">{ pretty('1752') }</option>
                        <option value="1751">{ pretty('1751') }</option>
                        <option value="1750">{ pretty('1750') }</option>
                        <option value="1749">{ pretty('1749') }</option>
                        <option value="1748">{ pretty('1748') }</option>
                        <option value="1747">{ pretty('1747') }</option>
                        <option value="1746">{ pretty('1746') }</option>
                        <option value="1745">{ pretty('1745') }</option>
                        <option value="1744">{ pretty('1744') }</option>
                        <option value="1743">{ pretty('1743') }</option>
                        <option value="1742">{ pretty('1742') }</option>
                        <option value="1741">{ pretty('1741') }</option>
                        <option value="1740">{ pretty('1740') }</option>
                        <option value="1739">{ pretty('1739') }</option>
                        <option value="1738">{ pretty('1738') }</option>
                        <option value="1737">{ pretty('1737') }</option>
                        <option value="1736">{ pretty('1736') }</option>
                        <option value="1735">{ pretty('1735') }</option>
                        <option value="1734">{ pretty('1734') }</option>
                        <option value="1733">{ pretty('1733') }</option>
                        <option value="1732">{ pretty('1732') }</option>
                        <option value="1731">{ pretty('1731') }</option>
                        <option value="1730">{ pretty('1730') }</option>
                        <option value="1729">{ pretty('1729') }</option>
                        <option value="1728">{ pretty('1728') }</option>
                        <option value="1727">{ pretty('1727') }</option>
                        <option value="1726">{ pretty('1726') }</option>
                        <option value="1725">{ pretty('1725') }</option>
                        <option value="1724">{ pretty('1724') }</option>
                        <option value="1723">{ pretty('1723') }</option>
                        <option value="1722">{ pretty('1722') }</option>
                        <option value="1721">{ pretty('1721') }</option>
                        <option value="1720">{ pretty('1720') }</option>
                        <option value="1719">{ pretty('1719') }</option>
                        <option value="1718">{ pretty('1718') }</option>
                        <option value="1717">{ pretty('1717') }</option>
                        <option value="1716">{ pretty('1716') }</option>
                        <option value="1715">{ pretty('1715') }</option>
                        <option value="1714">{ pretty('1714') }</option>
                        <option value="1713">{ pretty('1713') }</option>
                        <option value="1712">{ pretty('1712') }</option>
                        <option value="1711">{ pretty('1711') }</option>
                        <option value="1710">{ pretty('1710') }</option>
                        <option value="1709">{ pretty('1709') }</option>
                        <option value="1708">{ pretty('1708') }</option>
                        <option value="1707">{ pretty('1707') }</option>
                        <option value="1706">{ pretty('1706') }</option>
                        <option value="1704">{ pretty('1704') }</option>
                        <option value="1703">{ pretty('1703') }</option>
                        <option value="1702">{ pretty('1702') }</option>
                        <option value="1700">{ pretty('1700') }</option>
                        <option value="1699">{ pretty('1699') }</option>
                        <option value="1698">{ pretty('1698') }</option>
                        <option value="1697">{ pretty('1697') }</option>
                        <option value="1696">{ pretty('1696') }</option>
                        <option value="1695">{ pretty('1695') }</option>
                        <option value="1694">{ pretty('1694') }</option>
                        <option value="1693">{ pretty('1693') }</option>
                        <option value="1692">{ pretty('1692') }</option>
                        <option value="1691">{ pretty('1691') }</option>
                        <option value="1690">{ pretty('1690') }</option>
                        <option value="1689">{ pretty('1689') }</option>
                        <option value="1688">{ pretty('1688') }</option>
                        <option value="1687">{ pretty('1687') }</option>
                        <option value="1686">{ pretty('1686') }</option>
                        <option value="1685">{ pretty('1685') }</option>
                        <option value="1684">{ pretty('1684') }</option>
                        <option value="1683">{ pretty('1683') }</option>
                        <option value="1682">{ pretty('1682') }</option>
                        <option value="1681">{ pretty('1681') }</option>
                        <option value="1680">{ pretty('1680') }</option>
                        <option value="1679">{ pretty('1679') }</option>
                        <option value="1678">{ pretty('1678') }</option>
                        <option value="1677">{ pretty('1677') }</option>
                        <option value="1676">{ pretty('1676') }</option>
                    </select>
                    <button type="button" className="btn btn-outline-input-group btn-light bg-white text-muted input-date-help" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-date" onClick={ handleChangeHelpslug }>
                        { /*<span className="material-symbols-sharp">help_outline</span>*/ }
                    </button>
                </div>
            </div>
        </>

    );

};

export default FieldDate;
