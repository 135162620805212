
const Field = (props) => {
    
    const pretty = props.pretty;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <select className="form-select" name="victim_gender" id="field_victim_gender" value={ values['victim_gender'] || '' } onChange={ handleChange }>
                <option value="">&lt; Any { pretty('victim_gender') } &gt;</option>
                <option value="female">{ pretty('female') }</option>
                <option value="indeterminate">{ pretty('indeterminate') }</option>
                <option value="male">{ pretty('male') }</option>
            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-victim_gender" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
