
import { useState, useCallback, isValidElement } from "react";

import useResults from "../../hooks/useResultsNew";
import useForm from "../../hooks/useForm";
import usePretty from "../../hooks/usePretty";
import useFilters from "../../hooks/useFilters";
import useViz from "../../hooks/useViz";
import usePaging from "../../hooks/usePaging";
import useStagedSearch from "../../hooks/useStagedSearch";
import useSwitchDynaggs from "../../hooks/useSwitchDynaggs";
//import useAnchor from "../../hooks/useAnchor";
import useScrollToTop from "../../hooks/useScrollToTop";
import useClipboard from "../../hooks/useClipboard";

import useSearchmode from "../../hooks/oldbailey/useSearchmode";
import useDateDecade from "../../hooks/oldbailey/useDateDecade";

import { useParams, Link, useLocation } from "react-router-dom";
import queryString from "query-string";

import Agg from "../Agg";
import Loading from "../Loading";
import Error from "../Error";
import Post from "../Post";

import Hit from "./Hit";

//import options from '../../data/dhids/options';
import options from '../../data/oldbailey/options';

import oldBaileyLookups from '../../data/oldbailey/lookups';

import SearchSelectButton from "./fields/SearchSelectButton";

import SearchFields from "./fields/SearchFields";

import FieldKeyword from "./fields/FieldKeyword";
import FieldSearchIn from "./fields/FieldSearchIn";
import FieldDate from "./fields/FieldDate";
import FieldSurname from "./fields/FieldSurname";
import FieldGivenName from "./fields/FieldGivenName";
import FieldAlias from "./fields/FieldAlias";
import FieldCrime from "./fields/FieldCrime";
import FieldPlea from "./fields/FieldPlea";
import FieldVerdict from "./fields/FieldVerdict";
import FieldPunishment from "./fields/FieldPunishment";
import FieldStatsCat1 from "./fields/FieldStatsCat1";
import FieldStatsCat2 from "./fields/FieldStatsCat2";
import FieldStatsCountBy from "./fields/FieldStatsCountBy";
import FieldOAKeyword from "./fields/FieldOAKeyword";
import FieldOADate from "./fields/FieldOADate";
import FieldAssocrecLinkedToTrial from "./fields/FieldAssocrecLinkedToTrial";
import FieldAssocrecSurname from "./fields/FieldAssocrecSurname";
import FieldAssocrecGivenName from "./fields/FieldAssocrecGivenName";

import SearchButton from "./fields/SearchButton";

import DateTable from "./DateTable";

import viewSelector from "../../data/oldbailey/viewSelector";

import Macroscope from "./Macroscope";

const Search = () => {

    useScrollToTop();
    
    const { searchmode = 'keyword' } = useParams();
    const [ pretty ] = usePretty(oldBaileyLookups);

    document.title = "Search | " + pretty(searchmode) + " | The Proceedings of the Old Bailey";


    const { datedecade = '1670' } = useParams();

    const location = useLocation();
    const qs = queryString.parse(location.search, { arrayFormat: "bracket" });

    const preprocess = useCallback( () => {

        let qs = queryString.parse(location.search, { arrayFormat: "bracket" });

        let path = `${process.env.REACT_APP_API_URL}/data/oldbailey_record`;

        if (searchmode === 'ordinarys_accounts') {

            path = `${process.env.REACT_APP_API_URL}/data/oldbailey_oa`;

        } else if (searchmode === 'name') {

            path = `${process.env.REACT_APP_API_URL}/data/oldbailey_person`;

        } else if (searchmode === 'associated_records') {

            path = `${process.env.REACT_APP_API_URL}/data/oldbailey_assocrec`;

        } else if (searchmode === 'statistical') {

            path = `${process.env.REACT_APP_API_URL}/data/oldbailey_trial`;

            if (qs['countby']) {

                // DEFAULT
                // defendant
                // trial
                // offence
                // victim
                // verdict
                // punishment

                if (qs['countby'] === 'defendant') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_defendant`; }
                if (qs['countby'] === 'trial') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_trial`; }
                if (qs['countby'] === 'offence') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_offence`; }
                if (qs['countby'] === 'victim') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
                if (qs['countby'] === 'verdict') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_verdict`; }
                if (qs['countby'] === 'punishment') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_punishment`; }

            } else {

                // Inspect parameters to decide what view to use.
                
                if (qs['series']) {

                    if (qs['series'] === 'offence_category') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_offence`; }
                    if (qs['series'] === 'offence_subcategory') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_offence`; }
                    if (qs['series'] === 'plea') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_verdict`; }
                    if (qs['series'] === 'verdict_category') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_verdict`; }
                    if (qs['series'] === 'verdict_subcategory') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_verdict`; }
                    if (qs['series'] === 'punishment_category') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_punishment`; }
                    if (qs['series'] === 'punishment_subcategory') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_punishment`; }
                    if (qs['series'] === 'defendant_gender') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_defendant`; }
                    if (qs['series'] === 'defendant_age') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_defendant`; }
                    if (qs['series'] === 'victim_gender') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
                    if (qs['series'] === 'victim_age') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
                    if (qs['series'] === 'victim_hisco_class_1') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
                    if (qs['series'] === 'victim_hisco_label') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
                    if (qs['series'] === 'victim_institution') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_victim`; }
                    if (qs['series'] === 'decade') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_trial`; }
                    if (qs['series'] === 'year') { path = `${process.env.REACT_APP_API_URL}/data/oldbailey_trial`; }
                        
                }

            }

        }

        if (qs['text'] && /^([a-z][0-9]{8}[a]?-[0-9]{1,4})$/.test(qs['text'])) { // Match most OB section ids
            qs['idkey'] = qs['text'];
            delete qs['text'];
        }

        if (qs['text'] && /^([0-9]{8})$/.test(qs['text'])) { // Match OB full session ids
            qs['idkey'] = qs['text'];
            path = `${process.env.REACT_APP_API_URL}/data/oldbailey_datetable`;
            delete qs['text'];
        }

        if (qs['text'] && /^(OA[0-9]{8})$/.test(qs['text'])) { // Match OA ids
            qs['idkey'] = qs['text'];
            path = `${process.env.REACT_APP_API_URL}/data/oldbailey_oa`;
            delete qs['text'];
        }

        if (qs['month_gte'] && qs['year_gte']) {
            qs['year_month_gte'] = qs['year_gte'] + qs['month_gte'].padStart(2, "0");
            delete qs['month_gte'];
            delete qs['year_gte'];
        }

        if (qs['month_lte'] && qs['year_lte']) {
            qs['year_month_lte'] = qs['year_lte'] + (qs['month_lte']).padStart(2, "0");
            delete qs['month_lte'];
            delete qs['year_lte'];
        }

        const stringified = queryString.stringify(qs, { arrayFormat: "bracket" });

        return `${ path }?${ stringified }`;

    }, [ searchmode, location.search ]);

    // useResultsNew( apiPath, immediate_query, preprocess )
    const [ results, isLoading, error ] =
    //useResults( `${process.env.REACT_APP_API_URL}/data/oldbailey_record`, false, null );
    useResults( null, false, preprocess );
    //useResults(viewSelector(searchmode, location), null, false, false, preprocess );

    // We use the preprocess function to determine what view we are querying if the user chooses to download results.
    let thisview = preprocess();
    thisview = thisview.split('/oldbailey_')[1];
    thisview = thisview.split('?')[0];
    thisview = 'oldbailey_' + thisview;

    const today = new Date();

    const [ values, handleChange, handleSubmit, handleReset ] =
    useForm(false, false);

    const [ handleAddFilters, handleRemoveFilter ] =
    useFilters();

    const [ viz, handleChangeViz ] =
    useViz('table');

    const pagesize = 10; // Paging requires that we know the page size 
    const [ page, handleChangePageField, handleSubmitPageForm, handleNextPageButton, handlePreviousPageButton, handleLastPageButton, handleFirstPageButton ] =
    usePaging(pagesize);

    const [ showResults, handleEditQueryButton ] =
    useStagedSearch();

    const [ switchDynaggs ] =
    useSwitchDynaggs();

    const [ handleSearchmodeClick ] =
    useSearchmode();

    const [ handleChangeDateDecade ] =
    useDateDecade();

    const [ copyToClipboard ] = useClipboard();  

    let filters;

    if (values['filters']) {

        filters = values['filters'].map((filter, idx) => {

            const fs = filter.split('|');

            return (

            <div key={idx} className="d-grid col-12 col-lg-6 mb-2">
                <button type="button" className="btn btn-secondary text-start" data-filter={ filter } onClick={ handleRemoveFilter }>
                    <i className="bi bi-x me-2" />
                    <span className="fw-bold me-1">{ pretty(fs[0]) }</span>
                    <span className="fw-light">{ pretty(fs[1]) }</span>
                </button>
            </div>

        )});

    }

    let hits;

    if (results.hits && results.hits.hits) {

        hits = results.hits.hits.map((hit, idx) => (

            <Hit key={ hit._id } hit={ hit } idx={ ((parseInt(values['from']) || 0) + idx + 1) } last={ ((idx + 1) === Math.min((parseInt(values['from']) || 0) + pagesize, results.hits.total)) } /> 

        ));

    } 

    let aggregations;

    if (results.aggregations) {

        aggregations = Object.keys(results.aggregations).map((aggkey, idx) => (

            <Agg
            key={ idx }
            handleAddFilters={ handleAddFilters }
            filterkey={ aggkey }
            viz={ viz }
            agg={ results.aggregations[aggkey] }
            total={ results.hits.total }
            view={ thisview }
            lookups={ oldBaileyLookups }
            />

        ));

    }

    const [helpslug, setHelpslug] = useState();

    const handleChangeHelpslug = (event) => {

        var button = event.currentTarget;
        var helpslug = button.getAttribute('data-bs-helpslug');
        if (helpslug) {
            setHelpslug(helpslug);
        }
    }

    const extractString = (obj) => {

        if (typeof obj === 'string') {

            return obj;

        } else if (isValidElement(obj)) {

            return extractString(obj.props.children);

        } else if (Array.isArray(obj)) {

            return obj.map(e => extractString(e)).join(' ');

        } else {

            return obj.toString();

        }

    }

    //if (values['rows'] && values['initviz'] && values['initviz'] === 'table') { values['initviz']('table-cross'); } // If two statistics categories are selected, make a cross-tabulation table the default.

    //console.log('Search redrew.');

    return (

        <div className="container-fluid gx-0">

            <div id="search-offcanvas" className={

                'offcanvas offcanvas-top offcanvas-search ' +

                ( (searchmode === 'statistical' && showResults) ? '' : 'd-lg-none ' )

            }>

                <div className="offcanvas-header">

                    <h1>

                        { !showResults && (

                            <span>More criteria:</span>

                        ) }

                        { showResults && (

                            <span>Refine:</span>

                        ) }

                    </h1>

                    {/* !showResults && (

                        <div className="float-end">
                            <div className="dropdown">
                                <button className="btn btn-light bg-white dropdown-toggle text-muted search-offcanvas-mode-select" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="me-3">{ pretty(searchmode) }</span>
                                </button>
                                <ul className="dropdown-menu">
                                    { searchmode !== 'keyword' && ( <li><button type="button" className="dropdown-item" data-searchmode="keyword" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">key</span><span className="ms-1">Keyword</span></button></li> ) }
                                    { searchmode !== 'name' && ( <li><button type="button" className="dropdown-item" data-searchmode="name" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">description</span><span className="ms-1">Name</span></button></li> ) }
                                    { searchmode !== 'date' && ( <li><button type="button" className="dropdown-item" data-searchmode="date" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">calendar_month</span><span className="ms-1">Date</span></button></li> ) }
                                    { searchmode !== 'statistical' && ( <li><button type="button" className="dropdown-item" data-searchmode="statistical" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">monitoring</span><span className="ms-1">Statistical</span></button></li> ) }
                                    { searchmode !== 'ordinarys_accounts' && ( <li><button type="button" className="dropdown-item" data-searchmode="ordinarys_accounts" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">import_contacts</span><span className="ms-1">Ordinary's Accounts</span></button></li> ) }
                                    { searchmode !== 'associated_records' && ( <li><button type="button" className="dropdown-item" data-searchmode="associated_records" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">grid_on</span><span className="ms-1">Associated Records</span></button></li> ) }
                                    { searchmode !== 'custom' && ( <li><button type="button" className="dropdown-item" data-searchmode="custom" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">tune</span><span className="ms-1">Custom</span></button></li> ) }
                                </ul>
                            </div>
                        </div>

                    ) */}

                    <button id="offcanvas-close" type="button" className="btn-close" data-bs-dismiss="offcanvas"></button>

                </div>

                <div className="offcanvas-body">
         
                    <form onSubmit={ handleSubmit } onReset={ handleReset }>

                        <SearchFields
                            pretty={ pretty }
                            values={ values }
                            handleChange={ handleChange }
                            handleChangeHelpslug={ handleChangeHelpslug }
                            searchmode={ searchmode }
                            showResults={ showResults }
                        />

                    </form>

            </div>

        </div>

            <div className="modal fade" id="helpModal" tabIndex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button type="button" className="btn float-end" data-bs-dismiss="modal" aria-label="Close">
                        <i className="bi bi-x-lg"></i>
                    </button>

                    { helpslug && (

                        <div className="ob-body-text">
                            <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=` + helpslug } options={ options } />
                        </div>

                    ) }

                    <button type="button" className="btn btn-secondary float-end" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row gx-0">

                {/* left big column */}
                <div id="search-main-pane" className={

                    'calc-vh-fluid ' + 

                    ( (searchmode === 'date' || (searchmode === 'statistical' && showResults)) ? 'col-12 ' : 'col-12 col-lg-8 ' ) +

                    ( (!showResults) ? 'search-main-pane-narrow ' : '' ) +

                    ( showResults ? 'search-main-pane-results search-main-pane-wide ' : 'search-main-pane-feature ' )

                }>

                    { !showResults && (

                        <div>

                            <div className="row mb-2">
                                <div className="col-9">
                                    <h1>Search</h1>
                                </div>

                                {/*
                                <div className="col-3 text-end">
                                    <button className="btn btn-light offcanvas-button d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#search-offcanvas">
                                        <span className="material-symbols-sharp offcanvas-button-icon">filter_list</span>
                                    </button>
                                </div>
                                */}

                            </div>

                            <div className="row">

                                <div className="col-12 mb-5">

                                    <form onSubmit={ handleSubmit } onReset={ handleReset }>

<div className="row g-1 mb-4">
    <div className="col-12">

        <div className="d-block d-lg-none mb-2">

            <SearchSelectButton searchmode={ searchmode } pretty={ pretty } handleSearchmodeClick={ handleSearchmodeClick } />

        </div>

        <div className="input-group">

            <div className="d-none d-lg-inline me-2">

                <SearchSelectButton searchmode={ searchmode } pretty={ pretty } handleSearchmodeClick={ handleSearchmodeClick } />

            </div>

            { searchmode === 'keyword' && (

                <>

                    <FieldKeyword first={ true } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/>

                </>

            ) }

            { searchmode === 'name' && (

                <>

                    <FieldSurname first={ true } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/>

                </>

            ) }

            { searchmode === 'crime' && (

                <>

                    <FieldCrime first={ true } pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/>

                </>

            ) }

            { (searchmode === 'date') && (

                <>

                    <select className="form-select search-feature-first-input" name="datedecade" id="field_datedecade" value={ datedecade } onChange={ handleChangeDateDecade }>
                        <option value="1670">{ pretty('1670s') }</option>
                        <option value="1680">{ pretty('1680s') }</option>
                        <option value="1690">{ pretty('1690s') }</option>
                        <option value="1700">{ pretty('1700s') }</option>
                        <option value="1710">{ pretty('1710s') }</option>
                        <option value="1720">{ pretty('1720s') }</option>
                        <option value="1730">{ pretty('1730s') }</option>
                        <option value="1740">{ pretty('1740s') }</option>
                        <option value="1750">{ pretty('1750s') }</option>
                        <option value="1760">{ pretty('1760s') }</option>
                        <option value="1770">{ pretty('1770s') }</option>
                        <option value="1780">{ pretty('1780s') }</option>
                        <option value="1790">{ pretty('1790s') }</option>
                        <option value="1800">{ pretty('1800s') }</option>
                        <option value="1810">{ pretty('1810s') }</option>
                        <option value="1820">{ pretty('1820s') }</option>
                        <option value="1830">{ pretty('1830s') }</option>
                        <option value="1840">{ pretty('1840s') }</option>
                        <option value="1850">{ pretty('1850s') }</option>
                        <option value="1860">{ pretty('1860s') }</option>
                        <option value="1870">{ pretty('1870s') }</option>
                        <option value="1880">{ pretty('1880s') }</option>
                        <option value="1890">{ pretty('1890s') }</option>
                        <option value="1900">{ pretty('1900s') }</option>
                        <option value="1910">{ pretty('1910s') }</option>
                    </select>
                    {/*
                    <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-decade" onClick={ handleChangeHelpslug }>
                        <span className="material-symbols-sharp">help_outline</span>
                    </button>
                    */}

                </>

            ) }

            { searchmode === 'statistical' && (

                <>

                    <FieldStatsCat1 first={ true } pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/>

                </>

            ) }


            { (searchmode === 'ordinarys_accounts') && (

                <>

                    <FieldOAKeyword first={ true } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/>

                </>

            ) }

            { (searchmode === 'associated_records') && (

                <>

                    <FieldAssocrecSurname first={ true } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/>

                </>

            ) }

            { searchmode === 'advanced' && (

                <>

                    <FieldKeyword first={ true } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/>

                </>

            ) }

            { searchmode !== 'date' && (

                <>
                    <SearchButton/>
                </>

            ) }

        </div>
    </div>
</div>

{ searchmode === 'keyword' && (

    <>

        <div className="row mb-4"><div className="col-12"><div className="input-group"><FieldSearchIn pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row"><FieldDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>

    </>

) }

{ searchmode === 'name' && (

    <>

        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldGivenName pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-4"><div className="col-12"><div className="input-group"><FieldAlias pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row"><FieldDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>

    </>

) }

{ searchmode === 'crime' && (

    <>

        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPlea pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVerdict pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-4"><div className="col-12"><div className="input-group"><FieldPunishment pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row"><FieldDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>

    </>

) }

{ searchmode === 'date' && (

<>

    <div className="ob-body-text mt-5 mb-5 ob-feature-text">
        <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=formbrowsedate` } options={ { ...options, notitle: true  } } />
    </div>

    <div className="overflow-auto bg-white mt-4 ps-4 pe-4 datetable-holder">
        <DateTable decade={ datedecade } />
    </div>

</>

) }

{ searchmode === 'statistical' && (

    <>

        <div className="row mb-1 mt-4"><div className="col-12"><div className="input-group"><FieldStatsCat2 pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldStatsCountBy pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>

        <div className="row mb-1">
            <div className="col-12">
                <div className="input-group">
                    <select className="form-select" name="initviz" id="field_initviz" value={ values['initviz'] || '' } onChange={ handleChange }>

                        <option value="table">Display as { (values['rows']? '' : 'a ') + pretty('table', ['first-letter-lower']) + (values['rows']? 's' : '') }</option> 
                        <option value="pie">Display as { (values['rows']? '' : 'a ') +  pretty('pie chart', ['first-letter-lower']) + (values['rows']? 's' : '')  }</option>
                        <option value="bar">Display as { (values['rows']? '' : 'a ') +  pretty('bar chart', ['first-letter-lower']) + (values['rows']? 's' : '')  }</option>
                        <option value="line">Display as { (values['rows']? '' : 'a ') +  pretty('line chart', ['first-letter-lower']) + (values['rows']? 's' : '')  }</option>

                        { values['rows'] && (

                            <>

                                <option value="table-cross">Display as a { pretty('table, cross-tabulated', ['first-letter-lower']) }</option>
                                <option value="bar-stacked">Display as a { pretty('stacked bar chart', ['first-letter-lower']) }</option>
                                <option value="bar-stacked-normalised">Display as a { pretty('stacked bar chart, normalised', ['first-letter-lower']) }</option>
                                <option value="bar-grouped">Display as a { pretty('grouped bar chart', ['first-letter-lower']) }</option>
                                <option value="line-multi">Display as a { pretty('multi-line chart', ['first-letter-lower']) }</option>

                            </>

                        ) }

                    </select>
                    <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-viz" onClick={ handleChangeHelpslug }>
                        <span className="material-symbols-sharp">help_outline</span>
                    </button>
                </div>
            </div>
        </div>

    </>

)}

{ searchmode === 'ordinarys_accounts' && (

    <>

        <div className="row"><FieldOADate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>

    </>

) }

{ searchmode === 'associated_records' && (

    <>

        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldAssocrecGivenName pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-4"><div className="col-12"><div className="input-group"><FieldAssocrecLinkedToTrial pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row"><FieldDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>

    </>

) }

{ searchmode === 'advanced' && (

    <>

        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldCrime pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldPlea pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-1"><div className="col-12"><div className="input-group"><FieldVerdict pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row mb-4"><div className="col-12"><div className="input-group"><FieldPunishment pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div></div></div>
        <div className="row"><FieldDate pretty={ pretty } values={ values } handleChange={ handleChange } handleChangeHelpslug={ handleChangeHelpslug }/></div>

    </>

) }


{ /*

<>

<div className="row mb-4">
    <div className="col-12">
        <div className="input-group">
            <select className="form-select" name="div_type" id="field_div_type" value={ values['div_type'] || '' } onChange={ handleChange }>
                <option value="">&lt; All text &gt;</option>
                <option value="trialAccount">{ pretty('trialAccounts') }</option>
                <option value="advertisements">{ pretty('advertisements') }</option>
                <option value="frontMatter">{ pretty('frontMatter') }</option>
                <option value="punishmentSummary">{ pretty('punishmentSummaries') }</option>
                <option value="supplementaryMaterial">{ pretty('supplementaryMaterial') }</option>
            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-div_type" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>
        </div>
    </div>
</div>

<div className="row mb-4">
    <div className="col-12">
        <div className="input-group">
            <select className="form-select" name="sort" id="field_sort" value={ values['sort'] || '' } onChange={ handleChange }>
                <option value="">{ pretty('date') }</option>
                <option value="relevance">{ pretty('relevance') }</option>
            </select>
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-sort" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>
        </div>
    </div>
</div>

</>

*/ }

{/*
<div className="d-flex flex-row-reverse mt-4">
    <button type="submit" className="btn btn-primary">Search</button>
    <button type="reset" className="btn btn-danger me-2">Reset Form</button>
</div>
*/}

                                    </form>

{ searchmode !== 'date' && (

<button className="btn btn-dark search-add-more-button p-0 d-lg-none mt-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#search-offcanvas">
    <span className="material-symbols-sharp search-add-more-button-icon">add_circle</span> Add more search criteria
</button>

) }

{ searchmode === 'keyword' && (

    <>

    <div className="ob-body-text mt-5 mb-5 ob-feature-text">
        <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=formmain` } options={ { ...options, notitle: true  } } />
    </div>

    <div className="row mt-5">

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/name">
                <div className="card ob-search-feature-card-hero4 rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Name search</h2>
                        <p className="ob-body-text">
                            <span>Search for specific individuals.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/ordinarys_accounts">
                <div className="card ob-search-feature-card-courtroom-1809 rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Ordinary's Accounts</h2>
                        <p className="ob-body-text">
                            <span>Search the Ordinary of Newgate’s Accounts (1676 to 1772).</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

    </div>

    </>

) }

{ searchmode === 'name' && (

    <>

    <div className="ob-body-text mt-5 mb-5 ob-feature-text">
        <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=formpersname` } options={ { ...options, notitle: true  } } />
    </div>

    <div className="row mt-5">

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/keyword">
                <div className="card ob-search-feature-card-hero1 rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Keyword search</h2>
                        <p className="ob-body-text">
                            <span>Search the whole of the Proceedings for specific words.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/crime">
                <div className="card ob-search-feature-card-hero2 rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Crime, Verdict & Punishment</h2>
                        <p className="ob-body-text">
                            <span>Search for specific crimes, pleas, verdicts and punishments.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

    </div>

    </>

) }

{ searchmode === 'crime' && (

    <>

    <div className="ob-body-text mt-5 mb-5 ob-feature-text">
        <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=formcrime` } options={ { ...options, notitle: true  } } />
    </div>

    <div className="row mt-5">

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/keyword">
                <div className="card ob-search-feature-card-hero1 rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Keyword search</h2>
                        <p className="ob-body-text">
                            <span>Search the whole of the Proceedings for specific words.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/statistical">
                <div className="card ob-search-feature-card-stats rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Statistics</h2>
                        <p className="ob-body-text">
                            <span>Generate statistics based on tagged information.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

    </div>

    </>

) }


{ searchmode === 'statistical' && (

    <>

    <div className="ob-body-text mt-5 mb-5 ob-feature-text">
        <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=formstats` } options={ { ...options, notitle: true  } } />
    </div>

    <div className="row mt-5">

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/keyword">
                <div className="card ob-search-feature-card-hero1 rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Keyword search</h2>
                        <p className="ob-body-text">
                            <span>Search the whole of the Proceedings for specific words.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/date">
                <div className="card ob-search-feature-card-proceedings-cover rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Browse By Date</h2>
                        <p className="ob-body-text">
                            <span>Find a specific issue of the Proceedings or Ordinary’s Accounts.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

    </div>

    </>

) }

{ searchmode === 'ordinarys_accounts' && (

    <>

    <div className="ob-body-text mt-5 mb-5 ob-feature-text">
        <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=formordinarysaccounts` } options={ { ...options, notitle: true  } } />
    </div>

    <div className="row mt-5">

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/keyword">
                <div className="card ob-search-feature-card-hero1 rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Keyword search</h2>
                        <p className="ob-body-text">
                            <span>Search the whole of the Proceedings for specific words.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/date">
                <div className="card ob-search-feature-card-proceedings-cover rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Browse By Date</h2>
                        <p className="ob-body-text">
                            <span>Find a specific issue of the Proceedings or Ordinary’s Accounts.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

    </div>

    </>

) }

{ searchmode === 'associated_records' && (

    <>

    <div className="ob-body-text mt-5 mb-5 ob-feature-text">
        <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=formassociatedrecords` } options={ { ...options, notitle: true  } } />
    </div>

    <div className="row mt-5">

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/keyword">
                <div className="card ob-search-feature-card-hero1 rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Keyword search</h2>
                        <p className="ob-body-text">
                            <span>Search the whole of the Proceedings for specific words.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/name">
                <div className="card ob-search-feature-card-hero4 rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Name search</h2>
                        <p className="ob-body-text">
                            <span>Search for specific individuals.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

    </div>

    </>

) }

{ searchmode === 'advanced' && (
    
    <>

        <div className="ob-body-text mt-5 mb-5 ob-feature-text">
            <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=formcustom` } options={ { ...options, notitle: true  } } />
        </div>

        <div className="row mt-5">

            <div className="col-12 col-lg-6">
                <Link className="text-decoration-none text-white" to="/search/ordinarys_accounts">
                    <div className="card ob-search-feature-card-courtroom-1809 rounded-0 border-0">
                        <div className="ob-search-feature-card-spacer"/>
                        <div className="card-body ob-search-feature-card-text">
                            <h2 className="mt-0 mb-0">Ordinary's Accounts</h2>
                            <p className="ob-body-text">
                                <span>Search the Ordinary of Newgate’s Accounts (1676 to 1772).</span>
                            </p>
                        </div>
                    </div>
                </Link>
            </div>

            <div className="col-12 col-lg-6">
                <Link className="text-decoration-none text-white" to="/search/associated_records">
                    <div className="card ob-search-feature-card-assocrec rounded-0 border-0">
                        <div className="ob-search-feature-card-spacer"/>
                        <div className="card-body ob-search-feature-card-text">
                            <h2 className="mt-0 mb-0">Associated Records</h2>
                            <p className="ob-body-text">
                                <span>Search archive records associated with Old Bailey trials.</span>
                            </p>
                        </div>
                    </div>
                </Link>
            </div>

        </div>

    </>


) }



<div className="row mt-4">

    { searchmode !== 'advanced' && (

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/advanced">
            <div className="card ob-search-feature-card-drunkard rounded-0 border-0">
                <div className="ob-search-feature-card-spacer"/>
                <div className="card-body ob-search-feature-card-text">
                    <h2 className="mt-0 mb-0">Advanced search</h2>
                    <p className="ob-body-text">
                        <span>Create more complex and precise searches.</span>
                    </p>
                </div>
            </div>
            </Link>
        </div>

    ) }

    { searchmode === 'advanced' && (

        <div className="col-12 col-lg-6">
            <Link className="text-decoration-none text-white" to="/search/statistical">
                <div className="card ob-search-feature-card-stats rounded-0 border-0">
                    <div className="ob-search-feature-card-spacer"/>
                    <div className="card-body ob-search-feature-card-text">
                        <h2 className="mt-0 mb-0">Statistics</h2>
                        <p className="ob-body-text">
                            <span>Generate statistics based on tagged information.</span>
                        </p>
                    </div>
                </div>
            </Link>
        </div>

    ) }

    <div className="col-12 col-lg-6">
        <Link className="text-decoration-none text-white" to="/about/guides">
        <div className="card ob-search-feature-card-billingsgate rounded-0 border-0">
            <div className="ob-search-feature-card-spacer"/>
            <div className="card-body ob-search-feature-card-text">
                <h2 className="mt-0 mb-0">Guides</h2>
                <p className="ob-body-text">
                    <span>If you are new to the site you may find our guides helpful.</span>
                </p>
            </div>
        </div>
        </Link>
    </div>

</div>

    



                                </div>

                            </div>

                        </div>

                    ) }

                    { showResults && (

                        <>          

                            { results.hits && (

                                <div id="results" className="pt-4">

                                    <div className="ps-2 pe-2 ms-2 me-2 me-lg-5 ps-lg-5 pe-lg-5 ms-lg-5 mb-2">

                                        {/*
                                        <div className="row">

                                            <div className={

                                                'text-end col-12 ' + 

                                                ( (searchmode === 'statistical' && showResults) ? '' : 'd-lg-none ' )

                                            }>

                                                <button type="button" data-bs-toggle="offcanvas" data-bs-target="#search-offcanvas" className='btn btn-light offcanvas-button'>
                                                    <span className="material-symbols-sharp offcanvas-button-icon">filter_alt</span>
                                                </button>

                                            </div>
                                        </div>
                                        */}

                                        <div className="row">

                                            <div className="col-5">

                                                <span className="me-2">You searched for { pretty(qs, ['query']) }.</span>

                                            </div>

                                            <div className={

                                                'text-end col-7 ' + 

                                                ( (searchmode === 'statistical' && showResults) ? '' : 'col-lg-7 ' )

                                            }>

                                                <button type="button" data-bs-toggle="offcanvas" data-bs-target="#search-offcanvas" className={ 

                                                    "btn btn-outline-secondary query-options-button mb-1 query-options-button-small " +

                                                    ( (searchmode === 'statistical' && showResults) ? '' : 'd-lg-none ' )

                                                }><span className="material-symbols-sharp query-options-icon">filter_alt</span><span className="query-options-text">Refine</span></button>

                                                <button type="button" className="btn btn-outline-secondary query-options-button query-options-button-small ms-3 mb-1" onClick={ handleEditQueryButton }><span className="material-symbols-sharp query-options-icon">edit</span><span className="query-options-text">Edit query</span></button>

                                                { !results.aggregations && (
                                                    <button type="button" className="btn btn-outline-secondary query-options-button query-options-button-small ms-3 mb-1" data-bs-toggle="collapse" data-bs-target="#citation" aria-expanded="false"><span className="material-symbols-sharp query-options-icon">bookmark</span><span className="query-options-text">Cite query</span></button>
                                                ) }

                                                <a className="btn btn-outline-secondary query-options-button query-options-button-small ms-3 mb-1" href={ window.location.pathname }><span className="material-symbols-sharp query-options-icon">refresh</span><span className="query-options-text">New query</span></a>

                                            </div>

                                        </div>
                                        <div className="col-12 collapse mt-3" id="citation">
                                            <div className="card citation-card">
                                                <div className="card-body">
                                                    <button type="button" className="btn-close float-end ms-1 mb-1" aria-label="Close" data-bs-toggle="collapse" data-bs-target="#citation" aria-expanded="false"/>
                                                    <i>Old Bailey Proceedings Online</i> (www.oldbaileyonline.org, version 9.0)
                                                    { !results.aggregations && (
                                                        <> A search for { pretty(qs, ['query']) }. </>
                                                    ) }
                                                    { results.aggregations && (
                                                        <> { pretty((qs['initviz'] || 'table'), ['lookup_viz']) } of { pretty(qs, ['query-stats']) } using { pretty(qs, ['query']) }. </>
                                                    ) }
                                                    Available at: { window.location.toString() } (Accessed: { pretty(today, 'date') }).
                                                    <button type="button" className="btn-link float-end mt-3 text-primary" onClick={ () => { copyToClipboard( '<i>Old Bailey Proceedings Online</i> (www.oldbaileyonline.org, version 9.0) ' + (results.aggregations ? pretty((qs['initviz'] || 'table'), ['lookup_viz']) + ' of ' + extractString(pretty(qs, ['query-stats'])) + ' using ' + extractString(pretty(qs, ['query'])) + '.' : 'A search for ' + extractString(pretty(qs, ['query'])) + '.' ) + ' Available at: ' + window.location.toString() + ' (Accessed: ' + pretty(today, 'date') + ').'); } }>Copy to clipboard</button>
                                                </div>                                 
                                            </div>                           
                                        </div>
                                    </div>

                                    <div className="row gx-2 ps-2 pe-2 ms-2 me-2 me-lg-5 ps-lg-5 pe-lg-5 ms-lg-5 mb-2">

                                        { filters }

                                    </div> 

                                    { results.hits.total > 0 && (

                                        <div className="ps-2 pe-2 ms-2 me-2 me-lg-5 ps-lg-5 pe-lg-5 ms-lg-5">

                                            { searchmode === 'statistical' ? (
                                                <>
                                                <strong className="fs-6 me-1">{ pretty(results.hits.total) }</strong> record{ results.hits.total !== 1 && ( <>s</> ) } matched this query.
                                                <div className="stats-caveat">Not all records queried necessarily match one of the categories below, and some may match more than one.</div>
                                                </>
                                            ) : (
                                                <>
                                                <strong className="fs-6 me-1">{ pretty(results.hits.total) }</strong> record{ results.hits.total !== 1 && ( <>s</> ) } matched this query
                                                </>
                                            ) }

                                        </div>

                                    ) }

                                    { results.aggregations && (

                                        <>

                                            <div className="row mb-3 mt-3 ps-2 pe-2 ms-2 me-2 me-lg-5 ps-lg-5 pe-lg-5 ms-lg-5 mb-2 gx-0">

                                                <div className="col-5">

                                                   <p>
                                                        <span className="me-2">Tabulating { pretty(qs, ['query-stats']) }.</span>
                                                        <span className="me-2">Counting by { pretty(viewSelector(searchmode, location), ['query-countby']) }.</span>

                                                    </p>

                                                </div>

                                                <div className="col-7 text-end">

                                                        { values['rows'] && (

                                                            <>
                                                            <button type="button" className="btn btn-outline-secondary query-options-button query-options-button-big mb-1" onClick={ switchDynaggs }><span className="material-symbols-sharp query-options-icon">change_circle</span><span className="query-options-text">Swap categories</span></button>
                                                            </>

                                                        ) }

                                                        <button type="button" className="btn btn-outline-secondary query-options-button query-options-button-big ms-3 mb-1" onClick={ handleEditQueryButton }><span className="material-symbols-sharp query-options-icon">edit</span><span className="query-options-text">Change categories</span></button>
                                                        <button type="button" className="btn btn-outline-secondary query-options-button query-options-button-big ms-3 mb-1" data-bs-toggle="collapse" data-bs-target="#citation" aria-expanded="false"><span className="material-symbols-sharp query-options-icon">bookmark</span><span className="query-options-text">Cite visualisation</span></button>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-3 ps-2 pe-2 ms-2 me-2 me-lg-5 ps-lg-5 pe-lg-5 ms-lg-5 mb-2 gx-0">

                                                <div className="col-12">

                                                    <div className="input-group">
                                                        <select className="form-select" name="viz" id="field_viz" value={ viz } onChange={ handleChangeViz }>

                                                            <option value="table">{ pretty('table') + (values['rows']? 's' : '') }</option>
                                                            <option value="pie">{ pretty('pie chart') + (values['rows']? 's' : '')  }</option>
                                                            <option value="bar">{ pretty('bar chart') + (values['rows']? 's' : '')  }</option>
                                                            <option value="line">{ pretty('line chart') + (values['rows']? 's' : '')  }</option>

                                                            { values['rows'] && (

                                                                <>

                                                                    <option value="table-cross">{ pretty('table, cross-tabulated') }</option>
                                                                    <option value="bar-stacked">{ pretty('stacked bar chart') }</option>
                                                                    <option value="bar-stacked-normalised">{ pretty('stacked bar chart, normalised') }</option>
                                                                    <option value="bar-grouped">{ pretty('grouped bar chart') }</option>
                                                                    <option value="line-multi">{ pretty('multi-line chart') }</option>

                                                                </>

                                                            ) }

                                                        </select>
                                                        <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-viz" onClick={ handleChangeHelpslug }>
                                                            <span className="material-symbols-sharp">help_outline</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row ps-2 pe-2 ms-2 me-2 me-lg-5 ps-lg-5 pe-lg-5 ms-lg-5 mb-2 gx-0">

                                                { aggregations }
                                        
                                            </div>

                                        </>

                                    ) }

                                    { results.hits.total > 0 && (

                                        <>

                                            <div className="ps-2 pe-2 ms-2 me-2 me-lg-5 ps-lg-5 pe-lg-5 ms-lg-5 mb-2">
                                                Showing records <strong>{ (parseInt(values['from']) || 0) + 1 }</strong> - <strong>{ Math.min((parseInt(values['from']) || 0) + pagesize, results.hits.total) }</strong>
                                            </div>
                                            
                                            <div className="paging-wrapper">
                                                <div className="paging row g-0">

                                                    <div className="col-3">
                                                        <button type="button" disabled={ page < 2 } className="paging-first btn btn-link text-start" onClick={ event => handleFirstPageButton(event, results.hits.total) }><span className="material-symbols-sharp paging-icon">first_page</span></button>
                                                        <button type="button" disabled={ page < 2 } className="paging-previous btn btn-link text-start" onClick={ event => handlePreviousPageButton(event, results.hits.total) }><span className="material-symbols-sharp paging-icon me-2">chevron_left</span><span className="paging-text d-none d-lg-inline">Back</span></button>
                                                    </div>

                                                    <div className="col-6 text-center">
                                                        <form id="paging-form" onSubmit={ event => handleSubmitPageForm(event, results.hits.total) }>
                                                            <span className="paging-text">Page:</span>
                                                            <input type="text" className="form-control ms-2 me-2 paging-input" name="page" id="field_page" value={ page } onChange={ handleChangePageField } />
                                                            <span className="paging-text me-1">of </span><span className="paging-text">{ pretty(Math.ceil(results.hits.total / pagesize)) }</span>
                                                        </form>
                                                    </div>

                                                    <div className="col-3 text-end">
                                                        <button type="button" disabled={ page >= Math.ceil(results.hits.total / pagesize) } className="paging-next btn btn-link text-start" onClick={ event => handleNextPageButton(event, results.hits.total) }><span className="paging-text d-none d-lg-inline">Next</span><span className="material-symbols-sharp paging-icon ms-2">chevron_right</span></button>
                                                        <button type="button" disabled={ page >= Math.ceil(results.hits.total / pagesize) } className="paging-last btn btn-link text-start" onClick={ event => handleLastPageButton(event, results.hits.total) }><span className="material-symbols-sharp paging-icon">last_page</span></button>
                                                    </div>

                                                </div>
                                            </div>

                                        </>

                                    ) }

                                </div>

                            ) }

                            { ((searchmode === 'keyword' || searchmode === 'crime' || searchmode === 'advanced') && ((parseInt(values['from']) || 0) === 0) && !isLoading && !error && results && results.hits && results.hits.total > 0 && results.hits.total < 20000) && (                             

                                    <Macroscope handleChangeHelpslug={ handleChangeHelpslug } />

                            ) }

                            <div className="pt-4 pb-4 pt-lg-5 bg-white hits border-top border-bottom">

                                { isLoading && ( <div className="mt-5 mb-5 pt-5 pb-5"><Loading /></div> ) }

                                { error && ( <div className="mt-5 mb-5 pt-5 pb-5"><Error error={ error } /></div> ) }

                                { (!isLoading && !error) && (

                                    <>
                                        { hits }
                                    </>

                                ) }

                                { results && results.hits && results.hits.total === 0 && (

                                    <div>

                                        <strong className="fs-6 ms-1 me-1">No records</strong> matched this query.

                                    </div>

                                ) }
                       
                            </div>

                            { !isLoading && !error && results.hits && (

                                <div className="pt-2 pb-2">

                                    { results.hits.total > 0 && (

                                        <>

                                            <div className="paging row g-0">

                                                <div className="col-3">
                                                    <button type="button" disabled={ page < 2 } className="paging-first btn btn-link text-start" onClick={ event => handleFirstPageButton(event, results.hits.total) }><span className="material-symbols-sharp paging-icon">first_page</span></button>
                                                    <button type="button" disabled={ page < 2 } className="paging-previous btn btn-link text-start" onClick={ event => handlePreviousPageButton(event, results.hits.total) }><span className="material-symbols-sharp paging-icon me-2">chevron_left</span><span className="paging-text d-none d-lg-inline">Back</span></button>
                                                </div>

                                                <div className="col-6 text-center">
                                                    <form id="paging-form" onSubmit={ event => handleSubmitPageForm(event, results.hits.total) }>
                                                        <span className="paging-text">Page:</span>
                                                        <input type="text" className="form-control ms-2 me-2 paging-input" name="page" id="field_page" value={ page } onChange={ handleChangePageField } />
                                                        <span className="paging-text me-1">of </span><span className="paging-text">{ pretty(Math.ceil(results.hits.total / pagesize)) }</span>
                                                    </form>
                                                </div>

                                                <div className="col-3 text-end">
                                                    <button type="button" disabled={ page >= Math.ceil(results.hits.total / pagesize) } className="paging-next btn btn-link text-start" onClick={ event => handleNextPageButton(event, results.hits.total) }><span className="paging-text d-none d-lg-inline">Next</span><span className="material-symbols-sharp paging-icon ms-2">chevron_right</span></button>
                                                    <button type="button" disabled={ page >= Math.ceil(results.hits.total / pagesize) } className="paging-last btn btn-link text-start" onClick={ event => handleLastPageButton(event, results.hits.total) }><span className="material-symbols-sharp paging-icon">last_page</span></button>
                                                </div>

                                            </div>

                                        </>

                                    ) }

                                </div>

                            ) }

                        </>

                    ) }
            
                </div>

                {/* right big column */}
                <div id="search-refine-pane" className={

                    'pb-4 ps-5 pe-5 bg-light border-start border-dark ' + 

                    ( ((searchmode === 'statistical' && showResults) || searchmode === 'date') ? 'd-none ' : 'd-none d-lg-block col-lg-4 ' )

                }>

                    {/* !showResults && (

                        <div className="float-end">
                            <div className="dropdown">
                                <button className="btn btn-light bg-white dropdown-toggle text-muted search-refine-mode-select" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="me-3">{ pretty(searchmode) }</span>
                                </button>
                                <ul className="dropdown-menu">
                                    { searchmode !== 'keyword' && ( <li><button type="button" className="dropdown-item" data-searchmode="keyword" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">key</span><span className="ms-1">Keyword</span></button></li> ) }
                                    { searchmode !== 'name' && ( <li><button type="button" className="dropdown-item" data-searchmode="name" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">description</span><span className="ms-1">Name</span></button></li> ) }
                                    { searchmode !== 'date' && ( <li><button type="button" className="dropdown-item" data-searchmode="date" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">calendar_month</span><span className="ms-1">Date</span></button></li> ) }
                                    { searchmode !== 'statistical' && ( <li><button type="button" className="dropdown-item" data-searchmode="statistical" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">monitoring</span><span className="ms-1">Statistical</span></button></li> ) }
                                    { searchmode !== 'ordinarys_accounts' && ( <li><button type="button" className="dropdown-item" data-searchmode="ordinarys_accounts" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">import_contacts</span><span className="ms-1">Ordinary's Accounts</span></button></li> ) }
                                    { searchmode !== 'associated_records' && ( <li><button type="button" className="dropdown-item" data-searchmode="associated_records" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">grid_on</span><span className="ms-1">Associated Records</span></button></li> ) }
                                    { searchmode !== 'custom' && ( <li><button type="button" className="dropdown-item" data-searchmode="custom" onClick={ handleSearchmodeClick } ><span className="material-symbols-sharp">tune</span><span className="ms-1">Custom</span></button></li> ) }
                                </ul>
                            </div>
                        </div>

                    ) */}

                    <h1>

                        { !showResults && (

                            <span>More criteria:</span>

                        ) }

                        { showResults && (

                            <span>Refine:</span>

                        ) }

                    </h1>

                    <form onSubmit={ handleSubmit } onReset={ handleReset }>

                        <SearchFields
                            pretty={ pretty }
                            values={ values }
                            handleChange={ handleChange }
                            handleChangeHelpslug={ handleChangeHelpslug }
                            searchmode={ searchmode }
                            showResults={ showResults }
                        />

                    </form>

                </div>

            </div>

        </div>

    );

};

export default Search;
