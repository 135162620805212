const useClipboard = () => {

    const copyToClipboard = (richtext) => {

        const regrichtext = richtext.replace(/\s+/g, ' ');
        const plaintext = regrichtext.replace(/(<([^>]+)>)/gi, "").replace(/\s+/g, ' ');
       
        //console.log(regrichtext);
        //console.log(plaintext);

        const blobHtml = new Blob( [ regrichtext ], { type: "text/html" } );
        const blobText = new Blob( [ plaintext ], { type: "text/plain" } );

        if (window.ClipboardItem) {

            //console.log('can use ClipboardItem');

            const data = [ new ClipboardItem( {

                "text/plain": blobText,
                "text/html": blobHtml,

            } ) ];

            navigator.clipboard.write(data);

        } else {

            //console.log('cannot use ClipboardItem');

            navigator.clipboard.writeText(plaintext);

        }

    }

    return [ copyToClipboard ];
};

export default useClipboard;
