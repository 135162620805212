
import { useParams } from "react-router-dom";
//import { useParams, Link } from "react-router-dom";

//import { useState, useCallback, /*useRef,*/ useEffect } from "react";

//import XMLViewer from 'react-xml-viewer';

//import parse from 'html-react-parser';
//import { domToReact } from 'html-react-parser';

//import useResults from "../../hooks/useResultsNew";
//import usePretty from "../../hooks/usePretty";
import useScrollToTop from "../../hooks/useScrollToTop";

//import Loading from "../Loading";
//import Error from "../Error";

import ContactForm from "./ContactForm";

//import oldBaileyLookups from '../../data/oldbailey/lookups';

//import queryString from "query-string"; // We need this to edit the URL before it gets sent to the server.

const Record = () => {

    useScrollToTop();

    const { idkey } = useParams();

    return (

        <div className="container pt-5 pb-4 calc-vh">

            <div className="contact-form">

                <ContactForm idkey={ idkey }/>

            </div>

        </div>

    );

};

export default Record;
