
//import "./Basic.scss";
//import "./Dhids.scss";
import "./OldBailey.scss";
//import "./LocLon.scss";
//import "./BritishArmyOfficers.scss";

//import Base from "./components/dhids/Base";
import Base from "./components/oldbailey/Base";
//import Base from "./components/loclon/Base";
//import Base from "./components/britisharmyofficers/Base";

import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';

const App = () => {

    return <Base/>;

};

export default App;

//import logo from './logo.svg'; // This would import the logo file from /home/jamie/dhids/reactui2/src/logo.svg
//import './App.css';

//function App() {
//  return (
//    <div className="App">
//      <header className="App-header">
//        <img src={logo} className="App-logo" alt="logo" />
//        <p>
//          Edit <code>src/App.js</code> and save to reload.
//        </p>
//        <a
//          className="App-link"
//          href="https://reactjs.org"
//          target="_blank"
//          rel="noopener noreferrer"
//        >
//          Learn React
//        </a>
//      </header>
//    </div>
//  );
//}

//export default App;
