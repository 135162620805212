
import { useParams, useLocation, Link } from "react-router-dom";
//import { useParams, Link } from "react-router-dom";

import { useState, useCallback, /*useRef,*/ useEffect } from "react";

import XMLViewer from 'react-xml-viewer';

import parse from 'html-react-parser';
//import { domToReact } from 'html-react-parser';

import useResults from "../../hooks/useResultsNew";
import usePretty from "../../hooks/usePretty";
import useScrollToTop from "../../hooks/useScrollToTop";
import useClipboard from "../../hooks/useClipboard";

import Loading from "../Loading";
import Error from "../Error";

//import Contact from "./Contact";

import oldBaileyLookups from '../../data/oldbailey/lookups';

import queryString from "query-string"; // We need this to edit the URL before it gets sent to the server.

const Record = () => {

    useScrollToTop();

    const { idkey } = useParams();
    //const project = 'oldbailey';

    const [showxml, setShowxml] = useState(false);

    const toggleShowxml = (event) => {

        if (showxml) { setShowxml(false); } else { setShowxml(true); }

    };

    const customTheme = {
        "overflowBreak": true,
        "separatorColor": "#777",
        "textColor": "#777",
    }

    const [ pretty ] = usePretty(oldBaileyLookups);  
    const [ copyToClipboard ] = useClipboard();  

    document.title = idkey + " | The Proceedings of the Old Bailey";

    const location = useLocation();
    
    const preprocess = useCallback( () => {

        const qs = queryString.parse(location.search, { arrayFormat: "bracket" });

        let text = ( qs['text'] ? qs['text'] : '' );

        if (/^([a-z][0-9]{8}[a]?-[0-9]{1,4})$/.test(text) || // Match most OB section ids
        /^([0-9]{8})$/.test(text) || // Match OB full session ids
        /^(OA[0-9]{8})$/.test(text)) { // Match OA section ids

            text = '';

        }

        return `${process.env.REACT_APP_API_URL}/data/oldbailey_record_single?idkey=${ idkey }&text=${ text }`;

    }, [ idkey, location.search ]);

    // useResultsNew( apiPath, immediate_query, preprocess )
    const [ results, isLoading, error ] =
    useResults( null, true, preprocess );

    // const useResults = (apiPath, suffix, initquery, ignoreurl, preprocess)
    //const [ results, isLoading, error ] =
    //useResults(`${process.env.REACT_APP_API_URL}/data/oldbailey_record_single`, 'idkey=' + idkey, true, preprocess );

    const spaceImages = (event) => {

        //console.log('spaceImages');

        let offsets = {};

        const hrs = document.querySelectorAll("hr.page-break");

        hrs.forEach( (hr, i) => {

            offsets[hr.dataset.page] = hr.offsetTop;

        });

        const figures = document.querySelectorAll("figure.old-bailey-page-figure");

        figures.forEach( (figure, i) => {

            var desired_offset = offsets[figure.dataset.page];

            if (desired_offset) {

                var current_offset = figure.offsetTop;

                if (current_offset < desired_offset) {

                    var difference = desired_offset - current_offset;
                    figure.style.marginTop = difference + 'px';

                }
               
            }

        });

    };

    useEffect(() => {

        // Obviously we would like to find a more sophisticated trigger to run spaceImages than this.
        setTimeout(() => {
            spaceImages();
        }, "2000");

    }, [ results ]);

    const custom_options = {
        replace: ({ name, attribs, children }) => {

            //spaceImages();

            /*
            if (
                name === 'hr'
                && attribs
                && attribs['class']
                && attribs['class'] === 'page-break'
                //&& attribs.class
                //&& attribs.class === 'wikilink'
                && attribs['data-page']
            ) {

                const myRef = useRef();

                //return <Link to={ attribs.href.substring(39, attribs.href.length) }>{ domToReact(children, options) }</Link>
                //return <a href={ `/${process.env.REACT_APP_BASENAME}/about/` + attribs.href.substring(39, attribs.href.length) }>{ domToReact(children, custom_options) }</a>
                return (

                    <>
                    <span>BACOM</span>
                    <hr ref={ myRef } className="page-break BACOM" data-page={ attribs['data-page'] }/>
                    </>
                );

            }
           */

        }
    };

    const today = new Date();

    return (

        <div className="container mt-4 mt-xl-5 mb-5 calc-vh">

            {/*
            <div className="modal fade" id="contactModal" tabIndex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button type="button" className="btn float-end" data-bs-dismiss="modal" aria-label="Close">
                        <i className="bi bi-x-lg"></i>
                    </button>

                    <Contact idkey={ idkey }/>

                  </div>
                </div>
              </div>
            </div>
            */}

            {/*<button type="button" className="btn btn-primary" onClick={ spaceImages }>Space Images</button>*/}

            { isLoading && ( <div className="mt-5 pt-5 mb-5 mb-5"><Loading /></div> ) }

            { error && ( <div className="mt-5 pt-5 mb-5 mb-5"><Error error={ error } /></div> ) }

            { !isLoading && !error && results.hits && !results.hits.hits[0] && (

                <h5 className="mt-5 mb-5 fw-bold">
                    This record could not be found.
                </h5>

            ) }

            { !isLoading && !error && results.hits && results.hits.hits[0] && (

                <div>

                    <div className="row mb-lg-4">

                        <div className="col-12 col-lg-10">

                            <h1 className="display-7 mb-2">{ results.hits.hits[0]._source.title }</h1>

                        </div>

                        <div className="col-12 col-lg-2 text-end">
                            {/* 
                            { results.hits.hits[0]._source.projectsearchable && (

                                <>

                                <Link to={ '/' + results.hits.hits[0]._source.project + '/search' } className="text-decoration-none">

                                    Search { results.hits.hits[0]._source.projectlongname }

                                </Link>

                                <br/>

                                </>

                            ) }
                          
                            <Link to={ '/' + results.hits.hits[0]._source.project } className="text-decoration-none">

                                Download { results.hits.hits[0]._source.projectlongname }

                            </Link>

                            <br/>

                            <a href={ results.hits.hits[0]._source.exturl } className="text-decoration-none">

                                View this record on the live website <i className="bi bi-box-arrow-up-right"></i>

                            </a>

                            <br/>
                            */}
                            { results.hits.hits[0]._source.citation && (

                                <div>
                                    <button className="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#citation" aria-expanded="false">Cite this record</button>
                                </div>

                            ) }

                                <div>
                                    <Link className="text-decoration-none" to={ '/contact/' + results.hits.hits[0]._source.idkey } target="_blank">Report an error</Link>
                                    {/*<button type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target="#contactModal">Report an error</button>*/}
                                </div>

                        </div>

                        { results.hits.hits[0]._source.citation && (

                            <div className="col-12 collapse mt-3" id="citation">
                                <div className="card citation-card">
                                    <div className="card-body">
                                        <button type="button" className="btn-close float-end ms-1 mb-1" aria-label="Close" data-bs-toggle="collapse" data-bs-target="#citation" aria-expanded="false"/>
                                        { parse(results.hits.hits[0]._source.citation) } Available at: { window.location.toString() } (Accessed: { pretty(today, 'date') }).
                                        <button type="button" className="btn-link float-end mt-3 text-primary" onClick={ () => { copyToClipboard( results.hits.hits[0]._source.citation + ' Available at: ' + window.location.toString() + ' (Accessed: ' + pretty(today, 'date') + ').'); } }>Copy to clipboard</button>
                                    </div>                                 
                                </div>                           
                            </div>

                        ) }

                        {/*
                        { results.hits.hits[0]._source.metadata && (
 
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        { parse(results.hits.hits[0]._source.metadata) }
                                    </div>
                                </div>
                            </div>

                        ) }
                        */}
                        
                    </div >

                    <div className="row">

                        { results.hits.hits[0]._source.images && results.hits.hits[0]._source.images.length > 0 && (

                            <div className="col-12 col-md-4 me-5 order-last order-md-first">

                                {/*
                                { results.hits.hits[0]._source.metadata && (

                                    <div className="col-12">
                                        { parse(results.hits.hits[0]._source.metadata) }
                                    </div>

                                ) }
                                */}
                                
                                 <div className="text-center">                                       

                                    { results.hits.hits[0]._source.images.map((img, idx) => (

                                        <figure key={ img } className="figure mb-5 old-bailey-page-figure" data-page={ img.substring(26) }>

                                        <a href={ img } target="_blank" rel="noopener noreferrer">

                                            <img className="img-fluid old-bailey-page-image" src={ img } alt="Associated with this record" />

                                        </a>

                                        { idx === 0 && (((results.hits.hits[0]._source.image_titles && results.hits.hits[0]._source.image_titles[idx]) || (results.hits.hits[0]._source.image_rights && results.hits.hits[0]._source.image_rights[idx]))) &&  (

                                            <figcaption className="figure-caption text-start mt-2">

                                                { results.hits.hits[0]._source.image_titles && results.hits.hits[0]._source.image_titles[idx] && (

                                                        <strong className="me-1">
                                                            { results.hits.hits[0]._source.image_titles[idx] }
                                                        </strong>

                                                ) }

                                                { results.hits.hits[0]._source.image_rights && results.hits.hits[0]._source.image_rights[idx] && (

                                                        <>
                                                            { results.hits.hits[0]._source.image_rights[idx] }
                                                        </>

                                                ) }

                                            </figcaption>

                                        ) }
                                        
                                        </figure>

                                    )) }

                               </div> 

                            </div>

                        ) }

                        <div className="col">

                            { results.hits.hits[0]._source.metadata && (

                                <div className="col-12 mb-5">

                                    { parse(results.hits.hits[0]._source.metadata) }

                                </div>

                            ) }

                            { results.hits.hits[0]._source.xml && (

                                <div className="text-end mb-3">
                                    <button type="button" className="btn btn-link toggle-xml-button" onClick={ toggleShowxml }>
                                        Show
                                        { !showxml && (
                                            <> XML</>
                                        ) }
                                        { showxml && (
                                            <> Text</>
                                        ) }
                                    </button>
                                </div>

                            ) }

                            { results.hits.hits[0]._source.xml && showxml && (

                                <div className="source-xml">
                                    <XMLViewer xml={ results.hits.hits[0]._source.xml } theme={ customTheme } />
                                </div>

                            ) }

                            { !showxml && results.hits.hits[0]._source.collection !== 'assocrec' && (

                                <div className="source-text text-break border">

                                    { results.hits.hits[0].highlight && (

                                        parse(results.hits.hits[0].highlight.html[0], custom_options)

                                    ) }

                                    { !results.hits.hits[0].highlight && results.hits.hits[0]._source.html && (

                                        parse(results.hits.hits[0]._source.html, custom_options)

                                    ) }

                                    { !results.hits.hits[0].highlight && !results.hits.hits[0]._source.html && results.hits.hits[0]._source.text && (

                                        parse(results.hits.hits[0]._source.text)

                                    ) }

                                </div>

                            ) }

                            { results.hits.hits[0]._source.navigation && (

                                <div className="col-12 mt-4 text-end small">

                                    { parse(results.hits.hits[0]._source.navigation) }

                                </div>

                            ) }

                        </div>

                    </div>

                </div>


            )}

        </div>

    );

};

export default Record;
