
const Field = (props) => {
    
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeHelpslug = props.handleChangeHelpslug;

    return (

        <>

            <input type="search" className="form-control" name="length_gte" id="field_length_get" placeholder={ 'Min. length (words)' } value={ values['length_gte'] || '' } onChange={ handleChange } />
            <span className="input-group-text bg-white">
                <i className="bi bi-dash-lg"></i>
            </span>
            <input type="search" className="form-control" name="length_lte" id="field_length_lte" placeholder={ 'Max length (words)' } value={ values['length_lte'] || '' } onChange={ handleChange } />
            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-len" onClick={ handleChangeHelpslug }>
                <span className="material-symbols-sharp">help_outline</span>
            </button>

        </>

    );

};

export default Field;
