
import { useLocation, Redirect } from "react-router-dom";
import queryString from "query-string";

const SearchRedirector = () => {

    /*
    https://www.oldbaileyonline.org/search.jsp?form=searchHomePage&_divs_fulltext=house&kwparse=and&_persNames_surname=&_persNames_given=&_persNames_alias=&_offences_offenceCategory_offenceSubcategory=&_verdicts_verdictCategory_verdictSubcategory=&_punishments_punishmentCategory_punishmentSubcategory=&_divs_div0Type_div1Type=&fromMonth=&fromYear=&toMonth=&toYear=&ref=&submit.x=53&submit.y=18
    https://www.oldbaileyonline.org/search.jsp?form=searchHomePage&_divs_fulltext=&kwparse=and&_persNames_surname=&_persNames_given=&_persNames_alias=&_offences_offenceCategory_offenceSubcategory=breakingPeace_threateningBehaviour&_verdicts_verdictCategory_verdictSubcategory=&_punishments_punishmentCategory_punishmentSubcategory=&_divs_div0Type_div1Type=&fromMonth=&fromYear=&toMonth=&toYear=&ref=&submit.x=39&submit.y=11
    https://www.oldbaileyonline.org/search.jsp?form=persNames&_persNames_surname=Michael&_persNames_given=&_persNames_alias=&_persNames_type=&_persNames_gender=&fromAge=&toAge=&_occupations_value=&_persNames_home=&fromMonth=&fromYear=&toMonth=&toYear=&submit.x=0&submit.y=0
    https://www.oldbaileyonline.org/search.jsp?form=ordinarysAccount&_divs_div0Type_div1Type=ordinarysAccount&_divs_fulltext=Regret&kwparse=and&_persNames_surname=&_persNames_given=&_occupations_value=&_placeNames_value=&fromMonth=&fromYear=&toMonth=&toYear=&submit.x=0&submit.y=0
    https://www.oldbaileyonline.org/search.jsp?form=custom&_divs_fulltext=&kwparse=and&_persNames_surname=&_persNames_given=&_persNames_alias=&_persNames_gender=&fromAge=&toAge=&_occupations_value=Servant&_persNames_home=&_offences_offenceCategory_offenceSubcategory=&_offences_offenceDescription=&_verdicts_verdictCategory_verdictSubcategory=&_punishments_punishmentCategory_punishmentSubcategory=&_punishments_punishmentDescription=&_crimeDates_value=&_offences_crimeLocation=&_divs_div0Type_div1Type=&fromMonth=&fromYear=&toMonth=&toYear=&ref=&submit.x=0&submit.y=0
    https://www.oldbaileyonline.org/search.jsp?form=associatedRecords&_associatedRecords_defsur=Hill&_associatedRecords_offence=&_associatedRecords_trials=&_associatedRecords_doctype=&_associatedRecords_description=&_associatedRecords_libarch=&fromMonth=&fromYear=&toMonth=&toYear=&submit.x=0&submit.y=0
    */

    const location = useLocation();
    const qs = queryString.parse(location.search, { arrayFormat: "bracket" });

    let searchmode = 'keyword';

    if (qs['form'] && qs['form'] === 'persNames') { searchmode = 'name'; }
    if (qs['form'] && qs['form'] === 'ordinarysAccount') { searchmode = 'ordinarys_accounts'; }
    if (qs['form'] && qs['form'] === 'custom') { searchmode = 'advanced'; }
    if (qs['form'] && qs['form'] === 'associatedRecords') { searchmode = 'associated_records'; }

    let nps = {}

    if (qs['_divs_fulltext']) { nps['text'] = qs['_divs_fulltext']; }

    //const stringified = ( Object.keys(nps).length > 0 ? '?' + queryString.stringify(nps, { arrayFormat: "bracket" }) : '');

    return (     

        <>
            {/* <span>{ '/search/' + searchmode + stringified + '#results' }</span> */}
            {/* <span>{ '/search/' + searchmode }</span> */}
            <Redirect to={ '/search/' + searchmode } />
        </>
 
    );

};

export default SearchRedirector;
