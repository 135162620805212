
import useResults from "../../hooks/useResults";
//import useForm from "../../hooks/useForm";
//import usePretty from "../../hooks/usePretty";
//import useFilters from "../../hooks/useFilters";
//import useViz from "../../hooks/useViz";
//import usePaging from "../../hooks/usePaging";
//import useStagedSearch from "../../hooks/useStagedSearch";
//import useSwitchDynaggs from "../../hooks/useSwitchDynaggs";

//import { useLocation } from "react-router-dom";
//import queryString from "query-string";

//import Agg from "../Agg";
import Loading from "../Loading";
import Error from "../Error";
//import Post from "../Post";

import { Link } from "react-router-dom";


//import { useState } from "react";

//import options from '../../data/dhids/options';

//import lookups from '../../data/britisharmyofficers/lookups';

const Search = (props) => {

    //const location = useLocation();
    //const qs = queryString.parse(location.search, { arrayFormat: "bracket" });

    const today = new Date()
    const day = String(today.getMonth() + 1) + ("0" + today.getDate()).slice(-2);

    const [ results, isLoading, error ] =
    useResults(`${process.env.REACT_APP_API_URL}/data/oldbailey_onthisday`, 'day=' + day, false, true);

    //const pagesize = 100; // Infinite needs to know the initial pagesize
    //const [ page, handleChangePageField, handleSubmitPageForm, handleNextPageButton, handlePreviousPageButton, handleLastPageButton, handleFirstPageButton ] =
    //usePaging(pagesize);

    //const INLINE_RESULTS = false;

    //const [ switchDynaggs ] =
    //useSwitchDynaggs();

    //const [ pretty ] =
    //usePretty(lookups);

    let hit;
    //let hits;
    let trial;

    if (results.hits && results.hits.total === 1) {

        hit = results.hits.hits[0]._source;
        trial = hit.trial;
        //hits = ( <span>{ JSON.stringify(results.hits.hits) }</span> );

    }

    const [ trialresults, trialisLoading, trialerror ] =
    useResults(`${process.env.REACT_APP_API_URL}/data/oldbailey_record_single`, 'idkey=' + trial, false, true);

    let trialhit;
    //let trialhits;

    if (trialresults.hits && trialresults.hits.total === 1) {

        trialhit = trialresults.hits.hits[0]._source;
        //trialhits = ( <span>{ JSON.stringify(trialresults.hits.hits) }</span> );

    }

    //const [helpslug, setHelpslug] = useState();

    /*
    const handleChangeHelpslug = (event) => {

        var button = event.currentTarget;
        var helpslug = button.getAttribute('data-bs-helpslug');
        if (helpslug) {
            setHelpslug(helpslug);
        }
    }
    */

    return (

        <>          

        { (isLoading || trialisLoading) && ( <Loading /> ) }

        { (error || trialerror) && ( <Error error={ error } /> ) }

        { trialresults.hits && trialresults.hits.total === 1 && (

            <div className="row pt-4 pb-4">

                <div className="col-12 col-lg-6 p-4">

                    <h1>On this day in { hit.year } ...</h1>

                    <p className="ob-body-text fw-bold">{ hit.description }</p>
                    <p className="ob-body-text">
                        { trialhit.text.substring(0,600) }{ trialhit.text.length >= 600 && ( <> ... </> ) }
                    </p>
                    <p>
                        <Link to={ '/record/' + hit.trial }>See the full trial account.</Link>
                    </p>

                </div>

                <div className="col-12 col-lg-6 p-4">

                    <div className="otd-image-holder border hit-image-shadow">

                        <img className="img-fluid" src={ trialhit.images[0] } alt="Original document"></img>

                    </div>

                </div>
 
            </div>                                           

         ) }
    
        </>

    );

};

export default Search;
